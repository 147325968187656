.row-fluid {
    display: table;
    empty-cells: show;
    table-layout: fixed;
    width: calc(~"100% + 30px");
    margin-left: (@grid-gutter-width / -2);
    margin-right: (@grid-gutter-width / -2);

    &.row-remove {
        background-color: #fdf9e8;
    }

    &.row-remove-overlay {
        background-color: #fdf9e8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        display: flex;
        width: 100%;
        align-items: center;
    }

    &.relative {
        position: relative;
    }

    .col(1); // kickstart it

    // Common styles for all sizes of grid columns, widths 1-12
    .col(@index) when (@index = 1) {
        // initial
        @item: ~"> .col-xs-@{index}, > .col-sm-@{index},  > .col-md-@{index},  > .col-lg-@{index}";
        .col((@index + 1), @item);
    }

    .col(@index, @list) when (@index =< @grid-columns) {
        // general; "=<" isn't a typo
        @item: ~"> .col-xs-@{index},  > .col-sm-@{index},  > .col-md-@{index},  > .col-lg-@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }

    .col(@index, @list) when (@index > @grid-columns) {
        // terminal
        @{list} {
            float: none;
            display: table-cell;
            vertical-align: top;
        }
    }


    .fluid-col(@width) {
        float: none;
        display: table-cell;
        vertical-align: top;
        width: @width;
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }

    & > .col-xs-a, & > .col-sm-a, & > .col-md-a, & > .col-lg-a {
        .fluid-col(auto);
        padding-right: 5px;
    }

    & > .col-xs-check, & > .col-sm-check, & > .col-md-check, & > .col-lg-check {
        .fluid-col(29px);
        padding-right: 0;
    }

    .col-f(@list) {
        @index: length(@list);
        @w: extract(@list, @index);
        @selector: ~".col-f-@{w}";
        @selectors: @selector;

        @{selector} {
            width: @w * 1px;
        }

        .col-f(@list, @index - 1, @selectors)
    }

    .col-f(@list, @index, @selectors) when (@index > 0) {
        @w: extract(@list, @index);
        @selector: ~".col-f-@{w}";
        @{selector} {
            width: @w * 1px;
        }

        .col-f(@list, @index - 1, ~"@{selectors}, @{selector}")
    }

    .col-f(@list, @index, @selectors) when (@index = 0) {
        @{selectors} {
            float: none;
            display: table-cell;
            vertical-align: top;
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .col-f(40 60 80 100 120 140 150 160 200 250 280 364);

    .va-m {
        .va-m;
    }

    .va-b {
        .va-b;
    }

    .va-t {
        .va-t;
    }

    .divider {
        @w: (@grid-gutter-width / 2);
        .box-sizing(content-box);
        width: 1px;
        display: table-cell;
        background: fade(#000, 10%);
        background-clip: padding-box;
        border-left: @w solid transparent;
        border-right: @w solid transparent;
    }

    & > .col-actions {
        padding-left: 0;
        padding-right: 0;
    }
}
