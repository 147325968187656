.flex-head {
    display: table;
    width: 100%;

    .col-fit, .col-min {
        display: table-cell;
        vertical-align: bottom;

        &.middle {
            vertical-align: middle;
        }

        &.spaced {

            h2 {
                padding-bottom: 7px;
            }

            .btn {
                position: relative;
                top: -3px;
            }
        }
    }

    .col-fit {
        word-break: break-all;
    }

    .col-min {
        width: 1%;
    }

    .col-row {
        width: 100%;
        display: table-row;
    }
}

.flex(@direction) {
    max-width: 100%;
    display: flex;
    flex-direction: @direction;
    flex-wrap: nowrap;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        min-width: auto;

        &.flex {
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
        }
    }
}

.flex-row {
    .flex(row);
    align-items: center;

    &.relative {
        position: relative;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.stretch {
        align-items: stretch;
    }

    &.align-top {
        align-items: flex-start;
    }

    &.align-bottom {
        align-items: flex-end;
    }

    .flex-divider {
        flex: 0 0 1px;
        border-right: 1px solid #efefef;
    }

    &.checkbox-inline {
        > [type=checkbox] {
            margin-top: 0;
        }
    }

    & > * {
        // TODO: deprecated
        &.flex-grow {
            flex-grow: 1;
            flex-basis: 0;
            min-width: 0;
        }

        // TODO: deprecated
        &.right {
            margin-left: auto;
        }

        &.stretch {
            align-self: stretch;
        }

        &.align-self-top {
            align-self: flex-start;
        }

        &.align-self-bottom {
            align-self: flex-end;
        }

        // TODO: deprecated, use `.flex` instead
        &.flex-shrink {
            flex-shrink: 1;
        }
    }

    .gap(@margin) {
        & > * {
            // reset horizontal margins
            margin-right: 0;
            margin-left: 0;

            &:not(:first-child) {
                margin-left: @margin !important;
            }
        }
    }

    &.gap-sm {
        .gap(5px);
    }

    &.gap {
        .gap(10px);
    }

    &.gap-m {
        .gap(20px);
    }

    &.gap-l {
        .gap(30px);
    }

    &.gap-h {
        .gap(60px);
    }
}

.flex-column {
    .flex(column);
    align-items: flex-start;

    &.center {
        align-items: center;
    }

    &.content-center {
        align-content: center;
    }

    &.right {
        align-items: flex-end;
    }

    &.align-middle {
        justify-content: center;
    }

    &.flex-wrap {
        flex-wrap: wrap;
    }

    &.align-bottom {
        justify-content: flex-end;
    }

    & > * {
        &.align-self-bottom {
            margin-top: auto;
        }
    }

    .gap(@margin) {
        & > * {
            // reset vertical margins
            margin-top: 0;
            margin-bottom: 0;

            &:not(:first-child) {
                margin-top: @margin !important;
            }
        }
    }

    &.gap-sm {
        .gap(5px);
    }

    &.gap {
        .gap(10px);
    }

    &.gap-m {
        .gap(20px);
    }

    &.gap-l {
        .gap(30px);
    }
}
