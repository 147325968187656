.z-index(@z) {
    z-index: @z;
}

.z-index-1 {
    .z-index(1);
}

.z-index-9 {
    .z-index(9);
}
