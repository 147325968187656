.terms-of-use {
	font-size: @font-size-base * 1.2;

	.logo-holder {
		border-bottom: 2px solid @gray-lighter;
		padding-bottom: @padding-large-vertical * 3;
		margin-bottom: @padding-large-vertical * 2;
	}

	h3 {
		margin-bottom: @padding-large-vertical * 2;
	}

	li, p {
		margin-bottom: @padding-large-vertical * 2;
	}

	li p, .indented {
		text-indent: @padding-base-horizontal * 3;
	}

	li ol {
		padding-left: @padding-base-horizontal * 3;
	}

	ol li:before{
		content: counters(item,".") ". ";
	}
}
