// FIXME: legacy styles
.documents {
	.filter-reset {
		padding: 15px;
		margin-bottom: 20px;
	}

	.no-results {
		display: block;
	}

	.tab-menu {
		.active a {
			font-weight: bold;
		}
	}

	h2.title {
		color: #344D6B;
		font-weight: bold;
	}

	.well{
	 	&:extend(.application .well);
	 }

	.files {
		padding: 0;
		margin: 0;
		list-style: none;

		ul.list-inline li{
			display: inline-block;
			background: transparent;
		}

		li {
			display: block;
			background: #fff;

			&.no-results {
				.grey-line;
				padding: 12px 0;
			}
		}

		.frontface {
			@done: mix(#ebc300, #fff, 10%);
			padding: 20px 200px 14px 45px;
			border: 1px dashed #ccc;
			background: #fff;
			margin-bottom: 20px;
			border-radius: 6px;
			position: relative;
			min-height: 110px;
			transition: background 0.3s;

			&.no-actions {
				padding-right: 20px;
			}

			&.solid {
				border-style: solid;
			}
			&.done {
			    background: @done;
			}
			&.no-status {
				padding-left: 20px;
			}

			h4 {
				margin: 0 5px 0 0;
				display: inline-block;
			}

			.status {
				position: absolute;
				top: -1px;
				left: -1px;
				bottom: -1px;
				width: 30px;
				border-radius: 6px 0 0 6px;
				background: #ddd;
				color: #fff;
				text-align: center;

				&.requested {
					background: @brand-danger;
				}

				&.uploaded {
					background: @brand-success;
				}

				&:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: 0;
					height: 100%;
				}
			}

			.upload {
				position: absolute;
				top: -1px;
				right: -1px;
				bottom: -1px;
				width: 200px;
				padding: 15px 25px;
				text-align: center;

				&.centered {
					width: 400px;
					margin-right: -200px;
					right: 50%;
					top: 0px;

					.btn, .file-handler {
						width: 150px;
						display: inline-block;
					}
				}

				.btn {
					width: 100%;
					margin-bottom: 5px;
				}

				.inactive {
					padding-top: 15px;
					line-height: 24px;
				}
			}

			.uploading, .done-block {
				position: absolute;
				top: 1px;
				right: 1px;
				left: 0px;
				bottom: 1px;
				border-radius: 5px;
			}


			.uploading {
				background: #fff url(/static/images/loader-circular-primary.gif) center center no-repeat;
			}

			.done-block {
				padding-top: 20px;
				color: @brand-success;
				z-index: 1;
				visibility: visible;
			    opacity: 1;
				transition: visibility 0s, opacity 0.3s, background 0.3s;
				background: @done;

				.glyphicon {
					font-size: 40px;
					margin-bottom: 10px;
				}

				&.ng-hide {
					background: #fff;
					display: block !important;
					visibility: hidden;
					transition-delay: 0.3s, 0s, 0s;
					opacity: 0;
				}
			}

			.actions {
				color: @text-muted;
				font-size: 18px;
				display: inline-block;

				a {
					font-size: 14px;
					cursor: pointer;
				}

				.when {
					font-size: 12px;
				}

				.button {
					font-size: 12px;
					display: inline-block;
					vertical-align: middle;
					height: 20px;
					line-height: 18px;
					border: 1px solid;
					text-decoration: none;
					padding: 0 10px;
					border-radius: 3px;
					text-align: center;
					margin-right: 0;

					.hover {
						display: block;
						opacity: 0;
						color: #fff;
						background: @brand-danger;
						margin: -19px -11px;
						border-radius: 3px;
						line-height: 20px;

					}
					&:hover .hover {
						opacity: 1;
					}

					&.text-muted {
						border-color: #ccc;
					}
				}
			}

			.description {
				margin-top: 10px;
				max-height: 2*@line-height-computed;
				overflow: hidden;

				a { cursor: pointer; }

				&.full {
					overflow: visible;
					max-height: none;
				}
			}
		}



		.file-handler {
			display: block;
			position: relative;
			margin-bottom: 0;

			input[type=file] {
				width: 100%;
				position: absolute;
				z-index: -1;
				opacity: 0;
			}
		}

		.droppable {
			position: relative;
			perspective: 5000px;

			.backface, .frontface {
				backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				position: relative;
				transform-origin: 50%;
				transform: rotateX(0deg);
				transition: transform 0.3s, background 0.3s;
			}

			.backface {
				content: "";
				position: absolute;
				display: block;
				top: -1px;
				right: -1px;
				bottom: -1px;
				left: -1px;
				z-index: 1;
				background: mix(@brand-primary, #fff, 20%);
				color: fade(@brand-primary, 100%);
				border: 1px dashed @brand-primary;
				border-radius: 6px;
				transform: rotateX(-180deg);
				text-align: center;

				.error {
					position: absolute;
					top: -1px;
					right: -1px;
					bottom: -1px;
					left: -1px;
					padding: 20px 0 0;
					border: 1px solid @brand-danger;
					color: @brand-danger;
					border-radius: inherit;
					background: mix(@brand-danger, #fff, 20%);
				}
			}

			/*&:hover,*/ &.dropping, &.flipped {
				.frontface {
					transform: rotateX(180deg);
				}

				.backface {
					transform: rotateX(0deg);
				}
			}
		}

		.solid-line {
			border-style: solid;
		}
	}

	&.lender, &.comergence{
		.filter-reset{
			padding: 15px;
			margin-bottom: 20px;
		}
		.frontface{
			padding: 20px 200px 14px 20px;
		}
		.description {
			margin-bottom: 10px;
		}
		.dropdown-menu a{
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.new-document{
			textarea[name='description']{
				min-height: 100px;
			}
			.upload {
				right: 38%;
			}
			.file-actions .file-handler {
				display: inline-block;
			}
			.frontface{
				padding: 20px 20px 14px 20px;
			}
		}
		ul.channels {
			white-space: normal;
		}
	}

	.client-documents{
	  .files .frontface{
			min-height: 150px;
			.upload .request-btn{
				margin-bottom: 10px;
			}
		}
	}
	.files{
		.upload {
			.chooseFromLibrary.btn{
				padding: 6px 0;
				width: 150px;
			}
		}
	}
	.glyphicon-share-alt{
		transform: rotate(270deg);
		font-size: 35px;
		vertical-align: super;
	}
	.pls-select{
		font-size: 18px;
		color: @brand-nav-panel-bkg;
	}
	.uploaded-doc{
		padding-top: 20px;
	}
	.channel-actions{
		.btn {
			width: 90px;
		}
	}

	.summary {
		.title-holder {
			padding: 10px;
			background: #e0ecf8;
			font-size: 16px;
			line-height:18px;
		}
		.row-fluid {
			padding-left: 10px;
		}
	}


}
.choose-from-library {
	.modal-footer {
		background-color: none;
	}
	.modal-body {
		padding-top: 0;
	}
	.well {
		margin-top:0;
		overflow: visible;
		max-height: none;
	}
	.grey-bkg{
		background-color: @brand-grey-bkg;
		margin: 0px -28px;
		padding: 20px 30px;
	}
	.glyphicon-arrow-down{
		display: block;
		color: @brand-grey-icon;
		font-size: 54px;
		margin: 20px 0;
	}
	.grey-bkg + div > .grey-bkg{
		padding-top: 5px;
	}
	.documents .files .frontface{
		min-height: initial;
	}
	.when {
		font-size: 12px;
		color: @text-muted;
		margin-bottom: 8px;
	}
	h3 {
		margin-bottom: 5px;
	}
	p {
		color: @brand-dark-grey;
		margin-top: 10px;
		max-height: 40px;
		overflow: hidden;
		a {
			cursor: pointer;
		}
		&.full{
			overflow: visible;
			max-height: none;
		}
	}

}


.curtain {
	position: relative;
	z-index: 0; // fix for weird z-index behavior (see RM-16195)
	user-select: none;

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: 999;
		top: 0;
		right: 0;
		left: 0;
		height: 100%;
		background: fade(#fff, 75%);
	}

	&.resolved:after {
		opacity: 1;
		visibility: visible;
		transition: none;
	}
}

.request-document {
	.modal-dialog {
		width: 540px;
		.modal-body {
			textarea {
				&.form-control {
					&[disabled] {
						color: #dcdcdc;
						background-color: white;
						border-color: #dcdcdc;
						cursor: default;
						&:hover {
							border-color: #dcdcdc;
						}
					}
				}
			}
		}
	}
}

.files-new {
	padding: 0;
	margin: 0;
	list-style: none;

	ul.list-inline li{
		display: inline-block;
		background: transparent;
	}

	li {
		display: block;
		background: #fff;
		margin-bottom: 15px;

		&.no-results {
			.grey-line;
			padding: 12px 0;
		}

		h4 {
			margin: 0;
		}
	}

	.frontface {
		border: 1px solid #e7eaed;
		padding: 17px 20px 17px 25px;
		border-radius: 5px;
	}

	.header {
		margin-bottom: 8px;
	}

	.special-link {
		.c-icon {
			color: @text-muted;
			font-size: 20px;
			line-height: 16px;
			width: auto;
			top: 2px;
			margin-right: 8px;
		}

		.c-icon-file {
			font-size: 14px;
			line-height: 14px;
			top: 0;
		}
	}
}
