.modal-static {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        z-index: 1;
        display: block;
        pointer-events: none;
    }

    .modal-content {
        pointer-events: all;
    }

}

.modal {
    @modal-header-height: 52px;

    .modal-header {
        min-height: @modal-header-height;
        padding: 17px 20px 14px;
        color: @brand-primary-dark;
        border-bottom: 4px solid @brand-primary-dark;
    }

    .max-h300 {
        max-height: 300px;
        overflow-x: hidden;
    }

    .max-h450 {
        max-height: 300px;
        overflow-x: hidden;
    }

    .modal-title {
        font-size: 21px;
        line-height: 24px;
    }

    .modal-content {
        border-radius: 1px;
        border: 0;
        .box-shadow(~"0px 5px 7px rgba(0, 0, 0, 0.20)");

        &.min-300 {
            min-height: 300px;
        }

        &.min-400 {
            min-height: 400px;
        }

        &.min-500 {
            min-height: 500px;
        }

        &.min-600 {
            min-height: 600px;
        }
    }

    .close {
        font-size: 32px;
        color: inherit;
        text-shadow: none;
        .opacity(1);

        &:hover {
            .opacity(.8);
        }

        &.fixed-height {
            height: 24px;
        }
    }

    &.modal-edit, .modal-edit {
        .modal-header {
            min-height: @modal-header-height;
            padding: 14px 20px;
            font-weight: bold;
            color: #fff;
            background: @brand-primary-dark;
            border-bottom: none;
            text-align: center;
        }
    }

    .modal-close-header {
        padding: 10px 15px 0;
        .clearfix();
    }

    .modal-footer {
        border-top: none;

    }

    .modal-body + .modal-footer {
        padding-top: 0;
    }

    .inline-toggle {
        float: right;
        line-height: 28px;

        li {
            color: @brand-primary-dark;
        }

        li.active {
            background-color: @brand-primary-dark;
        }
    }

    &.center {
        /** Sharp and crisp vertical centering **/
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        white-space: nowrap;

        &:before {
            content: "";
            height: 100%;
            vertical-align: middle;
            display: inline-block;
        }

        .modal-dialog {
            white-space: normal;
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }
}

.modal-dialog, .modal {
    &.warning {
        @bgColor: yellow;
        @mainColor: #000;

        .close {
            color: #fff;
        }

        .btn-default {
            background-color: transparent;
            color: @mainColor;
            border-color: currentColor;

            &:hover {
                color: #555;
            }
        }

        .modal-header {
            color: @bgColor;
            background: @mainColor;
            border-bottom: none;

            .modal-title {
                font-size: 27px;
            }
        }

        .remote-resource:after {
            background-color: fade(@bgColor, 75%);
        }

        .modal-content {
            background: @bgColor;
        }
    }
}

modal-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

@media (min-width: @screen-md-min) {
    .modal-vlg { width: @modal-vlg; }
    .modal-smd { width: @modal-smd; }
    .modal-sm { width: @modal-sm; }
}

.modal-backdrop, .aside-backdrop {
    &.am-fade {
        background: fade(#000, 40%);
    }
}

// legacy
.modal-body {
    .rows-striped ~ .well {
        margin-top:    0;
        margin-bottom: 0;
    }

    &.no-padding {
        padding: 0;

        .rows-bordered {
            margin: 0;
        }
    }

    &.no-line-height {
        line-height: 0;
    }

    .well {
        margin: -30px -30px 30px;
        padding: 15px 30px;
        .yellow-bg;

        .big-icon {
            margin-top: 20px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .alert {
        &:not(.framed) {
            margin: -30px -30px 30px;
            padding: 15px 20px;
            border-width: 0px;

            font-size: 16px;

            &.text-medium {
                font-size: 14px;
            }
        }
    }
    .alert-quote {
        margin: 0px;
        background-color: @brand-quote-bkg;
    }
}
