reset-filters {
    .yellow-bg;

    &, &.row, &.row-fluid {
        display: flex;
        min-height: auto;
        height: 46px;
        justify-content: center;
        align-items: center;
        padding: 0;

        &:empty {
            display: none;
        }
    }
}
