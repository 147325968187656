@import "./questions/new-question/new.component.less";
@import "./questions/new-question/from-bank.component.less";

single-question {
    display: block;
    position: relative;

    &.form-group {
        margin: 0;
    }
}

.questionnaire-list {
	// TODO: move outside
	margin-top: 20px;

	.in-use-holder {
		width: 95px;
		text-align: right;
	}

	.question-container {
		padding: 20px 15px;
		border-top: 1px solid fade(#000, 15%);

		&.edit {
			background-color: mix(@brand-primary, #fff, 10%);

			.curtain {
				opacity: 0.25;

				&::after {
					background: transparent;
				}
			}

			.answer-options {
				.form-group {
					margin-bottom: 5px;
				}
			}

			.counter {
				.countee {}
			}
		}

		.shifted {
			padding-left: 17px;
		}

		hr {
			margin: 10px 0;
			border-top-color: rgba(0, 0, 0, 0.15);
		}

		.question-number {
			width: 2em;
			text-align: right;
		}

		.answer-list {
			margin-top: 10px;
			padding: 15px 30px;
			background: fade(#000, 5%);
		}
	}

	.non-reachable {
		background: rgba(235, 195, 0, 0.1);
		color: #333;
	}

	// FIXME: temporary
	.under-development {
		visibility: hidden;

		* {
			visibility: hidden;
		}
	}

	.questions-holder > div,
	.explanation-holder {
		min-height: @input-height-base;
	}

	.explanation-holder {
		input[type=checkbox] {
			display: block;
		}
	}

	.skip-logic-holder,
	.attached-documents,
	.explanation-holder {
		.horizontal-flex-group {
			min-height: @input-height-base;
		}

		input[type=checkbox] {
			width: auto;
			height: auto;
			margin-right: 5px;
		}

		ng-select {
			width: 72px;
		}

		.glyphicon {
			font-size: 20px;
		}
	}

	.attached-documents {
		ng-select {
			width: calc(~"100% - 20px");
			margin-right: 0;
		}
	}
}

.long-tooltip {
	width: 200px;
	padding-right: 15px;
}
