cell-select {
    display: inline-block;
    text-align: left;


    &, .text-default {
        white-space: normal;
        word-break: break-all;
    }

    &.btn {
        min-width: 48px;
        padding-right: 5px;
        min-height: 30px;
        text-align: left;
        height: auto;
    }
}
