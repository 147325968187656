.social-compliance-publisher-post {
	.limits {
		display: flex;
		flex-wrap: wrap;

		div {
			display: flex;
			align-items: center;
			margin-right: 3px;

			.icon-alert {
				margin: 0 3px;
			}
		}

		.red {
			color: red;
			cursor: pointer;
		}
	}
}
