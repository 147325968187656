post {
	facebook-post {
		&.post-content {
			.link-preview {
				.text-holder {
					.link {
						text-transform: uppercase;
					}

					.description {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}
}
