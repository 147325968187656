.badge {
	padding: 3px 6px;
}

.badge-holder {
	padding: 0 10px;

	&.reloading {
		&:before {
			position: absolute;
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			margin-top: -2px;
			right: 24px;
			background: url(/static/images/loader-circular-primary.gif) center center no-repeat;
			background-size: 75%;
		}

		* {
			display: none;
		}
	}
}

.badge-success {
	background-color: @brand-green;
}

.badge-danger {
	background-color: @brand-red;
}

.badge-default {
	background-color: @brand-primary;
}

.incative {
	color: @brand-dark-grey;
}

.badge-active {
	background-color: #72a4de;
}
