.breadcrumbs {
	margin: 0px;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
	}

	+h1, +h2, +h3, +h4 {
		line-height: 30px;
		min-height: 30px;
		padding: 0;
		margin: 0;

		.label {
			vertical-align: middle;
		}
	}

	.separator {
		color: #999;
	}

	.history-back {

		.glyphicon {
			float: left;
			margin-right: 5px;
			line-height: 16px;
		}
	}

	&.grey {
		a {
			color: @text-muted;
		}
	}
}

.line-space {
	padding-bottom: 10px;
}

.header-space {
	padding-bottom: 20px;
}
