.alert-card {
    border: 1px lightgrey solid;
    border-radius: 5px;
    padding: 15px
}

.alert-header {
    border-bottom: lightgrey 1px solid;
    padding-bottom: 5px;
}

.percent-field {
    width: 50px;
}
