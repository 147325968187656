.move-listing-modal {
	.selected-listing-group {
		min-height: 100px;

		.listing-group-image {
			.list-item-image-fn(150px, 150px/@cover-aspect-ratio);
		}

		.listing-group-details {
			overflow: hidden;
		}
	}
}
