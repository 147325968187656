.channel-locations {
  .item-removed {
    position: absolute;
    left: -1px;
    top: 3px;
  }

  .center-columns > * {
    display: flex;
    align-items: center;

    &:last-child {
      justify-content: center;
    }
  }

  .btn-mr10 {
    margin-right: 10px;
  }
}