@width: 44px;
@height: 26px;
@radius: (@height / 2);
@borderWidth: 1px;
@animationDuration: 0.2s;

toggle {
	margin: 1px 0;
	display: inline-block;
	vertical-align: middle;

	.toggle-container {
		position: relative;
		width: @width;
		height: @height;
		border-width: @borderWidth;
		border-style: solid;
		border-radius: @radius;
		transition:
			background @animationDuration linear,
			border @animationDuration linear;
		overflow: hidden;
		cursor: pointer;

		.toggle-switch {
			position: absolute;
			top: -@borderWidth;
			width: @radius * 2;
			height: @radius * 2;
			background: #FFF;
			border-width: @borderWidth;
			border-style: solid;
			border-radius: @radius;
			box-shadow:
				-1px 1px 3px 0 fade(#000, 15%),
				1px 1px 3px 0 fade(#000, 15%);
			transition:
				left @animationDuration ease-in-out,
				background @animationDuration linear,
				border @animationDuration linear,
				box-shadow @animationDuration linear;

            loader {
                margin: 2px;
            }
		}

		.color(@backgroundColor, @borderColor: @backgroundColor) {
			background-color: @backgroundColor;
			border-color: @borderColor;

			.toggle-switch {
				border-color: @borderColor;
			}
		}

		&.active {
			.color(@brand-primary);

			&.disabled {
				.color(#ACCCEC);
			}

			.toggle-switch {
				left: (@width - @borderWidth - (@radius * 2));
			}
		}

		&.inactive {
			.color(#FFF, fade(#000, 10%));

			&.disabled {
				.color(#EEEFF2);
			}

			.toggle-switch {
				left: -@borderWidth;
			}
		}

		&.disabled {
			pointer-events: none;

			.toggle-switch {
				box-shadow: none;
			}
		}

		&.loading {
			cursor: default;

			.toggle-switch {
				background-color: #fff;
            }
		}
	}
}
