.assign-questionnaire-form {
	.checkbox-inline {
		font-weight: bold;

		input {
			margin-top: 3px;
		}
	}

	.control-label {
		font-size: 12px;
		margin-bottom: 3px;
	}

	.control-value {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.description {
		padding: 15px 0 0;
	}

	.characters {
		padding-top: 5px;
		font-size: 12px;
	}

	.schedule-selector-holder {
		width: 280px;
	}
}
