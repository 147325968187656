.highlights() {
	.item {
		.text-center;
		&:not(:last-child) {
			border-right: 1px fade(#000, 15%) solid;
		}
		.item-value {
			margin-bottom: 5px;
			.text-big;
		}
		.item-label {
			.text-muted;
		}
	}
}

.lender-crm-contact-new-list,
.lender-crm-contact-list-details {
	section {
		margin-bottom: 30px;
		& > h3 {
			margin-bottom: 25px;
		}
		&.segment-filter {
			margin-top: 45px;
			&.segment-filter-view {
				.clauses-group {
					display: inline-block;
					.and-separator {
						&:after {
							content: "\00a0";
						}
					}
					.or-separator {
						&:before {
							content: "\00a0";
						}
					}
				}
			}
			&.segment-filter-edit {
				.clauses-group {
					margin-bottom: 15px;
					& > .rows-striped {
						margin-bottom: 15px;
						.clause {
							margin: 0 0 15px;
						}
					}
					& > .group-separator {
						margin: 0 15px;
						font-size: inherit;
						font-weight: bold;
					}
				}
			}
		}
	}
	label {
		&.multiline {
			white-space: normal !important;
		}
	}
}

.lender-crm-contact-list-details {
	.highlights {
		margin-bottom: 45px;
		.highlights();
		.item {
			.item-value {
				font-size: 2em;
			}
		}
	}
}

.lender-crm-contact-list-contacts {
	searchable-entity.search-contacts {
		display: inline-block;
		margin-left: 5px;
		width: 500px;
		.w11k-select {
			margin: 0;
			min-width: initial;
			.form-control {
				width: 100%;
				min-width: initial;
			}
			.item() {
				.contact {
					flex:1 1 0;
					.email {
						font-weight: 600;
					}
					.name {
						.text-smaller;
					}
				}
			}
			.has-feedback {
				.form-control {
					&.selection {
						.item();
					}
				}
			}
			.dropdown-menu {
				&.single {
					ul.items {
						li.item {
							display: block;
							padding: 0;
							.item-inner {
								display: flex;
								padding: 3px 10px;
								.item();
								.labels {
									flex: 0 0 95px;
									.label {
										display: block;
									}
								}
								&.disabled {
									.text-light;
									.email {
										.text-normal;
									}
								}
							}
							&.selected {
								.item-inner {
									&.disabled {
										color: #fff;
										background-color: @brand-dark-grey;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
