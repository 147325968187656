.add-question {
	margin-top: 15px;

	&.edit {
		padding: 15px;
		background-color: #f0f6fc;
	}

	hr {
		margin: 15px 0;
		border-top-color: rgba(0, 0, 0, 0.15);
	}

	.shifted {
		padding-left: 17px;
	}

	.answer-options {
		.form-group {
			margin-bottom: 5px;
		}
	}

	[form-footer] {
		hr {
			margin: 15px;

			&.no-margin-top {
				margin-top: 0;
			}
		}
	}

	.questions-holder > div,
	.explanation-holder {
		min-height: @input-height-base;
	}

	.skip-logic-holder,
	.attached-documents,
	.explanation-holder {
		.horizontal-flex-group {
			min-height: @input-height-base;
		}

		input[type=checkbox] {
			width: auto;
			height: auto;
			margin-right: 5px;
		}

		ng-select {
			width: 72px;
		}

		.glyphicon {
			font-size: 20px;
		}
	}

	.attached-documents {
		ng-select {
			width: calc(~"100% - 20px");
			margin-right: 0;
		}
	}
}
