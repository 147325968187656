.horizontal-flex-group {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;

	> h3 {
		margin-top: 0;
		margin-bottom: 0;
	}

	&.reversed {
		flex-direction: row-reverse;
	}

	&.align-top {
		align-items: start;
	}

	&.right {
		justify-content: flex-end;

		& > *, .space-normal {
			margin-right: 0;
			margin-left: 10px;
		}

		.space-big {
			margin-right: 0;
			margin-left: 20px;
		}

		.space-bigger {
			margin-right: 0;
			margin-left: 15px;
		}

		.space-small {
			margin-right: 0;
			margin-left: 5px;
		}

		.no-space {
			margin-left: 0;
			margin-right: 0;
		}
	}

	& > *, .space-normal {
		margin-left: 0;
		margin-right: 10px;
	}

	& .no-space {
		margin-right: 0;
		margin-left: 0;
	}

	.space-small {
		margin-left: 0;
		margin-right: 5px;
	}

	.space-bigger {
		margin-right: 15px;
	}

	.space-big {
		margin-right: 20px;
	}

	& .full-width {
		flex-grow: 1;
	}
}
