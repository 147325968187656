.supporting-document {
	.front-face {
		.title {
			& > a {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.indications {
			.updated-by {
				margin-top: 3px;
				.text-muted;
				.text-smaller;
			}
		}
	}
    .back-face{
        margin-bottom:-2px;
    }
}
