.lender-page{
	.lender {
		img{
			width: 65px;
			display: inline-block;
			margin-right: 10px;
		}
	}
	.big-line{
		.row {
			position: relative;
			z-index: 3;
			margin-bottom: 20px;
			a {
				line-height: 45px;
			}
		}
		.back-link {
			white-space: nowrap;
		}
	}

	.company-page{
		padding-top: 60px;
	}

	.company-info {
		h3.lender {
			font-size: 18px;
			margin: -10px 0;
			line-height: 65px;
		}
		small.channel{
			font-size: 14px;
			color: @brand-disabled;
		}
	}
	.files {
		&:extend(.documents .files all);
		margin-top: 30px;
		.frontface {
			border-style: solid;
			min-height: 120px;
			padding: 20px 200px 14px 20px;
		  	.upload {
			  width: 215px;
		  	}
		}
		label {
			display: block;
		}
		.apply {
			margin-top: 35px;
			.btn{
				width: 100%;
			}
		}
		.documents-required, .contact-person {
			a:first-child {
				&:before{
					content: '\e806';
					font-size: 10px;
					font-family: "comergence-icons";
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					width: 20px;
					margin-left: -5px;
					margin-top: -1px;
				}
				&.showDocs:before{
					content: '\e804'
				}
			}
			margin-top: 1em;
			ul {
				display:none;
				padding: 10px 0 0 15px;
			}
			.showDocs ~ ul{
				display: block;
			}
		}
		.contact-person {
			span {
				color: @text-muted;
			}
			> div {
				padding-right: 40px;
			}
			b, a {
				padding-left: 8px;;
			}
		}
		.pending, .active, .suspend{
			color: @brand-grey-icon;
			margin-top: 20px;
			font-size: 32px;
		}
		.pending {
			margin-top: 8px;
		}
		.active {
			margin-top: 8px;
			color: @brand-green;
			small {
				font-size: 12px;
				color: @text-muted;
				font-weight: normal;
			}
		}
		.status-text {
			font-size: 16px;
			color: #000;
		}
	}

	h3.underlined {
	  font-weight: bold;
	  color: @brand-primary-dark;
	  border-bottom: 3px solid @brand-primary-dark;
	  padding: 5px 0;
	  line-height: 34px;
	}

}

.lender-contact-information{
	.description {
		margin-top: 40px;
	}
	.row-fluid {
		margin-bottom: 5px;
	}
}

.application-history {
	section {
		margin-top: 10px;
	}
	.actions {
		text-align: center;
		a.view {
			cursor: pointer;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.companies .lender {
	width: 100%;
	display: table;
	@logo-size: 128px;
	@logo-padding: 5px;
	@total-size: @logo-size + 2*(@logo-padding + 1);

	.lender-logo {
		width: @total-size;
		padding-right: 30px;
		white-space: nowrap;
		display: table-cell;
		vertical-align: middle;


		.lender-logo-inner {
			width: @total-size;
			height: @total-size;
			padding: @logo-padding;
			border: 1px solid #ddd;
			font-size: 0;

			&:before {
				content: "";
				width: 0;
				height: @logo-size;
				display: inline-block;
				vertical-align: middle;
			}

			img {
				display: inline-block;
				vertical-align: middle;
				max-width: @logo-size;
				max-height: @logo-size;
				width: 100%;
			}
		}

	}

	.lender-description {
//		overflow: hidden;
		width: 100%;
		display: table-cell;
		vertical-align: middle;

		.lender-description-inner {
            .description {
                color: black;
            }
		}
	}
}

.partners-lender {
    padding-left: 15px;
}

.back-partners {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}