.market-map {
	.map-filters-toolbar {
		display: none;
	}

	&.show-filters {
		.map-wrapper {
			border-top: 1px solid fade(#000, 15%);;
			top: 300px;
		}
		.map-filters-toolbar {
			display: block;
		}
	}
	.gray-head {
		searchable-entity {
			display: inline-block;
			vertical-align: middle;
			width: 370px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.map-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		top: @header-height + @gray-head-height;
		bottom: @footer-height;
		overflow: hidden;
		.map {
			width: 100%;
			height: 100%;
		}
		.map-info-window {
			width: 250px;
			min-height: 100px;
			overflow: hidden;
			font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
			font-size: 14px;
			.info-window-header {
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid fade(#000, 15%);
				.logo {
					@size: 16px;
					width: @size;
					height: @size;
					float: left;
					margin-right: 8px;
					background-image: url(/static/images/optimalblue/ob-logo.png);
					background-size: contain;
				}
			}
			.info-window-header,
			.info-window-body {
				& > * {
					&:not(:last-child) {
						margin-bottom: 8px;
					}
				}
			}
			h1,
			h2 {
				margin: 0;
			}
			h1 {
				.text-bigger;
			}
			h2 {
				.text-medium;
			}
			.nmls-id {
				.text-muted;
				.text-smaller;
			}
		}
		.map-filters {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 250px;
			.panel {
				margin: 0;
				padding: 0;
				.panel-heading {
					background-color: transparent;
					cursor: pointer;
				}
				.panel-body {
					.filter {
						&:not(:last-child) {
							margin-bottom: 10px;
						}
						.w11k-select {
							.dropdown-menu {
								right: 0;
								left: auto;
							}
						}
					}
				}
			}
		}
		.map-legend {
			position: absolute;
			bottom: 30px;
			left: 10px;
			width: 200px;
			.panel {
				margin: 0;
				padding: 0;
				.panel-heading {
					background-color: transparent;
					cursor: pointer;
					&.collapsed {
						border-bottom: none;
						.caret {
							transform: rotate(180deg)
						}
					}
				}
				.panel-body {
					ul {
						margin: 0;
						padding: 0;
						list-style: none;
						li {
							margin-bottom: 5px;
							.list-icon () {
								width: 16px;
								text-align: center;
							}
							&.relationships {
								.icon {
									display: inline-block;
									.list-icon();
									&:before {
										@size: 10px;
										content: '';
										display: inline-block;
										width: @size;
										height: @size;
										background-color: red;
										border-radius: 50%;
									}
								}
							}
							&.prospect {
								.icon {
									.glyphicon;
									.glyphicon-star;
									.list-icon();
									.text-muted;
								}
							}
						}
					}
				}
			}
		}
	}
}
