checklist {
	& > div {
		padding-left: 20px;

		.glyphicon {
			position: absolute;
			margin-left: -20px;
			top: auto;

			&.glyphicon-ok {
				color: @brand-success;
				margin-top: 2px;
			}

			&.glyphicon-remove {
				color: @brand-danger;
				margin-top: 3px;
			}
		}
	}
}
