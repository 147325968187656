.placeholder-mixin(@rules) {
	&::-webkit-input-placeholder {
		@rules();
	}

	&::-moz-placeholder {
		@rules();
	}

	&:-ms-input-placeholder {
		@rules();
	}
}

.generic-login {
	@footer-height: 70px;
	@color-gray: #888;
	@ob-orange: #F07402;
    @ob-blue: #192a54;
	@main-width: 960px;
	@logo-height: 50px;

	background-image: url(/static/images/login/bg.jpg);
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
	min-height: 660px;
	height: 100vh;
	flex-direction: column;
	align-content: stretch;

	.modal-login {
		.alert-danger {
			background: #fff;
			text-align: center;
			border-radius: 3px;
            margin: 0 0 20px;
            font-size: 14px;

			b {
				font-weight: normal;
			}

			&.collapsed {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.text-muted {
		color: @color-gray;
	}

	.link-orange {
		color: @ob-orange;
	}

	.link-white {
		color: #fff;
	}

	.text-holder {
		color: #666;
		display: inline-block;
		line-height: @logo-height;
		min-height: @logo-height;
		margin-left: 39px;

		.link-orange {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.logo-holder {
		.clearfix;
		width: @main-width;
		margin: 0 auto;
	}

	.login-logo {
		padding: 45px 0 42px;

		img {
			display: block;
			margin: 0 auto
		}
	}

	.main-wrapper {
		min-height: 0;
		padding: 0;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: flex-start;

		.modal-dialog {
			&.modal-login {
				@modal-width: 400px;
				@background-color: fade(@brand-primary, 100%);
				@login-button-color: @ob-blue;
				@main-border-radius: 17px;

				margin: 0;
				width: auto;

				.appraiser-check-holder {
					margin: 20px auto;
					text-align: center;

					.appraiser-check {
						color: #fff;
						font-size: 16px;
						line-height: 24px;
						opacity: 0.8;
					}
				}

				.modal-content {
					width: @modal-width;
					border-radius: @main-border-radius;
					background-color: @background-color;
					box-shadow: none;

					.modal-content-container {
						height: 100%;
						border-radius: @main-border-radius;

						&.remote-resource:after {
							border-radius: @main-border-radius;
						}
					}

					.modal-body {
						@form-control-height: 40px;
						height: 100%;
						padding: 40px;

						.form-control {
							height: @form-control-height;
						}

						.form-group {
							margin-bottom: (@form-control-height / 2);

							&.last-input-holder {
								margin-bottom: @form-control-height;
							}

							&.login-button-holder {
								margin-bottom: 6px;
							}

                            &:last-child {
                                margin-bottom: 0;
                            }
						}
						.btn {
							&.btn-lg {
								width: 100%;
								padding-top: 0;
								padding-bottom: 0;
								height: @form-control-height;
								font-size: 16px;
								line-height: @form-control-height;
							}

							&.btn-primary {
								.button-variant(#FFF; @login-button-color; @login-button-color);

								&:hover, &.hover, &:focus, &.focus, &:active, &.active {
									background-color: darken(@login-button-color, 7%);
									border-color: darken(@login-button-color, 7%);
								}

								text-transform: uppercase;
							}

							&.btn-white {
								color: @ob-blue;

								&:hover, &.hover, &:focus, &.focus, &:active, &.active {
									background-color: mix(#FFF, @ob-orange, 90%);
									border-color: mix(#FFF, @ob-orange, 90%);
								}
							}
						}
						.restore {
							position: relative;
							margin: 0 -10px 41px;

							.btn {
								font-size: 14px;
								line-height: 21px;
								color: #fff;
							}
						}

						input[type="text"], input[type="password"] {
							font-size: 16px;
							line-height: @form-control-height - 10;
							padding: 5px 14px;

							.placeholder-mixin({
								font-size: 16px;
								color: #686868;
								opacity: 1;
							});
						}

						.input-group {
							width: 100%;
						}

						.input-group-addon {
							background: @ob-blue;
							border-color: @ob-blue;
							width: 31px;
							padding-left: 0;
							padding-right: 0;
							text-align: center;
						}
					}
				}
			}
		}

		.modal {
			&.credentials-modal {
				@brand-primary: #42545d;
				@brand-danger: #98012e;
				.modal-dialog {

					.btn-primary, a.btn-primary {
						.button-variant(#fff; @brand-danger; @brand-danger)
					}

					.btn-default, a.btn-default {
						.button-variant(#fff; @brand-primary; @brand-primary);
					}

					.btn-lg {
						font-size: 16px;
					}

					.form-control {
						height: 45px;
						border-color: #ddd;
					}

					.control-label {
						line-height: 39px;
					}

					.has-error .form-control {
						border-color: @brand-red;
					}

					.text-danger {
						color: @brand-danger;
					}

					.modal-header {
						text-align: left;
						border: none;
						background: fade(@brand-primary, 10%);
						.modal-title, .close {
							color: @brand-primary;
						}
					}

					.modal-content {
						@prim25: fade(@brand-primary, 30%);
						@prim20: fade(@brand-primary, 20%);
						.box-shadow(~"0px 5px 7px @{prim20}, inset 0 0 1px 1px @{prim25}");
					}

				}
			}
		}

	}

	.global-footer {
		display: block;
		width: @main-width;
		//justify-content: center;
		margin: 0 auto;
		padding: 0 0 10px;
		height: auto;
		background: transparent;
		box-shadow: none;
		font-size: 12px;
		line-height: 17px;
		color: @brand-primary;
        text-shadow: #FFF 0 0 1px, #FFF 0 0 2px, #FFF 0 0 2px, #FFF 0 0 3px, #FFF 0 0 3px, #FFF 0 0 3px;

		a {
            opacity: 0.95;
			text-transform: uppercase;

            &:hover {
                opacity: 1;
            }
		}

		nav {
			float: none;
			margin: 0 auto;
			width: 660px;

			ul {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				padding: 0;
				margin: 0;

				li {
					a {
						color: @ob-blue;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
		.copyright {
			display: none;
		}
	}
}

@media (max-height: 768px) {
	.generic-login {
		.login-logo {
			padding: 0;
		}
	}
}
