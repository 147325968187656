.request-history-modal {
	.inline-mb-6 {
		display: inline-block;
		margin-bottom: 6px;
	}
	.fake-select {
		display: flex;
		flex-wrap: wrap;
		span {
			display: inline-block;
			background: #F4F5F7;
			margin: 2px 5px 2px 0;
			padding: 1px 7px;
			border-radius: 4px;
		}
	}
}
