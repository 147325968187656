@import "@{src}shared/account/information/insurance-bonds/insurance-bonds.less";

.insurance-bonds {
	.rows-bordered {
		> .row-fluid:last-child {
			border-bottom: none;
			margin-bottom: 15px;
		}

		> .row-fluid.header {
			color: #333;
			min-height: 34px;

			h4 {
				margin: 0;
			}

			.btn {
				border-radius: 0;
				margin-right: -15px;
			}
		}
	}
}
