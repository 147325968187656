.ng-select {
	&.ng-select-single {
		.ng-value-container {
			&, .ng-clear-wrapper {
				padding-top: 1px;
			}

			.ng-value {
				margin-top: -1px;
			}

			.ng-placeholder {
				top: 3px;
				padding-bottom: 2px;
			}
		}

		.ng-input {
			margin-top: -2px;
		}
	}

	&.ng-select-multiple {
		.ng-select-container .ng-value-container {
			overflow: hidden;
			padding: 0;
			border: 3px solid transparent;

			.ng-value {
				margin-bottom: 3px;
				margin-left: 0;
				margin-right: 3px;
				overflow: hidden;
				padding: 0;
				display: flex;

				.ng-value-label {
					overflow: hidden;
					text-overflow: ellipsis;
					display: inline;
				}
			}

			.ng-multiple-value {
				margin-top: -2px;
			}


			.ng-placeholder {
				top: 4px;
				padding-bottom: 0;
				padding-left: 5px;
			}

			.ng-input {
				margin-left: 0;
				margin-top: 0;
				padding: 0;
			}
		}

		&.ng-select-single-multiple {
			.ng-select-container .ng-value-container {
				overflow: hidden;
				padding-top: 0px;
				padding-left: 10px;

				.ng-single-value {
					padding-right: 5px;
				}
			}
		}
	}

	.ng-dropdown-panel {
		width: auto;
		min-width: 100%;
		max-width: 370px;
	}

	.ng-dropdown-footer {
		.ng-dropdown-footer-items {
			margin: -2px -5px;

			.ng-option {
				cursor: pointer;
				display: block;
				padding: 4px 10px;
				line-height: 20px;
				&:hover {
					background-color: @ng-select-marked;
				}
			}
		}
	}
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	padding: 4px 10px;
}
