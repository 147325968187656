@cover-aspect-ratio: 1.75;

.list-item-image-fn(@width, @height, @radius: 4px) {
	border: 1px solid #e6e7ec;
	border-radius: @radius;
	display: block;
	width: @width;
	height: @height;
	background: #F4F5F7 url(/static/images/no-listing-image.svg) center / cover no-repeat;

	&.nmls-active {
		background-image: url(/static/images/listings/nmls-active.svg);
		background-color: #fff;
	}

	&.nmls-inactive,
	&.removed-from-nmls {
		background-image: url(/static/images/listings/nmls-inactive.svg);
		background-color: #fff;
	}

	&.removed-from-nmls {
		position: relative;
		overflow: hidden;

		&::after {
			content: 'Removed';
			position: absolute;
			right: -22px;
			bottom: 11px;
			transform: rotate(-45deg);
			padding: 2px 20px;
			color: white;
			font-size: 8px;
			text-transform: uppercase;
			text-align: center;
			background-color: #D44444;
		}
	}
}

@import './groups/group-details/listing-group-details.less';

.company-listing-groups,
.company-listing-group-details,
.company-alerted-listings {
	.list-item-image {
		flex: 0 0 auto;
		.list-item-image-fn(150px, 150px/@cover-aspect-ratio);
	}

	.suggest {
		background: @brand-well;
	}

	.has-discrepancy {
		@color: saturate(mix(@brand-danger, #FFF, 50%), 20%);
		color: @text-color;
		background: @color;
	}

	.discrepancy-highlight {
		background-color: @state-danger-bg;
	}
}
