@import './review/facebook-review/facebook-review.component.less';
@import './review/common/comments/review-comments.component.less';
@import './review/reply/reply.component.less';

.extended-modal-body .modal-body {
	min-height: 120px;
}

.social-compliance-reviews {
	@spacing: 13px;

	.post {
		border: 1px solid #DDE4E9;
		margin: 0 0 20px 0;

		header {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #DDE4E9;
			padding: 18px 20px;

			.column:first-child {
				flex: 1;
			}
		}

		.content-holder {
			display: flex;
			align-items: flex-start;
			padding: 18px 20px;

			.column:last-child {
				flex: 1;
				padding-left: 20px;
			}

			.scores, .review-text, .reply-holder, .recommends {
				padding-top: @spacing;
			}

			.btn-reply-holder {
				margin-left: -2px;
			}
		}
	}

	.reply-text-holder {
		position: relative;
		padding: 13px 57px 13px 15px;
		background-color: #f4f5f7;
	}

	.reply-edit-icon {
		position: absolute;
		top: 13px;
		right: 18px;
		color: #9b9b9b;
		cursor: pointer;
	}

	.button-holder {
		min-width: 72px;
	}
}
