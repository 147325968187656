sm-link-input {
	.form-group {
		margin-bottom: 0;

		.form-control {
			padding-left: 30px;
		}

		.form-control-feedback {
			&.sm-link-input-network-icon {
				position: absolute;
				top: 0;
				right: initial;
				left: 0;
				opacity: initial;
			}
		}
	}
}
