solution-center-list {
    @product-card-column-width: @sc-preview-width + @sc-card-gap;

    .wrapper {
        width: @sc-page-width;
        margin: 0 auto;
        display: block;
        position: relative;
    }

    .sticky-filters {
        position: sticky;
        z-index: 1;
        top: 0;
        padding-block: 15px;
        background: #FFF;
        border-bottom: 1px solid @border-grey;
    }

    sc-company-card {
        cursor: pointer;
        display: block;

        &.selected {
            @selected-color: @brand-accent;
            cursor: default;
            border: 1px solid @selected-color;
            box-shadow:
                inset 0 0 5px 0 fade(@selected-color, 20%),
                0 0 10px 0 fade(@selected-color, 40%);
        }

        &:not(.selected):hover {
            h3 {
                text-decoration: underline;
            }
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .company-list {
        width: @product-card-column-width;
    }

    .company-preview {
        top: 101px; //height of the filter bar
        position: sticky;
    }
}
