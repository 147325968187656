.relationship-status-modal {
	.statuses {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		gap: 10px 0;
	}

	.confirmation {
		display: flex;
		align-items: center;
		margin-top: 20px;
		line-height: 2;

		input {
			margin: 0;
		}
	}
}