@sc-page-width: 1200px;
@sc-card-gap: 20px;
@sc-preview-width: 334px;
@sc-preview-height: 169px;
@sc-overview-width: @sc-page-width - @sc-preview-width - @sc-card-gap;
@sc-products-gap: 20px;
@sc-inner-padding: 20px;
@sc-border: 1px;

solution-center, solution-center-v2 {
    display: flex;
    flex-direction: column;
    margin-inline: -30px;
    min-height: @min-height-full;
    margin-bottom: -@gap-vertical;

    .split-view {
        flex: 1 0;
        display: flex;
        align-items: flex-start;
        position: relative;

        &.collapsed {
            .configuration {
                flex-basis: 100%;
            }

            .preview {
                flex-basis: 0;

                .scalable-content {
                    opacity: 0;
                }
            }
        }

        .preview-toggle {
            cursor: pointer;

            .preview-chevron {
                transition: scale 0.3s ease-in-out;
                &.reverse {
                    scale: -1 1;
                }
            }
        }

        .configuration {
            @sdw: 10px;
            flex: 1 0 60%;
            min-width: 640px;
            border-right: 1px solid @brand-grey-light;
            box-shadow: @sdw 0 @sdw -@sdw #0002;
            align-self: stretch;
            transition: flex-basis 0.3s ease-in-out;

            h3 {
                margin-block: 30px 5px;
            }

            .label-tags {
                display: flex;
                flex-wrap: wrap;
                white-space: nowrap;
                font-size: 10px;
                font-weight: 600;
                gap: 10px;

                .label-tag {
                    display: block;
                    line-height: 20px;
                    min-width: 22px;
                    padding: 0 8px;
                    border: 1px solid fade(@brand-primary, 40%);
                    border-radius: 11px;
                    background: fade(@brand-primary, 10%);
                }
            }

            quill-editor {
                display: block;
            }

            .ql-container {
                font-size: @font-size-base;
            }

            .ngx-quill-view-html, .ngx-quill-view {
                //border: 1px solid @brand-grey-light;

                .ql-editor {
                    padding: 8px 0;
                }
            }

            .gray-head {
                padding-inline: 30px;
                box-shadow: none;
            }

            .tab-content {
                padding-inline: 30px;

                .info-block {
                    margin: 30px 0 3px;
                    background: #F3F9FE;
                    padding: 5px;
                    border: 1px solid #DFE9FB;
                    border-radius: 5px;
                    color: #87B7E6;

                    ul {
                        padding-left: 30px;
                    }
                }


                .sticky-footer {
                    margin-inline: -30px;
                    padding: 15px 30px;
                    border-top: 1px solid #E7EAED;
                    background: white;
                    position: sticky;
                    bottom: 0;
                }

                .file-error {
                    border: 1px solid #dd534e;
                    color: #dd534e;
                    background-color: #FDF3F3;
                }
            }

            .header-block {
                margin-block: 10px;
            }

            .image-wrapper {
                display: flex;
                position: relative;
                border: 1px solid #ccc;
                box-sizing: content-box;
                aspect-ratio: 16/9;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .preview {
            padding: 10px 30px 30px;
            flex: 1 1 40%;
            overflow: hidden;
            position: sticky;
            top: 0;
            transition: flex-basis 0.3s ease-in-out;


            sc-company-card {
                box-shadow: 1px 1px 3px #0003;
            }

            .scalable-content {
                position: absolute;
                transform-origin: left top;
                transition: opacity 0.3s ease-in-out;
            }
        }

        .custom-uploader-container {
            height: 296px;
        }
    }
}

solution-center-v2 {
    .split-view {
        width: 100%;
        overflow: hidden;

        .preview {
            position: relative;
            align-self: stretch;
            background: #FAFAFA;
            padding-top: 120px;

            .scalable-content.centered {
                left: 50%;
                translate: -50% 0;
            }

            sc-company-card-v2 {
                width: 300px;
            }
        }

        .image-settings {
            max-width: 420px;
            padding: 5px 0 0 25px;

            image-size-slider {
                display: grid;
                grid-template-rows: auto auto;
                gap: 13px;

                .images-row {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    align-items: center;
                    gap: 12px;
                    padding: 0 1.5px;
                }

                .small-image {
                    width: 15px;
                    justify-self: start;
                }

                .large-image {
                    width: 20px;
                    justify-self: end;
                }

                input[type="range"] {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 8px;
                    outline: none;
                    opacity: 0.7;
                    transition: opacity .2s;
                    cursor: pointer;
                }

                input[type="range"]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    border: 3px solid #CCC;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    border-radius: 50%;
                }
            }

            image-position {
                display: inline-grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                justify-content: center;
                gap: 20px;

                .btn {
                    margin: 0;
                }
            }
        }
    }
}

sc-company-card, sc-company-overview-card {
    display: block;
    border-radius: 3px;

    .label-tags {
        display: block;
        margin-top: 3px;
        white-space: nowrap;
        font-size: 10px;

        .label-tag {
            display: inline-block;
            line-height: 18px;
            padding: 0 5px;
            border: 1px solid fade(@brand-primary, 40%);
            border-radius: 3px;
            background: fade(@brand-primary, 10%);
            margin-right: 5px;
        }
    }

    &.preview-mode {
        user-select: none;
        pointer-events: none;
    }
}

sc-company-card {
    border: 1px solid @brand-grey-light;
    width: @sc-preview-width;
    max-height: @sc-preview-height;
    padding: 10px;

    .cc-logo {
        overflow: hidden;

        &, img {
            height: 56px;
        }
    }

    h3 {
        margin-block: 7px 3px;
        color: @brand-primary;
        font-size: 19px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

sc-company-overview-card {
    border: @sc-border solid @brand-border-grey;
    border-radius: 4px;
    width: @sc-overview-width;

    &:empty {
        border: none;
    }

    .btn.btn-icon.sm-link-mini {
        margin-top: 10px;
        padding: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: center center;
        }
    }

    .gray-head {
        padding-inline: 20px;
        margin: 0;
        box-shadow: none;
        border-radius: 3px 3px 0 0;
        border-bottom-color: @input-border;

        .tab-menu li.active {
            border-color: @input-border;
        }
    }

    h2 {
        margin-block: 5px 10px;
        color: @brand-primary;
        font-size: 32px;
    }

    h3 {
        font-weight: 700;
    }

    .contact-data {
        overflow: hidden;
        gap: 2px 20px;
        flex-wrap: wrap;
    }

    .tab-inner {
        padding: @sc-inner-padding;
    }

    .label-tags .label-tag {
        padding: 5px 8px;
    }

    .flex-row {
        & > .c-icon, & > .glyphicon {
            top: 0;
        }
    }

    .ql-container {
        font-size: @font-size-base;
    }

    .ql-container, .ql-editor {
        display: inline;
        padding: 0;
    }

    .overview {
        max-height: 275px;
        overflow: hidden;

        img.pull-right {
            margin: 0 0 20px 20px;
        }

        .iw10 {
            width: 10%;
        }

        .iw20 {
            width: 20%;
        }

        .iw30 {
            width: 30%;
        }

        .iw40 {
            width: 40%;
        }

        .iw50 {
            width: 50%;
        }

        .iw60 {
            width: 60%;
        }


        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    .product-description {
        overflow: hidden;
        margin-top: 16px;
        max-height: 195px;
    }

    .label-tags {
        margin-top: -5px;
        white-space: break-spaces;

        .label-tag {
            margin-top: 5px;
        }
    }

    .product-list {
        display: flex;
        flex-wrap: wrap;
        gap: @sc-products-gap;
    }
}

sc-coc-single-product {
    gap: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid @input-border;
    border-radius: 3px;
    padding: 16px;
    width: ((@sc-overview-width - @sc-products-gap) / 2 - @sc-inner-padding - @sc-border);

    .flex-grow {
        flex-grow: 1;
    }
}

sc-coc-channels {
    h3 {
        margin-block: 0 10px;
    }

    .channel-block {
        min-height: 100px;
        padding: 16px;
        border: 1px solid @brand-border-grey;
        border-radius: 5px;

        h4 .glyphicon {
            line-height: 1.1;
        }

        + .channel-block {
            margin-top: 20px;
        }

        .actions {
            width: 100px;
            text-align: center;
            .btn {
                width: 100%;
            }
        }

        .contact-person {
            overflow: hidden;
            margin-top: 10px;
            gap: 2px 20px;
            flex-wrap: wrap;
        }

        .pending, .active, .suspend{
            color: @brand-grey-icon;
            margin-top: 20px;
            font-size: 32px;
        }
        .pending {
            margin-top: 8px;
        }
        .active {
            margin-top: 8px;
            color: @brand-green;
            small {
                font-size: 12px;
                color: @text-muted;
                font-weight: normal;
            }
        }
        .status-text {
            font-size: 16px;
            color: #000;
        }
    }
}

@sc-cc-card-width: 410px;
sc-company-card-v2 {
    --cc-br: 4px;
    --trany: 0.2s ease-in-out;
    display: block;
    border-radius: var(--cc-br);
    min-width: 300px;
    max-width: @sc-cc-card-width;
    border: 1px solid @input-border;
    box-shadow: 0 1px 4px fade(#000, 10%);
    background: #fff;
    transition: border-color var(--trany), box-shadow  var(--trany);

    .cc-logo {
        display: flex;
        justify-content: center;
        height: 104px;
        padding-block: 20px;

        img {
            height: 64px;
            margin: 0 auto;
        }
    }

    .cc-description {
        padding: 20px (20/300*100%) 0; // 20px for 300px width
        background: #F8F8F8;
        border-radius: 0 0 var(--cc-br) var(--cc-br);
        border-bottom: 20px solid transparent; // Used instead of padding-bottom so ellipsis works correctly
        height: 240px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 10;
        overflow: hidden;
        transition: color var(--trany), background-color var(--trany);
    }

    &.preview-mode {
        user-select: none;
        pointer-events: none;
    }
}

@sc-full-card-inner-width: 1060px;
@sc-full-card-width: @sc-full-card-inner-width + 2 * (@sc-border + @sc-inner-padding);
.modal-card-full {
    width: @sc-full-card-width;
    margin-bottom: 30px;

    .modal-content {
        border-radius: 4px;
        background: transparent;
    }
}

sc-company-full-card-v2 {
    display: block;
    background: #FFF;
    border: @sc-border solid @brand-border-grey;
    border-radius: 4px;
    width: @sc-full-card-width;

    &.remote-resource:after {
        border-radius: 4px;
    }

    &:empty {
        border: none;
    }

    .btn.btn-icon.sm-link-mini {
        img {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: center center;
        }
    }

    h2 {
        margin-block: 5px 20px;
        font-size: 32px;
    }

    h3 {
        font-weight: 700;
    }

    .gray-head {
        padding-inline: 20px;
        margin: 0;
        box-shadow: none;
        border-radius: 3px 3px 0 0;
        border-bottom-color: @input-border;

        h2 {
            font-weight: 600;
        }

        .modal .modal-new & .close {
            font-size: 32px;
            color: @text-color;
        }

        .tab-menu li.active {
            border-color: @input-border;
        }
    }


    .contact-data {
        overflow: hidden;
        gap: 2px 20px;
        flex-wrap: wrap;
    }

    .tab-inner {
        padding: @sc-inner-padding @sc-inner-padding 0;
        border-radius: 0 0 3px 3px;
    }

    .label-tags .label-tag {
        padding: 5px 8px;
    }

    .flex-row {
        & > .c-icon, & > .glyphicon {
            top: 0;
        }
    }

    .marketing-img {
        width: var(--img-width, 50%);
        margin-bottom: 20px;

        &.align-img-right {
            float: right;
            margin-left: 20px;
        }

        &.align-img-left {
            float: left;
            margin-right: 20px;
        }

        &.align-img-center {
            margin-inline: auto;
            display: block;
        }
    }

    .ql-container {
        font-size: @font-size-base;
    }

    .ql-container, .ql-editor {
        display: inline;
        padding: 0;
    }

    quill-view {
        display: block;
    }

    .overview {
        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    &.preview-mode {
        user-select: none;
        pointer-events: none;
    }
}

solution-center-v2-list {
    .gray-head {
        margin: 0;
    }

    .wrapper {
        max-width: 1800px;
        margin: 0 auto;
    }

    .sticky-filters {
        position: sticky;
        z-index: 1;
        top: 0;
        padding: 15px 30px;
        margin-inline: -30px;
        background: #FFF;
        border-bottom: 1px solid @border-grey;
    }

    .company-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 20px;
    }

    sc-company-card-v2 {
        &:hover {
            border-color: #99A1A4;
            box-shadow: 0 1px 12px 3px fade(#000, 30%);
            cursor: pointer;

            .cc-description {
                background: #F0F0F0;
                color: #000;
            }
        }
    }
}
