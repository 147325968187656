html {
  	height: 100%;
}

body {
	height: 100%;
    min-width: 1000px;
	word-wrap: break-word;
}

.cdk-overlay-container {
	position: absolute;
	z-index: 1030;

	.modal-open & {
		z-index: 5000;
	}

	// IE11 fix
	.fullscreen {
		lightbox {
			max-width: none;
			max-height: none;
		}
	}
}

.full-page-view {
	&,
	& > .main-wrapper {
		&,
		& > .well {
			&,
			& > ui-view-ng-upgrade {
				&,
				& > ui-view {
					&,
					& > ng-component {
						width: 100%;
						height: 100%;
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}

.center-vertically {
	.container.main-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

@import "./common-split/modal.less";

.main-wrapper {
	min-height: @min-height-full;
    &:after {
        height: @gap-vertical;
    }

	.partners {
		.row-fluid.list {
			a {
				text-decoration: none;
			}

			&:hover {
				background-color: rgba(86, 158, 253, 0.089);
			}
		}
	}
}

.yellow-bg {
	background: @brand-well;
	color: @brand-well-text;
}

.yellow-head {
	@bg-color: mix(@brand-well-bg, #fff, 10%);
	@shadow-color: mix(#000, #fff, 15%);
	background: @bg-color;
	color: #D4AE94;

	margin-top: -3px;
	margin-bottom: 0;
	padding: 15px 0;
	z-index: 1;
	position: relative;

	box-shadow:
		2*-964px 0   0 @bg-color,
		2*-964px 1px 0 @shadow-color,
		2* 964px 0   0 @bg-color,
		2* 964px 1px 0 @shadow-color,
		-964px   0   0 @bg-color,
		-964px   1px 0 @shadow-color,
		 964px   0   0 @bg-color,
		 964px   1px 0 @shadow-color,
	     0       1px 0 @shadow-color

;
}

.well.warning {
	background: @brand-well;
	color: @brand-well-text;
	padding: 10px 20px;
}

.text-default {
	color: @text-color;
}

.text-white {
	color: #fff !important;
}

.text-smaller {
	font-size: 12px;
}

.text-medium {
	font-size: 14px;
}

.text-bigger {
	font-size: 16px;
}

.text-big {
	font-size: 18px;
}

.text-huge {
	font-size: 32px;
}

.text-light {
	color: @text-muted;
}

.text-very-light {
	color: @brand-grey-light;
}

.text-normal {
	font-weight: 400;
}

.text-link {
	color: @brand-light-blue;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.control-label.required:after, .control-label.required-placeholder:after {
	content: ' *';
	color: @state-danger-text;
}

.control-label.required-placeholder:after {
	visibility: hidden;
}

.control-label.required.text-right {
	.text-overflow;
	&:before {
		content: '*';
		margin-left: 5px;
		float: right;
		color: @state-danger-text;
	}

	&:after {
		content: none;
	}
}


form.preview .control-label.required,
ng-form.preview .control-label.required {
	&:before, &:after {
		content: none;
	}
}

.require_errors, .grouped_errors {
	span {
		font-weight: bold;

		+ span:before{
			content: ",";
		}
	}
}

.navbar-default {
	height: @default-navbar-height;
	background: #fff;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04), 0 2px 3px 0 rgba(0, 0, 0, 0.04);
	border: 0;
	border-radius: 0;
	color: fade(#fff, 40%);
	z-index: 1001;
	margin-bottom: 0px;
    padding: 0 30px;

	.navbar-header {
		position: relative;
		.navbar-brand {
			padding: 0px 15px;
            img {
                margin: 6px 0;
            }
		}
		.project-version {
			position: absolute;
			right: 0;
			bottom: -3px;
			font-size: 75%
		}
	}

	.navbar-form {
		padding: 0 12px;

		.has-error {
			input, input:focus {
				box-shadow: none;
			}
		}
	}

	.navbar-nav {
		padding-left: 10px;
		> li, .open {
			> a {
                display: flex;
				cursor: pointer;
				height: @default-navbar-height;
                padding-block: 0;
                line-height: 27px;
                align-items: center;
				color: @main-menu-color;
				background: transparent;
			}
			> a:hover {
				color: @text-color;
				background: @main-menu-bg-active;
			}
		}

        .caret {
            margin-left: 5px;
        }

		.open {
			color: @text-color;
			background: @main-menu-bg-active;
		}

		> li.active {
			> a, > a:hover, > a:focus {
				background-color: @main-menu-bg-active;
				color: @text-color;
			}
		}
		> li > a.user {
			background: url('/static/images/user_icon.svg') no-repeat left;
			padding-left: 22px;
		}
		.icon-alert {
            margin-left: 5px;
            display: block;
            margin-bottom: 1px;
		}
		.glyphicon-cog {
			font-size: 17px;
			vertical-align: text-bottom;
		}
		&.user-nav {
			padding-left: 0;

			> li > a {
				padding: 6px 10px 1px 0;
				height: @header-height;

				&:hover, &:active {
					background-color: transparent;
				}
			}
			.open {
				background-color: transparent;

				.dropdown-toggle {
					color: @text-color;
					background-color: transparent;
				}
			}
			.glyphicon {
				font-size: 19px;
			}
			.c-icon {
				font-size: 36px;
				vertical-align: text-bottom;
			}

			.glyphicon-exclamation-sign {
				top: 10px;
				font-size: 12px;
				line-height: 12px;
				right: 17px;
			}
		}

		.dropdown-menu {
			border-radius: 2px;
			border: 1px solid rgba(0, 0, 0, 0.07);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

			li a {
				color: @main-menu-color;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .icon-alert {
                    margin-left: 5px;
                    margin-right: -5px;
                }
			}
		}
	}

	.user-menu {
		min-width: 180px;
		max-width: 240px;

		.organization {
			color: #000;
			padding: 3px 15px 0px;
			cursor: default;
		}

		li a {
			display: flex;
			color: @main-menu-color;
		}
	}


	.container {
		position: relative;
		padding: 0;
	}

}

.home-blue {
	background-image: url(/static/images/login/bg.jpg);
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
}

.big-icon {
	font-size: 32px;
}

.medium-icon {
	font-size: 24px;
}

.icon-warning {
	color: #faad00;
}

[ng-show-password] + .form-control-feedback, .pin-code-feedback {
	opacity: 0.25;
	transition: opacity 0.15s;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
}

.dates {
	input, .datepicker-wrapper {
		width: 120px;
		display: inline-block;
	}
	margin-bottom: 20px;
}

@import "./common-split/row-fluid.less";

.va-m {
	vertical-align: middle;
}

.va-b {
	vertical-align: bottom;
}

.va-t {
	vertical-align: top;
}

.inline-block {
	display: inline-block;
}

.col-w-logo {
	@padding: 35px;
	@logo-size: 21px;
	padding-left: @padding;

	.logo-image {
		width: @logo-size;
		height: @logo-size;
		margin-top: 3px;
		margin-left: -@padding;
		float: left;
		vertical-align: top;
		background: url("/static/images/optimalblue/ob-logo.png") no-repeat;
	}

	.col-md-check + & {
		padding-left: @padding + 10;

		.logo-image {
			margin-left: -@padding;
		}
	}
}

.comergence-icon {
	@logo-size: 21px;
	display: block;
	width: @logo-size;
	height: @logo-size;
	background: url("/static/images/optimalblue/ob-logo.png") no-repeat;
}

.alert {
	.text-alert {
		color: @alert-warning-text;
		text-decoration: underline;
	}

	&.text-branded {
		color: @brand-primary;
	}

	&.alert-button-slim {
		padding: 5px 5px 5px 10px;
	}
}

.alert:empty {
	display: none;
}

.with-buttons {
	line-height: @line-height-computed + 10px;
}

.content {
	h2 {
		margin: 0 0 20px;
		line-height: 33px;
		font-size: 28px;

		.right-menu {
			float: right;
			font-size: 16px;
			padding-top: 4px;
			.label-align {
				line-height: 25px;
			}
		}
	}

	h3 {
		margin: 0 0 20px;
		line-height: 28px;
	}

	.normalize {
		font-size: @font-size-base;
	}

	.margin-bottom-xs {
		margin-bottom: ceil((@line-height-computed / 4));
	}

	.margin-bottom-sm {
		margin-bottom: ceil((@line-height-computed / 2));
	}

	.margin-bottom-md {
		margin-bottom: @line-height-computed;
	}

	.margin-bottom-lg {
		margin-bottom: ceil((@line-height-computed * 2));
	}

	.margin-top-md {
		margin-top: @line-height-computed;
	}

	.margin-top-lg {
		margin-top: ceil((@line-height-computed * 2));
	}

	.margin-top-sm {
		margin-top: ceil((@line-height-computed / 2));
	}
}

.sticky {
    position: sticky;
    z-index: 1;

    &.block {
        padding: 10px 0;
        background: white;

        &.bottom {
            bottom: 0;
        }
    }
}

@import "./common-split/form-tables.less";

.grey-line {
	background-color: rgba(0, 0, 0, 0.03);
}

uber-editable {
	position: relative;
	display: block;
}

.standalone-search-field {
	position: absolute;
	top: 0;
	font-size: 16px;
	text-decoration: none;

	&.c-icon-search {
		opacity: 0.3;
		cursor: pointer;

		&:hover {
			opacity: 0.6;
		}
	}
}

.search-hint {
	position: absolute;
	list-style: none;
	min-width: 100%;
	z-index: 999;
	background: #fff;
	border: 1px solid @brand-grey-light;
	padding: 0;

	&, li {
		margin: 0;
	}

	li {
		padding: 4px 10px;
		white-space: nowrap;
		cursor: pointer;

		&.cursor {
			background: @brand-primary;
			color: #fff;

			&:hover {
				background: darken(@brand-primary, 10%);
				color: #fff;
			}
		}

		&.location {
			padding-left: 5px;

			&:before {
				display: inline-block;
				vertical-align: bottom;
				color: @brand-grey-light;
				font-family: 'Glyphicons Halflings';
				content: "\e062";
			}
		}

		&:hover {
			background: #eee;
			color: @text-color;
		}
	}
}

.inline-editing {
	.form-control, .btn {
		margin-top: -4px;
		margin-bottom: -4px;
	}
}


.w11k-menu {
	cursor: pointer;
	background: none;
	border: 0;
	.header-text,
	.header-placeholder {
		color: @brand-primary;
		font-size: @menu-item-size;
	}
	div.form-control {
		border: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		text-align: right;
		overflow: visible;
	}
	.dropdown-menu {
		line-height: 16px;
		left: auto;
		right: 0;
	}
	.caret {
		color: @brand-primary;
	}
}

.small-icon {
	font-size: 13px;

	.btn &.glyphicon-plus {
		font-size: 12px;
		top: 1px;
	}
}

.inline-toggle {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px 0 15px;
	padding: 0;

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 0 10px;
		margin-right: 10px;
		color: @brand-primary;
		cursor: pointer;
		text-decoration: underline;

		&.active {
			background-color: @brand-primary;
			border-radius: 14px;
			color: #fff;
			text-decoration: none;
			box-shadow: inset 0 2px 6px fade(#000, 25%);
		}
	}
}

.break-word {
	word-break: break-all;
}

.required-block-lg {
	line-height: 45px;
}

@import "./common-split/wired-labels.less";

.scorecard-summary {
	span:first-child {
		margin-right: 10px;
	}
}

.text-weak {
	color: @text-muted;
}

.callout{
	h4{
		font-size: 16px;
	}
	&.callout-warning {
		h4 {
			color: @brand-red;
		}
	}

	&.callout-success {
		h4 {
			margin-top: 0;
			margin-bottom: 23px;
			color: @brand-green;
			span {
				margin-right: 10px;
				font-size: 24px;
				vertical-align: sub;
			}
		}
	}
}

.clickable {
	cursor: pointer;
	a& {
		color: @text-color;

		&, &:hover {
			text-decoration: none;
		}
	}

	&.no-pointer {
		cursor: default;
	}
}

.unselectable {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.button {
	font-size: 12px;
	display: inline-block;
	vertical-align: middle;
	height: 20px;
	line-height: 18px;
	border: 1px solid;
	text-decoration: none;
	padding: 0 10px;
	border-radius: 3px;
	text-align: center;
	margin-right: 10px;
  &.vertical-stretch {
    height: auto;
    min-height: 20px;
  }

  &.button-success {
		color: @brand-success;
		background: #fff;
	}

	&.button-danger {
		color: @brand-danger;
		background: #fff;
	}

	&.button-italic {
		font-style: italic;
	}
}

h3 > a, a.underlined { text-decoration: underline; }

@media (max-width: 1024px) {
    body {
        width: 1024px;
    }
}

.header{
	/** OLD INCORRECT WAY BEGIN **/
	a {
		display: block;
		cursor: pointer;
		&:after{
			display: inline-block;
			vertical-align: baseline;
			font-family: "comergence-icons";
			font-size: 10px;
			margin-left: 10px;
		}

		&.display-inline {
			display: inline-block;
		}
	}

	.sort-up {
		&:after{
			content: '\e804';
			transform: rotate(180deg);
		}
	}

	.sort-down {
		&:after{
			content: '\e804';
		}
	}
	/** ------------------ END **/

	.sortable {
		transition: color 0.2s, box-shadow 0.2s;
		position: relative;
		padding-left: 0;

		& b {
			position: relative;
			padding-left: 15px;
			transition: padding 0.2s linear;
		}

		& b:before{
			content: '\e804';
			font-size: 9px;
			line-height: 10px;
			font-family: "comergence-icons";
			text-align: center;

			position: absolute;
			left: 8px;
			top: calc(~"50% - 5px");

			color: #333;
			opacity: 0;
			transform: rotate(180deg);
			transition: opacity 0.2s, transform 0s linear 0.2s;
		}


		&.asc, &.desc {

			color: #333;

			b {
				padding-left: 24px;
			}
		}

		&.asc b:before {
			opacity: 1;
			transition-duration: 0.2s;
			transition-delay: 0s;
		}

		&.desc b:before {
			opacity: 1;
			transform: rotate(360deg);
		}
	}

	.sortable:not(.not-active) {
		cursor: pointer;
		-webkit-user-select: none;
		user-select: none;

		&:hover {
			background: @border-grey;
		}
	}
}

.partner-filters-row {
    margin-left: -15px;
}

.partner-header {
    padding-left: 15px !important;
    padding-top: 9px !important;
}

.gray-head {
	@background: @brand-grey-bkg;
	@border: #E7EAED;
	@col-width: 880px;
	@duration: 0.3s;

	position: relative;
	min-height: @gray-head-height;
	margin: 0 0 30px;
	padding: 10px 0;
	background: @background;
	border-bottom: 1px solid @border;
	box-shadow:
		-@col-width -1px  0 @background,
		-@col-width 0     0 @border,
		 @col-width -1px  0 @background,
		 @col-width 0     0 @border,
		2*-@col-width -1px  0 @background,
		2*-@col-width 0     0 @border,
		2* @col-width -1px  0 @background,
		2* @col-width 0     0 @border;

	.remote-resource:after {
		background-color: fade(#efefef, 75%);
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: 999;
		top: 0;
		bottom: -1px;
		right: 0;
		left: 0;
		background: fade(#efefef, 75%);
		opacity: 0;
		visibility: hidden;
		transition: opacity @duration, visibility 0s;
		transition-delay: 0s, @duration;
	}

	&.disabled:after {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
		transition-duration: @duration;
	}

	h2 {
		margin: 0;
		padding-top: 10px;
		padding-bottom: 7px;
		line-height: 30px;
	}

	.tab-menu {
		margin: 0;
		padding: 0;
		list-style: none;
		white-space: nowrap;

		li {
			display: inline-block;
			border: 1px solid transparent;
			border-bottom: 0;
			border-radius: @border-radius-base @border-radius-base 0 0;
			margin-bottom: -10px;

			a {
				height: 40px;
				line-height: 40px;
				padding: 0 15px;
				color: @text-muted;

				:hover {
					color: @text-color;
				}

				b {
					font-weight: normal;
				}

				.icon-alert {
					position: relative;
					top: 3px;
				}
			}

			&.active {
				background: #fff;
				border-color: @border;
				box-shadow: 0 2px 0 -1px #fff;

				a {
					color: @text-color;
					text-decoration: none;

					b {
						font-weight: bold;
					}
				}
			}
		}
	}
}

@import "./common-split/flex.less";

.label.block {
	display: inline-block;
	white-space: normal;
}

.label.min-100 {
	&:extend(.label.block);
	min-width: 100px;
}

.label.min-65 {
	min-width: 65px;
}

.password-requirements{
	margin-bottom: 20px;
	h5 {
		font-weight: bold;
		margin-top: 0;
	}
	ul {
		color: @text-muted;
	}
	.glyphicon-ok-sign {
		color: @brand-green;
	}
	.glyphicon-minus-sign{
		color: @brand-grey;
	}
	.has-error ~ *{
		color: @brand-red;
	}
}

password-requirements-tooltip.v-align {
	display: block;
	margin: 4px 0 0 -20px;
}

.has-error .ng-select:not(.ng-select-opened) {
	.ng-select-container {
		border-color: @brand-red;
	}

	.ng-placeholder {
		color: fade(@brand-red, 90%);
	}
}

#livechat-compact-container, #livechat-full {
	left: 38%;
}

.vcenter {
  vertical-align: middle !important;
}

.resize-vertical {
	resize: vertical; /* user can resize vertically, but width is fixed */
}

.no-resize {
	resize: none; /* user can resize vertically, but width is fixed */
}

.icon-xs {
	font-size: 8px;
	vertical-align: middle;
	top: 0;
}

.rotate-90 {
	transform: rotate(90deg);
}

.dot {
	display: inline;
	white-space: nowrap;
	word-break: normal;

	//trick to allow correct work of &nbsp;
	&:before {
		@size: (5/7em); //10px on 14px font
		content: "";
		display: inline-block;
		width: @size;
		height: @size;
		//vertical-align: middle;
		border-radius: 50%;


		.dot-default& {
			background: @brand-primary;
		}

		.dot-success& {
			background: @brand-success;
		}

		.dot-warning& {
			background: @brand-warning;
		}

		.dot-danger& {
			background: @brand-danger;
		}
	}

}

.border-gray {
	border: 1px solid #ddd;
}

.w250 {
	width: 250px;
}

.w180 {
	width: 180px;
}

.w140 {
	width: 140px;
}

.w120 {
	width: 120px;
}

.w107 {
	width: 107px;
}

.w100 {
	width: 100%;
}

.no-margin {
	margin: 0;
}

.mb5 {
	margin-bottom: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb12 {
	margin-bottom: 12px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb30 {
	margin-bottom: 30px;
}

.mxw100 {
	max-width: 100%;
}

.pd5 {
	padding: 5px;
}

.pd10 {
	padding: 10px;
}

section {
	margin-top: 30px;
}

.display-inline {
	display: inline-block;
}

.display-block {
	display: block;
}

.counter {
	counter-reset: cnt;

	.countee {
		counter-increment: cnt;

		&:before {
			content: counter(cnt) ". ";
		}
	}

	&.alpha {
		.countee:before {
			content: counter(cnt, upper-alpha) ".\00a0";
		}
	}
}

a.disabled[a-disabled] {
	color: #333333;
	cursor: default;
	pointer-events: none;
	text-decoration: none;

	&.text-muted {
		color: @text-muted;
	}
}

.weight-normal {
  font-weight: normal;
}

.check-list{
	label {
  	padding-left: 7px;
  }
  .control-value {
  	padding-top: 0 !important;
  }
}

.bullet div:before {
	content: '\2219';
	font-weight: bold;
	vertical-align: middle;
}

.enter-word::before{
  content: "Enter "
}
.reenter-word::before{
  content: "Re-enter "
}

.label.label-default.light{
  background-color: #F0F0F0;
  color: @brand-dark-grey;
}

.gray-bg {
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -200%;
		right: 0;
		bottom: 0;
		left: -1000%;
		z-index: -1;
		background: #efefef;
		box-shadow:
		1280px 0 0 #efefef,
		2560px 0 0 #efefef;
	}

	&.remote-resource:after {
		background-color: fade(#efefef, 75%);
	}

	.rows-striped > .row-fluid {
		&:nth-child(2n - 1) {
			background: #fff;
		}
	}
}

.comfortable-textarea {
	min-height: 80px;
}

.hide-by-default {
	visibility: hidden;
}

.show-hidden {
	visibility: visible;
}

.common-upload {
	p {
		font-size: 18px;
	}
	.glyphicon-arrow-down {
		display: block;
		color: @brand-grey-icon;
		font-size: 54px;
		margin: 20px 0;
	}

	h4 {
		width: 200px;
		min-height: 50px;
		border: 1px solid #000;
		border-radius: 10px;
		padding: 15px 5px;
	}
}

.solid {
	white-space: nowrap;
	word-wrap: normal;
}

.solid-word {
	word-wrap: normal;
}

.small-link {
	color: @text-muted;
	text-decoration: underline;
}

.comments-list {
	.comment {
		margin: 10px 0 5px;
	}

	.comment-info {
		width: 200px;
		margin: 0 0 0 -15px;
	}

	.message-row {
		.label {
			position: relative;
			top: -1px;
			margin-left: 7px
		}
	}
}

/*
	Css pseudo table.
	Notice http://colintoh.com/blog/display-table-anti-hero
*/

.pseudo-tabel {
	width: 100%;
	display: table;

	&.fixed {
		table-layout: fixed;
	}

	&.aligned-center {
		.pseudo-cell {
			vertical-align: middle;
		}
	}

	.pseudo-row {
		display: table-row;
	}

	.pseudo-cell {
		display: table-cell;
		word-break: break-all;
	}
}

.light-link {
	text-decoration: underline;
	color: @text-muted;
	font-size: 12px;
	line-height: 14px;
	&:hover {
		text-decoration: none;
	}
}

.light-link-backward {
	text-decoration: none;
	font-size: 12px;
	line-height: 14px;
	&:hover {
		text-decoration: underline;
		color: @text-muted;
	}
}

a {
	&.underlined {
		text-decoration: underline;
	}
}

/* Form errors */
[form-errors],
[form-errors-proper] {
	padding: 0 !important;
	border: none;
	background: transparent;

	height: 0;
	position: relative;
	overflow: hidden;

	transition: height, margin;
	transition-duration: 0.1s;
	transition-timing-function: ease-in-out;
	transition-delay: 0.05s;

	&.collapsed {
		margin-top: 0;
		margin-bottom: 0;
	}

	.form-error-inner {
		&:extend(.alert);
		&:extend(.alert-danger);
		width: 100%;
		position: absolute;

		&:empty {
			display: none;
		}
	}

	&.error-tight .form-error-inner {
		padding-top: 6px;
		padding-bottom: 6px;
	}
}

.verify-pin {
	display: inline-block;
	width: 80px;
	min-width: 0!important;
	vertical-align: middle;
}

.pin-result {
	font-size: 24px;
	line-height: @input-height-base;
	vertical-align: middle;
	position: static;
}

.v-align {
	h2 {
		height: 200px;
		line-height: 200px;
	}
}

.export-button {
	margin-left: 15px;
}

.preformatted {
	white-space: pre-wrap;
}

.badges-holder {
	.space {
		display: inline-block;
		line-height: 27px;
	}

	.space:after {
		content: '\00a0';
	}

	.remove {
		cursor: pointer;
	}
}

.bg-white {
	background: #fff!important;
}

.bg-active {
	background: @gray-lighter;
}

.branded-text {
	color: @brand-primary;
	cursor: pointer;
}

.button-height {
	line-height: 34px;
}

.common-order-actions {
	a {
		vertical-align: middle;
		line-height: 27px;
		font-size: 24px;
		color: @text-muted;
	}

	a:hover {
		color: @brand-grey;
	}

	a.ng-hide:not(.ng-hide-animate) {
		display: inline !important;
		visibility: hidden;
	}
}

ul.pretty {
	@padding: 16px;
	padding-left: @padding;
	list-style: none;
	li:before {
		content: '\2022';
		float: left;
		margin-left: -@padding;
		font-size: 16px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

@import "./common-split/text-overflow.less";

.font-bold {
	font-weight: bold;
}

.font-italic {
	font-style: oblique;
}

.pull-right.glyphicon-question-sign ~ [custom-field] {
	width: ~"calc(100% - 30px)";
}

.block-all {
	@baze-z: 1010;

	&:before {
		content: "";
		display: block;
		position: fixed;
		background: fade(#fff, 75%);

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		visibility: hidden;
	}

	.block-all-inner {
		position: relative;
	}

	&.active {
		&:before {
			z-index: @baze-z;
			visibility: visible;
		}

		.block-all-inner {
			z-index: @baze-z + 1;
		}
	}
}

.relationship {
	.glyphicon {
		color: @brand-dark-grey;
	}
	.glyphicon +.glyphicon{
		color: @brand-grey-icon;
		top: -2px;
		left: -7px;
		z-index: -1;
	}
}

.glyphicon.form-aligned {
	top: 7px;
}

.color-yellow {
	color: #F7B446;
}

.inline-margin-right {
	margin-right: 10px;
}

.modal-403 {
	.denied-lock {
		height: 80px;
		background: url(/static/images/lock.svg) center no-repeat;
		margin-bottom: 30px;
	}

	p {
		margin: 10px 0;
	}
}

.overflow-label-wrapper {
	display: flex;

	.label {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

[realm-contenteditable] {
	.dynamic-content {
		float: left;
		margin-top: -25px;
		.dropdown-toggle {
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}

.contenteditable, .contenteditable-static {
	min-height: 180px;
	overflow: auto;

	.soc-icon {
		margin: 0 5px;
		display: inline-block;
		font-size: 24px;
	}
}

.contenteditable-static {
	min-height: 0;
	margin-bottom: 5px;
}

.tags-holder {
	.form-control;
	height: auto;
	min-height: 28px;
	padding: 2px 2px 0 2px;

	&.disabled {
		border-color: transparent;
		box-shadow: none;
		padding: 0;

		> ul > li:first-child {
			margin-left: 0;
		}
	}

	&.inline {
		display: inline-block;
	}

	> ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style-type: none;
		list-style-position: inside;
		overflow: hidden;

		.input-holder {
			flex-grow: 1;
			min-width: 100px;

			input {
				display: block;
				width: 100%;
			}
		}

		input {
			border: 0;
			outline: none;
		}

		.close-icon {
			margin: 0 0 0 5px;
			font-weight: bold;
			cursor: pointer;
		}

		.dropdown-menu {
			max-height: 240px;
			overflow-y: auto;
		}
	}
}

.alert-danger-link {
	color: inherit;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}

.alert-danger-link.disabled[a-disabled] {
	color: inherit;
}

.filters-rows {
	margin-bottom: 25px;

	.show-hide-filters {
		color: #63a0df;
		padding-top: 0;
		padding-bottom: 0;

		.c-icon {
			color: #acccec;
			top: -1px;
			margin-right: 3px;
		}
	}

	.filter-labels {
		.text-light,
		.text-muted {
			display: block;
			margin-bottom: 2px;
			font-size: 12px;
			line-height: 1.42;
			font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
			color: @text-muted;
		}
	}

	.filter-selectors {
		margin-bottom: 12px;
	}
}

.standalone-filters-applied {
	.yellow-bg;

	min-height: @input-height-base + 2 * 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

text-count, quill-count, .text-count {
    display: block;

    textarea + &, quill-editor + & {
        padding-top: 3px;
    }
}

form-show-password {
	outline: none;
}

.icon-alert {
	display: inline-block;
	width: 16px;
	height: 16px;
	background: data-uri('/static/images/icons/alert.svg') no-repeat;
}

@import "./common-split/recurly.less";

.relative {
	position: relative;
}

#resolverLoading {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;

	&.resolved {
		pointer-events: none;
		z-index: 0;
	}
}

.ellipsis {
	overflow:hidden;
	white-space:nowrap;
	text-overflow: ellipsis;
}

.paging-section {
	padding: 2rem;
}

.container.main-wrapper {
    padding-left: 30px;
    padding-right: 30px;

	.partners {
		.row-fluid.list {
				a {
					text-decoration: none;
				}
				&:hover {
					background-color:rgba(86, 158, 253, 0.089);
					cursor: pointer;
			}
		}
	}
}

.on-page-alert {
	&:extend(.alert);
	padding: 0px 0px 0px 5px;
	color: #dd534e;
	font-size: initial;
	margin-bottom: 0px;

	.icon-alert {
		margin-bottom: -3px;
	}
}

@import "./common-split/z-index.less";
