.ownership-update-list{
	.ssn-warning {
		padding-top: 15px;
		padding-bottom: 5px;
		//margin-bottom: -12px; RM-20518
		margin-top: 12px;
		background-color: @brand-well;
		label {
			padding-left: 10px;
		}
		p {
			margin: 10px;
			color: @brand-well-text;
			font-size: 12px;
			line-height: 14px;
		}
		.glyphicon{
			float: right;
			font-size: 25px;
			color: @brand-well-text;
			margin-right: 15px;
			width: 26px;
		}
	}

	&:last-child {
		.row, .row-fluid {
			&:last-child {
				border-bottom: none;
			}
		}
	}
}

.ownership-modal.modal-new {
	.modal-body {
		padding-bottom: 0;
	}

	.rows-bordered.ownership-actions {
		margin-top: -20px;
	}

	.rows-bordered {
		> .row, > .row-fluid {
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}
}

.contacts, .ownership-update-list{
	.label-not-verified, .not-verified, .label-verified{
		float: right;
		font-size: 13px;
		height: 22px;
		line-height: 14px;
		width: 95px;
		font-weight: normal;
		word-wrap: normal;
		padding-left: 5px;
		padding-right: 5px;
	}
}
.not-verified {
	color: @brand-red;
	border: 1px solid @brand-red;
}
.label-not-verified{
	&::before {
		content: "Not Verified";
	}
}
.label-not-verified:hover {
	&::before {
		overflow: hidden;
		content: "Verify";
		display: inline-block;
		width: 100%;
	}
	color: #fff;
	background-color: @brand-red;
	cursor: pointer;
}
.contact-modal {
	.row-space {
	  padding-bottom: 20px;
	}
	.btn-line {
	  padding: 0;
	  display: block;
	  height: 1px;
	  border: 0;
	  border-top: 1px solid @brand-disabled;
	  margin:0;
	}
}
.contact-modal {
	.modal-content {
		min-height: 500px;
	}
	.inner-msg {
		margin-top: 0;
	}
}
.verify-ownership-modal{
	.modal-header {
		border-bottom: none;
	}

	p.well {
		margin-bottom: 0;
	}
	p.success{
		margin-bottom: -15px;
		font-size: 16px;
		font-weight: bold;
		span {
			display: block;
			font-size: 50px;
			color: @brand-green;
			margin-bottom: 10px;
		}
	}
}
.contact-modal .modal-content {
	min-height: 500px;
	.row-fluid .alert{
		margin: -12px -14px 15px -14px;
	}
	.row-fluid .alert-success{
		margin-bottom: -12px;
	}
	.current-user {
		margin-top: -30px;

		.form-control-feedback {
			top: 4px;
			right: 15px;
		}
	}
	#ChangePasswordForm {
		.form-group {
			margin: 10px 0;
		}
	}
}
.contact-modal {
	.current-user {
		margin-top: -30px;

		.form-control-feedback {
			top: 4px;
			right: 15px;
		}
	}
}
.ownership-modal{
	margin-right: 0;
	margin-left: 0;

	.typeahead.dropdown-menu {
		max-height: 136px;
	}

	.no-individuals {
		position: relative;
		margin: -12px -29px;
		padding: 15px 40px;

		.close {
			position: absolute;
			top: 11px;
			right: 25px;
		}
	}
}
.individual-contact{
	&.subscriptions {
		.add-subscription {
			.form-horizontal {
				.form-group {
					.control-label {
						&:after {
							content: ":"
						}
					}
				}
				[form-errors] {
					.form-error-inner {
						margin: 0;
						padding: 4px 0;
						background: none;
						border: none;
						border-radius: 0;
					}
				}
			}
		}
	}
	.audit-trail {
		margin-bottom: 30px;
	}
	.btn ~ .control-label{
		line-height: 45px;
	}
	.notifications {
		background: @brand-grey-bkg;
		position: absolute;
		z-index: 3;
		padding: 5px 15px;
		right: 0px;
		left: 0px;
	}

	.pin-code-feedback {
		right: 15px;
		top: 0;
	}
}
.contacts {
	.glyphicon-question-sign{
		color: @brand-grey;
	}

	.loan-margin {
		@padding: 30px;
		padding-left: @padding + 15px;

		.tooltip.top-left {
			margin-left: -@padding - 5;
		}

		.c-icon-loan {
			float: left;
			margin-left: -@padding;
			margin-top: 3px;
			font-size: 20px
		}

		.c-icon-phone {
			float: left;
			margin-left: -@padding;
			margin-top: 3px;
			text-align: center;
		}
	}

	.rows-bordered .text-right .pull-right {
		margin-right: -15px;
	}

	.row-fluid .digital-media-actions-holder {
		height: 24px;
	}
}

[contact-merge-key] {
	.glyphicon {
		margin-right: 10px;
	}

	[type=radio] {
		margin-top: 3px;
	}
}

.contacts-email-list {
	.backface {
		background: mix(@brand-primary, #fff, 10%);

		.btn-primary-wired {
			background: #fff;
		}
	}
}

.originator-email-holder {
	.top-cell {
		z-index: 5;
	}

	.bottom-cell {
		z-index: 1;
	}

	.originator-email {
		.clearfix();
		position: relative;
		margin-right: -90px;

		input, a {
			display: block;
			float: left;
		}

		.btn {
			margin-left: 5px;
		}
	}

	&.row-fluid .form-control.short {
		width: 150px;
		min-width: 150px;

		.btn {
			margin-left: 5px;
		}
	}

	& .editing {
		.control-value {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.has-custom-error {
		.control-label {
			color: @brand-red;
		}

		.form-control {
			border-color: @brand-red;
		}
	}
}

.contact-pin-code {

	.verify-pin {
		width: 150px;
		margin-left: 15px;
	}

	.glyphicon.form-control-feedback {
		position: absolute;
		top: -1px;
	}
}
