.document-card.client-document {
	.front-face {
		position: relative;

		.status {
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: -1px;
			width: 5px;
			background-color: #d6d9dd;
			border-radius: 5px 0 0 5px;
			overflow: hidden;

			&-red {
				background-color: @brand-danger;
			}

			&-green {
				background-color: @brand-success;

				// DocuSign
				&.half-green {
					&::before,
					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 50%;
					}

					&::after {
						background-color: #808080;
					}
				}
			}
		}
	}
}
