.issues-list {
	.group-label {
		color: #808080;
		font-size: 14px;;
	}

	.list-holder .row-fluid:not(.header) {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	checklist > div, select-checkboxes > div {
		position: relative;
		margin-bottom: 11px;
		color: #333;
		line-height: 1.29;

		&:last-child {
			margin-bottom: 0;
		}
	}

	checklist > div {
		padding-left: 50px;

		.glyphicon {
			margin-left: -25px;
		}
	}

	select-checkboxes > div {
		padding-left: 25px;

		.checkbox-inline {
			padding-left: 25px;

			input[type="checkbox"] {
				margin-left: -25px;
			}
		}
	}
}
