.regulations-body {
	max-height: 460px;
	overflow-y: auto;

	.modal .modal-new & {
		padding: 0 20px;
		min-height: 80px;
	}

	.rows-bordered > .row-fluid.header > * {
		white-space: normal;
		padding: 6px 15px 5px;
	}

	.regulation-entry {
		min-height: auto;
		border-bottom: 0;

		ul {
			margin: 0;
			padding-left: 33px;
			list-style-type: none;

			li {
				padding-bottom: 8px;
				font-size: 13px;

				&:last-child {
					padding-bottom: 4px;
				}
			}
		}
	}
}
