new-inline-edit {
    @icon-selector: ~".inl-edit-btn";
    --pad: 5px;
    --underline-color: #d6d9dd;
    --color: #9B9B9B;
    --bg-color: @brand-grey-bkg;
    --dura: 0.2s;

    display: block;
    position: relative;

    .editable {

        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        text-decoration-line: underline;
        text-decoration-style: dashed;
        text-decoration-color: var(--underline-color);
        text-underline-offset: 4px;
        transition: background-color var(--dura) linear, filter var(--dura) linear;
        --shadow-color: #FFF0;
        filter: drop-shadow(1px 1px 5px var(--shadow-color));

        @{icon-selector} {
            display: inline-block;
            z-index: 1;
            position: absolute;
            text-align: center;
            padding-left: var(--pad);
            width: calc(20px + var(--pad));
            line-height: 20px;
            top: 0;
            left: 100%;
            pointer-events: none;
            color: transparent;
            background-color: transparent;
            transition: background-color var(--dura) linear, color var(--dura) linear;
            border-radius: 0 3px 3px 0;
        }

        &:hover{
            background-color: var(--bg-color);
            --shadow-color: #0002;

            @{icon-selector} {
                color: var(--color);
                background-color: var(--bg-color);
            }
        }

        > * {
            pointer-events: none;
        }
    }

    .popover {
        z-index: 1035;
        max-width: none;
        min-width: 100%;
    }

    .alert.alert-danger {
        background: none;
        border: none;
        border-radius: 0;
        padding: 5px 0 0;
        margin: 0;
        font-size: 12px
    }
}
