a {
	cursor: pointer;
}

.form {
	color: @gray-light;
}

.form-control {
	.padding(@height; @padding-horizontal: @padding-base-horizontal) {
		padding:
			((@height - (floor(@font-size-base * @line-height-base) + 2)) / 2)
			@padding-horizontal;
	}

	height: @input-height-base;
	line-height: @line-height-base;
	font-size: @font-size-base;
	border-radius: @input-border-radius;
	box-shadow: none;

	.padding(@input-height-base);

	&.input-lg {
		height: @input-height-large;
		border-radius: @input-border-radius-large;

		.padding(@input-height-large);
	}

	&:hover {
		border-color: darken(@input-border, 20%);
	}

	&:focus:hover {
		border-color: darken(@input-border-focus, 20%);
	}

	textarea& {
		// textarea bug with box-sizing
		min-height: @input-height-base;

		.padding(@input-height-base);
	}

	button& {
		text-align: left;
		position: relative;
		padding-top: 0;
		padding-bottom: 0;
		line-height: @input-height-base - 2;

		& .caret {
			position: absolute;
			top: (@input-height-base / 2) - 2;
			right: 10px;
		}
	}

	@placeholder-opacity: 0.6;

	&::-webkit-input-placeholder {
		color: inherit;
		opacity: @placeholder-opacity;
	}

	&::-moz-placeholder   {
		opacity: @placeholder-opacity;
	}

	&:-ms-input-placeholder  {
		opacity: @placeholder-opacity;
	}
}

.form-group {
	&.no-margin {
		margin-bottom: 0;
	}

	&.has-feedback {
		.form-control-feedback {
			width: @input-height-base;
			height: @input-height-base;
			line-height: @input-height-base;
			position: absolute;
			top: 0;
			right: 0;
			opacity: .5;
            pointer-events: all;

			&:hover {
				text-decoration: none;
				opacity: 1;
			}

            &.c-icon-search {
                pointer-events: none;
            }

			&.c-icon-eye {
				right: @padding-base-horizontal * 1.5;
			}
		}

		a.form-control-feedback {
			color: @text-muted;

			&:hover {
				color: @text-color;
			}
		}

		.input-lg ~ .form-control-feedback {
			width: @input-height-large;
			height: @input-height-large;
			line-height: @input-height-large;
		}

		&.left-position {
			.form-control {
				padding-right: 10px;
				padding-left: 27.5px;

				&:hover {
					border-color: #D6D9DD;
				}
			}

			.form-control-feedback {
				left: 0;
				right: auto;
				color: #CDCDCD;
			}
		}
	}

	&.form-group-show-password {
		.form-control-feedback.c-icon-eye {
			right: 5px;
		}
	}
}

.form-filter {
	@line-height: 20px;
	line-height: @line-height;
	padding-top: ((@input-height-base - @line-height) / 2);
	padding-bottom: ((@input-height-base - @line-height) / 2);
	display: inline-block;
}

button,
.btn {
	outline: none !important;
}

.btn {
	min-width: 70px;

	.padding(@height; @padding-horizontal: @padding-base-horizontal) {
		padding:
			((@height - (floor(@font-size-base * @line-height-base) + 2)) / 2)
			@padding-horizontal;
	}

	height: @input-height-base;
	line-height: @line-height-base;
	font-size: @font-size-base;
	border-radius: @btn-border-radius-base;

	.padding(@input-height-base);

	&.btn-lg,
	.btn-group-lg & {
		height: @input-height-large;
		line-height: @line-height-base;
		font-size: @font-size-base;
		border-radius: @btn-border-radius-large;

		.padding(@input-height-large);
	}

	// inspect 'bootstrap/less/mixins/buttons.less' for vendor mixin
	.btn(@color; @background; @hover-background; @active-background; @border) {
		&,
		&:focus {
			color: @color;
			background-color: @background;
			border-color: @border;
		}
		&:hover {
			background-color: @hover-background;

			& when not (@background = transparent) and not (@background = white) {
				border-color: @hover-background;
			}

            & when (@background = transparent) or (@background = white) {
                border-color: @border;
            }
		}
		&:active,
		&.active,
		.open > .dropdown-toggle& {
			background-color: @active-background;

			& when not (@background = transparent) and not (@background = white) {
				border-color: @active-background;
			}

            & when (@background = transparent) or (@background = white) {
                border-color: @border;
            }

			background-image: none;
			box-shadow: none;
		}
		&.disabled,
		&[disabled],
		&.visual-disabled,
		fieldset[disabled] & {
			&,
			&:hover,
			&:focus,
			&:active,
			&.active {
				color: @btn-disabled-color;
				background-color: @btn-disabled-bg;
				border-color: @btn-disabled-border;
				opacity: .5;
			}
		}
	}

	&.btn-toggle {
		.btn(#808080, transparent, #e6eaee, #e6eaee, transparent);

		&:hover {
			color: #333;
			font-weight: bold;
		}

		&.active {
			color: #333;
			font-weight: bold;
		}
	}

	&.btn-default, // legacy support
	&.btn-primary-wired, // legacy support
	&.btn-secondary {
		.btn(@btn-secondary-color, @btn-secondary-bg, @btn-secondary-hover-bg, @btn-secondary-active-bg, @btn-secondary-border);
	}

	&.btn-primary {
		.btn(@btn-primary-color, @btn-primary-bg, @btn-primary-hover-bg, @btn-primary-active-bg, @btn-primary-border);
	}

	&.btn-tertiary {
		.btn(@btn-tertiary-color, @btn-tertiary-bg, @btn-tertiary-hover-bg, @btn-tertiary-active-bg, @btn-tertiary-border);
		// RM-20865
		@padding-horizontal: 8px;
		padding-right: @padding-horizontal;
		padding-left: @padding-horizontal;
	}

	&.btn-success {
		.btn(@btn-success-color, @btn-success-bg, @btn-success-hover-bg, @btn-success-active-bg, @btn-success-border);
	}

	&.btn-success-wired, // legacy support
	&.btn-outline-success {
		.btn(@btn-outline-success-color, @btn-outline-success-bg, @btn-outline-success-hover-bg, @btn-outline-success-active-bg, @btn-outline-success-border);
	}

	&.btn-danger {
		.btn(@btn-danger-color, @btn-danger-bg, @btn-danger-hover-bg, @btn-danger-active-bg, @btn-danger-border);
	}

	&.btn-danger-wired, // legacy support
	&.btn-outline-danger {
		.btn(@btn-outline-danger-color, @btn-outline-danger-bg, @btn-outline-danger-hover-bg, @btn-outline-danger-active-bg, @btn-outline-danger-border);
	}

	.btn-fixed(@width; @padding-horizontal: @padding-base-horizontal) {
		padding-left: @padding-horizontal;
		padding-right: @padding-horizontal;
		min-width: @width;
		width: auto;
	}

	timepicker &.btn-link, timepicker &.btn-default {
		.btn(@btn-tertiary-color, @btn-tertiary-bg, @btn-tertiary-hover-bg, @btn-tertiary-active-bg, @btn-tertiary-border);
	}

	// square button with icon
	&.btn-icon, &.btn-icon-flex {
		width: @input-height-base;
		min-width: @input-height-base;
		padding-left: 0;
		padding-right: 0;

		.glyphicon,
		.c-icon {
			margin-right: 0;
		}
	}

    &.btn-icon-flex {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .glyphicon,
        .c-icon {
            top: 0;
            margin: 0;
            width: auto;
            height: auto;
        }
    }

	.btn-icon-add {
		@size: 10px;
		@thickness: 2px;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		width: @size;
		height: @size;
		margin-top: -2px;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
		}

		&:before {
			top: ((@size - @thickness) / 2);
			border-top: @thickness solid;
			width: @size;
		}
		&:after {
			left: ((@size - @thickness) / 2);
			border-left: @thickness solid;
			height: @size;
		}
	}

	&.btn-icon-disabled {
		&:hover, &:active {
			cursor: default;
			background: none;
			box-shadow: none;
		}
	}

	& + & {
		margin-left: 10px;

		.pagination & {
			margin-left: 0;
		}
	}

	.glyphicon,
	.c-icon {
		margin-right: 5px;
	}

    .caret {
        margin-left: 5px;
    }

    &.btn-dropdown {
        display: inline-flex;
        text-align: left;
        padding-inline: 8px;
        gap: 5px;
        align-items: center;

        > *:not(.caret) {
            flex: 1 1;
        }

        > .caret {
            flex: 0 0;
            margin: 0;
        }
    }
}

.control-label {
	color: @text-muted;
	font-weight: normal;

	.form-horizontal & {
		padding-top: 4px;
		padding-right: 0;
	}
}

input[type=checkbox], input[type=radio] {
	margin: 2px 0 0;
	width: 14px;
	height: 14px;
}

/** Fixing bootstrap datepicker **/
.datepicker {
	min-width: 270px;
	padding: 10px;

	table {
		min-height: 260px;

		thead tr:first-child .btn {
			font-size: 16px;
		}
	}

	.dow {
		text-transform: uppercase;
		line-height: 1.5em;
	}

	.btn-default {
		min-width: 32px;
		border-width: 0;
		padding: @padding-base-vertical 0;
		border-radius: 0;
	}

	.btn-default {
		color: @text-color;

		&:hover,
		&:focus,
		&:active,
		&.active,
		.open > .dropdown-toggle& {
			background-color: fade(@text-color, 5%);
		}
	}

	//Fixing selected items
	.btn-primary {
		.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
		font-weight: bold;
	}
}

.datepicker-wrapper {
	display: inline-block;
	width: 100%;
	position: relative;
}

.datepicker-button {
	.input-date.ng-hide ~ & {
		display: none;
	}

	.input-date ~ &, .input-date.ng-show ~ & {
		display: inline-block;
	}

	display: none;
	position: absolute;
	width: 30px;
	top: 0;
	right: 0;
	height: 28px;
	text-align: center;
	cursor: pointer;
	color: #999;
	.user-select(none);

	.datepicker-wrapper.disabled & {
		color: #999;
		cursor: not-allowed;
	}

	&:hover {
		color: #666;
	}

	.glyphicon {
		top: 6px;
	}
}

/** Fixing bootstrap timepicker **/
.timepicker {
	padding: 10px;
	table {
		thead .btn {
			font-size: 16px;
			.glyphicon {
				margin-right: 0;
			}
		}
	}
	.btn-default {
		padding: @padding-base-vertical 0;
		min-width: 32px;
		border-width: 0;
		border-radius: 0;
	}
	.btn-default {
		color: @text-color;
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: fade(@text-color, 5%);
		}
	}
	.btn-primary {
		font-weight: bold;
		.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
	}
	.btn + .btn {
		margin-left: 0;
	}
}

.timepicker-wrapper {
	display: inline-block;
	width: 100%;
	position: relative;
	.timepicker-button {
		position: absolute;
		width: 30px;
		height: 28px;
		top: 0;
		right: 0;
		text-align: center;
		cursor: pointer;
		color: #999;
		.user-select(none);
		&:hover {
			color: #666;
		}
		.glyphicon {
			top: 6px;
		}
	}
}

@timepicker-chevron-height: 15px;
timepicker {
	td {
		line-height: 1px;
	}

	td:nth-child(2) {
		user-select: none;
		cursor: default;
	}

	td:nth-child(4) {
		display: none;
	}

	.bs-timepicker-field {
		width: 40px;

	}

	.btn {
		min-width: auto;
		width: 100%;
		height: 100%;
		margin: 0;

		&.btn-link {
			text-align: center;
			height: @timepicker-chevron-height;
		}

		.bs-chevron {
			@border-width: 4px;
			width: 0;
			height: 0;
			margin: 0 auto;


			&.bs-chevron-up {
				top: 2px;
				transform: none;
				border-width: 0 @border-width @border-width;
				border-color: transparent transparent currentColor;
			}

			&.bs-chevron-down {
				top: 1px;
				border-width: @border-width @border-width 0;
				border-color: currentColor transparent transparent;
				transform: none;
			}
		}

		// AM/PM
		&.btn-default {
			margin-left: 5px;
			border: none;
			box-shadow: none;
			padding: 0;
			line-height: @input-height-base;
			width: @input-height-base;
		}
	}
}
.timepicker-padding {
	padding-top: @timepicker-chevron-height;
	padding-bottom: @timepicker-chevron-height;
}

input.input-date {
}

.input-lg {
	.input-size(@input-height-large; @padding-large-vertical; @padding-large-horizontal; 16px; @line-height-large; 3px);
}


.dropdown-menu > li {
	& > a,
	&.checkbox {
		padding: 3px 15px;
	}
	&.checkbox {
		white-space: nowrap;
		label {
			display: block;
		}
	}

	.checkbox-holder {
		padding-left: 20px;
		position: relative;

		input[type=checkbox] {
			position: absolute;
			margin-left: -20px;
		}
	}
}

[bs-select] {
	padding-right: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.select-multiple.dropdown-menu {
	> li > a {
		color: @gray-light;
		background-color: @dropdown-bg;
		padding-right: 30px;

		> i.pull-right {
			margin-right: -20px;
		}

		&:hover,
		&:focus {
			background-color: @dropdown-link-hover-bg;
		}
	}

	> .active > a {
		color: #000;
	}
}

.select.dropdown-menu {
	max-height: 26*10 + 12px;
	overflow-y: auto;
    overflow-x: hidden;

	.form-control + & {
		min-width: 100%;
		margin-right: 30px;
	}
}

.glyphicon {
	top: 2px;
}

.text-nowrap {
	word-wrap: normal;
}

.text-wrap {
	white-space: normal;
}

.tooltip {
	filter: drop-shadow(0 2px 5px fade(#000, 20%));
}

.tooltip-inner {
	box-shadow: 0 0 0 1px @tooltip-border-color;
	white-space: normal;
	text-align: left;
	padding: 5px 10px;
}

.tooltip.top-right {
	margin-top: -(5px + @tooltip-arrow-width);
	margin-left: @tooltip-arrow-width;

	.tooltip-arrow {
		bottom: -@tooltip-arrow-width;
	}

}

.tooltip.top {
	margin-top: -(12 + @tooltip-arrow-width);
}

.tooltip.top-left {
	margin-top: -(5px + @tooltip-arrow-width);
	margin-left: -@tooltip-arrow-width;
	.tooltip-arrow {
		bottom: -@tooltip-arrow-width;
	}
}

.tooltip.bottom-right {
	margin-top: (5px + @tooltip-arrow-width);
	margin-left: @tooltip-arrow-width;

	.tooltip-arrow {
		top: -@tooltip-arrow-width;
		right: 8px;
	}
}

.tooltip.bottom-left {
	margin-top: (5px + @tooltip-arrow-width);
	margin-left: -@tooltip-arrow-width;

	.tooltip-arrow {
		top: -@tooltip-arrow-width;
		right: 8px;
	}
}

.tooltip {
	pointer-events: none;
	opacity: 1;

	.tooltip-arrow {
		transform: none !important;
	}

	.tooltip-arrow:after {
		content: "";
		display: block;
		position: absolute;
		border-style: solid;
		border-color: transparent;
		z-index: -1;
	}


	&.bottom-right, &.bottom-left, &.bottom {
		.tooltip-arrow:after {
			border-bottom-color: @tooltip-border-color;
			border-width: 0 (@tooltip-arrow-width + 1) (@tooltip-arrow-width + 1);
			left: -(@tooltip-arrow-width + 1);
			top: -2px;
		}
	}

	&.left {
		.tooltip-arrow:after {
			border-left-color: @tooltip-border-color;
			border-width: (@tooltip-arrow-width + 1) 0 (@tooltip-arrow-width + 1) (@tooltip-arrow-width + 1);
			left: -(@tooltip-arrow-width - 1);
			top: -(@tooltip-arrow-width + 1);
		}
	}

	&.right {
		.tooltip-arrow:after {
			border-right-color: @tooltip-border-color;
			border-width: (@tooltip-arrow-width + 1) (@tooltip-arrow-width + 1) (@tooltip-arrow-width + 1) 0;
			left: -1px;
			top: -(@tooltip-arrow-width + 1);
		}
	}

	&.top-right, &.top-left, &.top {
		.tooltip-arrow:after {
			border-top-color: @tooltip-border-color;
			border-width: (@tooltip-arrow-width + 1) (@tooltip-arrow-width + 1) 0;
			left: -(@tooltip-arrow-width + 1);
			top: -@tooltip-arrow-width;
		}
	}

	&.tooltip-top {
		margin-top: -3px;
		margin-left: 7px;
	}

	&.wide {
		width: 200px;
	}
}

.typeahead.dropdown-menu {
	max-height: 26*10 + 12px;
	overflow-y: auto;
    overflow-x: hidden;
	padding: 0;

	li {
		cursor: pointer;
		border-bottom: 1px solid #ccc;

		&.active a {
			color: inherit;
			background: inherit;
		}

		a:hover {
			background: #f7f7f7;
		}

		&:first-child {
			border-radius: @border-radius-base - 1 @border-radius-base - 1 0 0;
		}

		&:last-child {
			border-bottom: 0;
			border-radius: 0 0 @border-radius-base - 1 @border-radius-base - 1;
		}
	}

	.form-control + & {
		min-width: 100%;
		margin-right: 30px;

		.row-fluid & {
			min-width: calc(~"100% - 4px");
		}
	}
}

.form-control{
	&.ng-hide-add, &.ng-hide-remove, &.ng-hide-add-active, &.ng-hide-remove-active,
	&.ng-show-add, &.ng-show-remove, &.ng-show-add-active, &.ng-show-remove-active {
		-moz-transition: none;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}

.remote-filter {
	@duration: 0.3s;
	min-height: @input-height-base;
	position: relative;

	&:before {
		.form-control();
		content: "";
		margin-bottom: -@input-height-base;
		background-color: @input-bg-disabled;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		background: fade(#fff, 75%) url("/static/images/loader-linear-primary.gif") 50% 50% no-repeat;
		opacity: 0;
		visibility: hidden;
		transition: opacity @duration, visibility @duration;
	}

	&.on-gray-bg:after {
		background-color: fade(#f7f7f7, 75%);
	}

	&.loading:after {
		visibility: visible;
		opacity: 1;
	}
}

.label-not-verified {
	color: @brand-red;
	border: 1px solid @brand-red;
}
.label-verified {
	color: @brand-green;
	border: 1px solid @brand-green;
}

.label {
	display: inline-block;
	padding: 0.3em 0.5em;

	&.in-control {
		position: relative;
		top: -1px;
	}
}

.label-text-normal {
	font-weight: normal;
}

.dropdown-menu {
	.checkbox, .radio {
		margin: 0;
	}
}

.dropdown-menu-col-fix {
	.dropdown-menu-right {
		right: -@padding-base-horizontal;
	}
}


.pull-right > .dropdown-menu {
	&.bottom-right, &.top-right {
		right: auto;
	}
}

.alert {
	border-width: 1px;
	border-style: solid;
	border-radius: @border-radius-base;
}
.alert-info {
	color: @text-color;
	background-color: @btn-secondary-hover-bg;
	border-color: @btn-secondary-border;
}
.alert-danger {
	color: #dd534e;
	background-color: @btn-outline-danger-hover-bg;
	border-color: #f6e2e1;
}
.alert-warning {
	color: #b5845e;
	background-color: #fdf9e8;
	border-color: #eae1ca;
}
.alert-success {
	color: #55a95f;
	background-color: #edf7ef;
	border-color: #d3e6d9;
}

.alert-warning .form-group:not(.has-error) {
	.control-label {
		color: inherit;
	}
}

.panel-default > .panel-heading .remote-resource:after {
	background-color: @panel-default-heading-bg;
}

.progress-bar-default {
	background-color: @progress-bar-bg;
}

.popover.top > .arrow{
    margin-left: -3px;
}
