.market {
	.gray-head {
		margin-bottom: 0;
		searchable-entity {
			display: inline-block;
			vertical-align: middle;
			width: 370px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.forecast-holder {
		padding: 25px 10px;
		border-bottom: 1px solid #e5e5e5;
		.row-fluid {
			& > div:not(:last-child) {
				border-right: 1px solid #e5e5e5;
			}
		}
		.msa-holder {
			bottom: -11px;
			position: relative;
			z-index: 1;
		}
	}
	.inline-toggle {
		margin-left: 0;
		margin-right: 0;
	}
	.map-link {
		display: block;
		.text-bigger;
		&:hover {
			text-decoration: none;
		}
		.text {
			text-decoration: underline;
		}
	}
	.companies-list {
		.col-w-logo {
			.logo-img {
				margin-left: 0;
			}
			span.label-primary-wired {
				font-size: 10px;
				margin-top: 3px;
			}
		}
	}
}

.market-search-addition {
	a {
		display: block;
	}
}

.saved-filter {
	.remove-filter {
		float: right;
		position: relative;
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s;
		box-shadow: -4px 0 5px 4px #fff;
		background: #fff;
	}

	&:hover .remove-filter{
		opacity: 1;
	}
}

.prospecting-status {
	.star {
		.glyphicon;
		position: relative;
		top: 0;
		font-size: 1.5em;

		&.gold {
			.glyphicon-star;
			color: #ffd530;
		}

		&.grey {
			.glyphicon-star;
			color: #494949;
		}

		&.empty {
			.glyphicon-star-empty;
			color: #74a8da;
		}

		.plus {
			.glyphicon;
			.glyphicon-plus-sign;
			position: absolute;
			top: auto;
			right: 0;
			bottom: 0;
			font-size: .55em;
			color: #74a8da;
			background-color: #ffffff;
			border-radius: 50%;
			text-decoration: none;
		}
	}

	.tooltip-inner {
		.text-nowrap;
	}
}
