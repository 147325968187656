sm-icon {
	@size: 40px;

	display: inline-block;
	position: relative;

	sm-network-icon{
		width: auto;
	}

	.sm-icon {
		&-network-icon {
			font-size: @size !important;
		}

		&-profile-image {
			display: block;
			width: @size;
			height: @size;
			background-size: cover;
			border-radius: 50%;
		}

		&-badge {
			position: absolute;
			top: auto;
			right: -5px;
			bottom: 0;
			left: auto;
			font-size: 18px !important;
			text-shadow: 1px 0 #FFF, 0 1px #FFF, -1px 0 #FFF, 0 -1px #FFF;
			background-color: #FFF;
			border-radius: 50%;
		}
	}

	&.minified {
		@size: 26px;

		.sm-icon {
			&-network-icon {
				font-size: @size !important;
			}

			&-profile-image {
				width: @size;
				height: @size;
			}

			&-badge {
				right: -8px;
				font-size: 16px !important;
			}
		}
	}
}
