investor-crm-contact-list-general-information {
    .delete-list-button {
        margin-left: unset !important;
    }

	label {
		&.multiline {
			white-space: normal !important;
		}
	}
}

investor-crm-contact-list-criteria {
    .clause-field-not-found {
        border: solid;
        border-color: red !important;
        border-width: 1px;
    }
}