.social-compliance-monitoring-settings-company,
.social-compliance-monitoring-settings-individuals,
.social-accounts-individuals {
	.company,
	.account {
		display: flex;
		align-items: center;
	}

	.icon-container {
		margin-right: 15px;
	}

	.link-container {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@import 'company/company.less';
@import 'individuals/individuals.less';
