@w: 28px;
@h: 16px;

.field-icon {
	display: inline-block;
	width: @w;
	height: @h;
	vertical-align: middle;
	background-size: @w @h;
	margin-right: 5px;

	&.field-icon-Text {
		background: url('/static/images/fields/input.svg') no-repeat;
	}

	&.field-icon-Droplist,
	&.field-icon-MultipleSelection {
		background: url('/static/images/fields/select.svg') no-repeat;
	}

	&.field-icon-PhoneNumber {
		background: url('/static/images/fields/phone.svg') no-repeat;
	}

	&.field-icon-Number {
		background: url('/static/images/fields/number.svg') no-repeat;
	}

	&.field-icon-YesNo {
		background: url('/static/images/fields/yes-no.svg') no-repeat;
	}

	&.field-icon-Date {
		background: url('/static/images/fields/date.svg') no-repeat;
	}

	&.field-icon-Dollar {
		background: url('/static/images/fields/dollar.svg') no-repeat;
	}

	&.field-icon-Percent {
		background: url('/static/images/fields/percent.svg') no-repeat;
	}

	&.field-icon-Email {
		background: url('/static/images/fields/email.svg') no-repeat;
	}

}

