.social-media-post-header {
	display: flex;

	& > * {
		&:not(:last-child) {
			margin-right: 15px;
		}
	}

	.icon-container {
		flex: 0 0 auto;
	}

	.title-container {
		flex: 1 0 0;
		overflow-wrap: anywhere;

        .text-muted {
            color: rgb(187, 187, 187);
            display: block;
        }
	}
}
