.hecm {
	.matching {
		.rows-bordered {
			.row-fluid {
				input.input-control {
					&:extend(.form-control all);
					margin-left: 0px;
				}

				.nmls {
					position: relative;
					.icon {
						position: absolute;
						left: 90px;
						top: 9px;
						background-color: inherit;
					}
					input {
						width: 110px;
						padding-right: 25px;
					}
				}

				.nmlsValidation {
					border-color: #DD534F;
					box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eea7a4;
				}

				.error {
					input {
						&:focus, &:hover, &:focus:hover {
							.nmlsValidation;
						}

						.nmlsValidation;
					}
				}
			}
		}

		.matched {
			background: #f2f9ff;
		}
	}
}
