// extend holder class in @import "documents";
.lenders {
    &:extend(.documents all);
    .brand-title {
        color: @brand-primary;
    }
    .description {
        margin-top: 10px;
        max-height: 2*@line-height-computed;
        overflow: hidden;

        a { cursor: pointer; }

        &.full {
            overflow: visible;
            max-height: none;
        }
    }
}
