.activities-report {
	.filters {
		margin-bottom: 35px;
	}
	.summary {
		margin-bottom: 35px;
		padding-bottom: 25px;
		border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
		// TODO: make it common
		.item {
			padding-right: 20px;
			padding-left: 20px;
			line-height: 1.1;
			.text-center;
			&.item-value {
				span {
					.text-big;
					display: block;
					margin-bottom: 10px;
				}
			}
			&.item-label {
				span {
					.text-muted;
				}
			}
			&:not(:last-child) {
				border-right: 1px rgba(0, 0, 0, 0.15) solid;
			}
		}
	}
	.stats {
		margin-bottom: 35px;
		.inline-toggle {
			li {
				text-decoration: none;
				.counter {
					.text-muted;
				}
				&.active {
					.counter {
						color: inherit;
					}
				}
				&.disabled {
					cursor: auto;
					.text-muted;
				}
			}
		}
	}
}
