info-message-holder {
	position: fixed;
	top: 0;
	right: 0;
    bottom: 0;
	z-index: @zindex-modal;
	padding: 50px 10px 20px;
	overflow: hidden;
    pointer-events: none;

    .alert-container {
        z-index: 0;
        position: relative;
        overflow: hidden;
        width: 480px;
        margin: 0;
        padding: 5px 10px 10px 5px;
        border-radius: @border-radius-base;
    }

	.alert {
		outline: none;
        display: flex;
        align-items: center;
        background: #fff;
        padding-right: 30px;
        position: relative;
        border-radius: @border-radius-base;
        box-shadow: 2px 1px 6px fade(#000, 50%);
        gap: 15px;
        font-size: 13px;
        margin: 0;
        pointer-events: all;

        .alert-icon {
            flex: 0 0 40px;
            font-size: 40px;

            img {
                 width: 1em;
            }
        }

        .close {
            position: absolute;
            top: 5px;
            right: 5px;
            display: inline-block;
            text-align: center;
            width: 21px;
            height: 21px;
            line-height: 20px;
            border: 1px solid #999;
            border-radius: 3px;
        }

        a {
            text-decoration: underline;
        }

        h3 {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 5px;
        }

        &.server-error {
            background: mix(#fff, @brand-danger, 90%);

            img {
                filter: invert(63%) sepia(63%) saturate(6651%) hue-rotate(334deg) brightness(97%) contrast(77%);
            }
		}

        &.server-warning {
            background: mix(#fff, @brand-well-bg, 90%);

            img {
                filter: invert(77%) sepia(18%) saturate(4037%) hue-rotate(6deg) brightness(100%) contrast(101%);
            }
        }

        &.server-info {
            background: mix(#fff, @brand-primary, 90%);

            img {
                filter: invert(71%) sepia(67%) saturate(2855%) hue-rotate(184deg) brightness(96%) contrast(80%);
            }
        }

        &.server-success {
            background: mix(#fff, @brand-success, 90%);

            a {
                color: @brand-success;
            }

            img {
                filter: invert(89%) sepia(10%) saturate(1977%) hue-rotate(60deg) brightness(80%) contrast(90%);
            }
        }
	}
}
