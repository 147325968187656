ul.ivh-treeview {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	li {
		&:last-child {
			background: url('/static/images/tree/i_half.png') no-repeat 0 0;
		}
		padding-top: 20px;
	}
}

ul.ivh-treeview li.ivh-treeview-node-collapsed ul.ivh-treeview {
	display: none;
}

ul.ivh-treeview .ivh-treeview-twistie-leaf,
ul.ivh-treeview .ivh-treeview-twistie-collapsed {
	display: none;
}

ul.ivh-treeview {
	.ivh-treeview-node-collapsed {
		.ivh-treeview-twistie-collapsed {
			display: inline;
		}
	}
}

ul.ivh-treeview
.ivh-treeview-node-collapsed
.ivh-treeview-twistie-expanded {
	display: none;
}

.ivh-treeview-node {
	background: url('/static/images/tree/v-line.png');
	background-repeat: repeat-y;
	background-position: top left;
}

ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-leaf {
	display: inline;
}

ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-expanded,
ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-collapsed {
	display: none;
}

ul.ivh-treeview {
	list-style-type: none;
	padding-left: 0;
}

.expand {
	cursor: pointer;
	float: left;
	width: 50px;
	height: 18px;
	text-align: center;
	background: url('/static/images/tree/expand_line.png') 8px 0px no-repeat;
	padding-left: 17px;

	.glyphicon {
		top: 0px;
	}

	.expand-common {
		height: 18px;
		display: block;
		background-repeat: no-repeat;
	}

	.ExpandLeaf {
		.expand-common();
		background-image: url('/static/images/tree/expand_leaf.png');
	}
}

.ivh-treeview-node-leaf {
	.expand {
		width: 50px;
		background: none;
		padding-left: 0px;
	}
}

.tree-content {
	margin-left: 18px;
	min-height: 18px;
}

.tree-container {
	margin-left: 26px;
	zoom: 1;
}

ul.ivh-treeview .ivh-treeview-node-leaf .ivh-treeview-toggle {
	cursor: auto;
}

.ivh-treeview-twistie-expanded,
.ivh-treeview-twistie-collapsed {
	.glyphicon {
		background: #e0ecf7;
		border-radius: 50%;
		padding: 4px;
		border: 1px solid #c3d9f0;
		color: #66a1dd;
		font-size: 8px;
	}
}
