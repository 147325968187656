.territory {

	form {
		padding-bottom: 200px;
	}

	.max-h300 {
		max-height: 335px;
		overflow-x: hidden;
	}

	.list-style {
		list-style: none;
		padding: 0px;
		margin: 0px;
	}

	.columns-holder {
		column-count: 3;
		-moz-column-count: 3;
		column-gap: 0;
		li {
			display: inline-block;
		}
	}

	.w11k-select {
		.dropdown-menu {
			max-width: 130%;
			li {
				text-overflow: ellipsis;
			}
		}
	}

	.horizontal-list {
		.list-style;
		li {
			width: 315px;
			float: left;
			padding: 0 20px 10px 0;
		}
	}

	.modal-body {
		li {
			width: 200px;
		}
	}

	.statesList {
		.list-style;
		 > li {
			&:first-child {
				padding-top: 5px;
			}
			border-bottom: 1px solid #999;
			padding: 10px 0;
			b {
				font-size: 16px;
			}
			 ul {
				 .list-style;
				 .columns-holder;
				 li {
					 width: 315px;
					 padding: 0 0 10px 0;
					 label {
						 font-weight: normal;
					 }
				 }
			 }
		}

		.line-space {
			padding: 10px 0;
		}
	}

	.cities {
		ul {
			.list-style;
			.columns-holder;
			li {
				width: 270px;
				padding: 0 20px 10px 0;
				label {
					font-weight: normal;
				}
			}
		}
	}
}


