@keyframes rotate-loader {
    100% {
        transform: rotate(360deg);
    }
}

loader {
    display: inline-block;

    &.color-primary {
        color: @brand-primary;
    }

    &.color-warning {
        color: @brand-warning;
    }

    &.color-danger {
        color: @brand-danger;
    }

    &.color-success {
        color: @brand-success;
    }

    &.color-white {
        color: #FFF;
    }
}
