.comments-filter {
	i {
		margin-left: 10px;

		&.glyphicon-question-sign {
			top: -1px;
			color: #818181;
		}
	}
	bs-tooltip-container {
		.tooltip-inner {
			text-align: center;
		}
	}
}
