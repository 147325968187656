.mcr {

	@imageSize: 160px;

	&:extend(.documents all);

	.files {
		.frontface {
			padding: 20px 20px 14px 20px;
			.upload {
				right: 38%;
			}
		}
	}

	.import {
		.holder {
			float: left;
			width: 50%;
			padding: 0 30px;
			min-height: 250px;
			&:nth-child(2n+1) {
				border-right: 1px solid #d8d8d8;
			}

			img {
				max-width: @imageSize;
				max-height: @imageSize;
				margin: 0px auto;
				display: inline-block;
			}

			.row-space {
				margin: 0 0 10px 0;
			}

			p {
				margin-bottom: 18px;
			}
		}
	}
}

.mcr-state-data {
	.tab-menu {
		li a {
			text-decoration: none;
		}
		li:not(.active):hover a b {
			text-decoration: underline;
		}
	}

	.header .sub-header {
		margin-top: 5px;

		&.row-fluid {
			width: ~"calc(100% + 20px)";
			margin-right: -5px;
		}
	}

	.sum-sign {
		vertical-align: middle;
		font-family: serif;
		font-size: 24px;
	}

	.compensate-left {
		margin-left: -15px;
	}

	.compensate-right {
		margin-right: -15px;
	}
}

.mlo {
	&:extend(.mcr all);

	.files {
		.frontface {
			.upload {
				right: 0%;
			}
		}
	}
}

.mcr-analytics {
	.inline-toggle {
		&.tab-switcher {
			margin-left: 0;
		}
	}
	.report {
		section {
			&:first-child {
				margin-top: 0;
			}
		}
		.report-chart {
			.clearfix;
			.chart-filters {
				.controls-group {
					float: left;
					&:not(:last-child) {
						margin-right: 15px;
					}
					.statistics-code {
						width: 190px;
					}
					.year {
						width: 100px;
					}
				}
			}
		}
		.filters {
			& > * {
				display: block;
				float: left;
			}
			.year {
				width: 100px;
				margin-left: 5px;
			}
		}
		&.financial-ratios {
			.company-value {
				white-space: nowrap;
				& > div {
					display: inline-block;
					vertical-align: middle;
					&.value {
						margin-right: 10px;
						color: inherit; //reset
						line-height: inherit; //reset
						white-space: nowrap;
					}
					&.indicators {
						.trend {
							margin-right: 10px;
							.glyphicon;
							&.up {
								.glyphicon-chevron-up;
								.text-success;
							}
							&.down {
								.glyphicon-chevron-down;
								.text-danger;
							}
						}
						.company-hint {
							.glyphicon;
							&.exclamation {
								.glyphicon-exclamation-sign;
								.text-danger;
							}
							&.question {
								.glyphicon-question-sign;
								.text-light;
							}
						}
					}
				}
			}
		}
	}
}
