.accordion {
	.rows-bordered {
		margin: 0px;
	}

	.collapsible-holder {
		.row-fluid {
			background: #f0f6fc;
			padding: 12px 40px;
		}
	}

	.highlight {
		color: #75b8fc;
	}
}
