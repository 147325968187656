.group-fields {
	display: flex;

	.col {
		width: 49.5%;
		&:first-child { margin-right: 0.5%; }
		&:last-child { margin-left: 0.5%; }
	}

	.field-element {
		display: flex;
		align-items: center;
		min-height: 50px;
		padding: 8px 15px;

		&:hover {
			background: rgba(102, 161, 221, 0.1);
		}

		.glyphicon {
			top: 0;
		}

		&.system {
			border-bottom: #F5F5F5 2px solid;
		}
	}
}

@import "./additional-fields/additional-fields.component.less";
@import "./custom-fields-entities/entity-fields/entity-fields.component.less";
@import "./custom-fields-entities/entity-fields/details/edit-values-modal/edit-values-modal.component.less";
