.brandingLogin {

	h2 {
		font-size: 24px;
		margin-bottom: 0;
		+ small{
			font-size: 18px;
			color: #aaa;
		}
	}

	&.center .modal-dialog {
		width: 870px;
	}

	.modal-body {
		margin: 0;
		padding: 0;

		.alert {
			margin: -30px -50px 30px;
		}


		.brand {
			text-align: center;
			padding: 0px;

			.scrollable {
				position: relative;
				padding: 30px;
				overflow-y: auto;
				max-height: 450px;

			}

			.top-curtain {
				@color: #FFF;
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				top: 0;
				height: 30px;
				background: radial-gradient(ellipse 70% 110% at 50% 0, fade(@color, 100%), fade(@color, 0%));
				pointer-events: none;
			}

			.bottom-curtain {
				@color: #FFF;
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				bottom: 0;
				height: 30px;
				background: radial-gradient(ellipse 70% 110% at 50% 100%, fade(@color, 100%), fade(@color, 0%));
				pointer-events: none;
			}


			img {
				max-height: 64px;
			}

			p {
			//	text-align: justify;
			}

			color: #333 !important;

			.description {
				margin-top: 16px;
				&, & * {
					color: #333;
					font-size: 14px !important;
				}
			}

			h3 {
				font-size: @font-size-h3 !important;
			}
		}

		form {
			vertical-align: middle;
			padding: 30px 50px 10px;
			background-color: #EEEEEE;

			.form-group {
				margin-bottom: 20px;

				.btn {
					width: 100%;
					white-space: normal;
				}
			}

		  	.actions {
			  text-align: center;
			}

			hr {
				border-top-color: #bbbbbb;
				margin: 10px 0 25px 0;
			}
		}
	}
}

.registration-modal, .set-password {
	.glyphicon-question-sign {
		color: @brand-grey;
		font-size: 24px;
		padding-left: 0;
	}
}

.registration-modal {


	h3 {
		margin-top: 0;
		//text-transform: uppercase;
		text-align: center;
		margin-bottom: 30px;
	}
	.nmls-label{
		margin-top: -20px;
	}
	.popover {
		padding: 0;
		margin-top: -15px;
		position: relative;
		width: 100%;
		max-width: none;
		display: block;
		border-radius: 0;
		border-width: 0;
		background: #f7f7f7;
		box-shadow: 0 2px 8px -1px fade(#000, 30%), 0 0 0 1px #ccc;

		@arrow-size: 8px;

		> .arrow {
			border-width: @arrow-size;
			border-bottom-color: #ccc;
			border-top-width: 0;
			left: -@arrow-size;
			top: -@arrow-size;
			margin-left: 50%;
			text-align: left;
		}

		> .arrow:before {
			content: "";
			position: absolute;
			display: block;
			left: -@arrow-size;
			top: 2px;
			border: @arrow-size solid transparent;
			border-top: 0;
			border-bottom-color: #f7f7f7;
//			border-bottom-color: red;
		}
		> .arrow:after {
			display: none;
		}

		.row-fluid {
			padding: 8px 0;
		}

		transition: none;

		&.fade {
			display: none;

			&.in {
				display: block;
			}
		}
	}

	.form-group:not(.has-success):not(.has-error)  .control-label strong {
		color: #333;
	}

	.form-group {
		margin-bottom: 30px;

		&.no-margin {
			margin-bottom: 0;
		}
	}

	.with-icon {
		padding-left: 30px + 32px + 20px;

		.big-icon {
			position: absolute;
			margin-left: -52px;
		}
	}
}

.credentials-modal {
  .modal-body {
	padding: 30px 30px 5px 30px;
  }
}

.registration-confirm {
    .glyphicon-ok-sign {
        font-size: 80px;
        color: @brand-success;
    }

    .close {
        z-index: 1;
        position: absolute;
        width: 32px;
        text-align: 32px;
        right: 10px;
        top: 10px;
    }
}
