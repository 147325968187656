@magic-colors:
	#55ADFD,
	#A2D2ED,
	#70BE6A,
	#ED6162,
	#FDB9B7,
	#FFA756,
	#FFD498,
	#DAC8E4,
	#88A7B2,
	#D5B8A0;

@magic-gray: mix(#000, #fff, 15%);

.magic-colors(@property, @suffix: ~"", @saturation: 100%, @i: 0) when (@i < (length(@magic-colors))) {
	@n: @i + 1;
	&.color-@{n}@{suffix} {
		@{property}: mix(extract(@magic-colors, @n), #fff, @saturation);
	}
	.magic-colors(@property, @suffix, @saturation, @n);
}

.magic-colors() {
	//prevent recursion when all colors are processed
};

[magic-chart], .magic-chart {
	position: relative;

	.chart-tooltip {
		&, .label {
			color: @text-color;
			font-size: 12px;
			font-weight: 400;
		}

		.metric {
			display: table;
			width: 100%;
			.magic-colors(background-color, ~" .label:before");

			&.total .label {
				color: @text-color;
				font-weight: 600;
			}
			.label {
				max-width: 240px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}


		.label {
			color: @text-muted;
			display: table-cell;
			text-align: left;
			padding: 2px 10px 2px 0;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: top;

				width: 10px;
				height: 10px;
				margin-right: 7px;
				margin-top: 1px;
				border-radius: 50%;
			}

		}

		.value {
			display: table-cell;
			text-align: right;
			font-weight: 700;
		}

		white-space: nowrap;
		pointer-events: none;
		border: 1px solid @magic-gray;
		border-radius: 4px;
		display: inline-block;
		background: fade(#fff, 90%);
		box-shadow: 1px 1px 3px fade(#000, 10%);
		padding: 5px 10px;
		top: 50%;
		transform: translate(0%, -50%);
		left: 0;
		opacity: 0;
		position: absolute;
		z-index: 10001;
	}
	&.bar-chart .chart-tooltip {
		@backgroundColor: #fff;
		@borderColor: @magic-gray;

		&:after, &:before {
			content: '';
			height: 0;
			width: 0;
			position: absolute;
			top: 100%;
			left: 50%;
			border: solid transparent;
		}
		&:after {
			border-top-color: @backgroundColor;
			border-width: 5px;
			margin-left: -5px;
		}
		&:before {
			border-top-color: @borderColor;
			border-width: 6px;
			margin-left: -6px;
		}
	}

	.chart-container {
		width: 100%;
		height: 300px;
		overflow: hidden;

		.axis text {
			font: 10px sans-serif;
		}
		.axis {

			path.domain {
				fill: none;
				stroke: @magic-gray;
			}

			&.y path.domain {
				stroke: none;
			}

			&.x path.domain {
				stroke: @magic-gray;
				shape-rendering: crispEdges;
			}

			.bar-chart&.y path.domain {
				stroke: @magic-gray;
				shape-rendering: crispEdges;
			}

			line {
				fill: none;
				stroke: @magic-gray;
				shape-rendering: crispEdges;
			}

			text {
				font-size: 12px;
				font-weight: 400;
				fill: @text-muted;
				cursor: default;
			}
			.axis-arrow {
				fill: @magic-gray;
				stroke: none;
			}
			.axis-label {
				font-weight: 600;
			}
		}

		.point {
			fill: #fff;
			.magic-colors(fill);
		}

		.focus{
			fill: @magic-gray;
		}

		.line {
			fill: none;
			stroke-width: 2;

			&.dashed {
				stroke-dasharray: 4, 3;
			}

			.magic-colors(stroke);
		}

		.bar {
			rect {
				.magic-colors(fill, ~"", 60%);
				shape-rendering: crispEdges;
				transition: fill 0.25s;
			}

			&:hover rect {
				.magic-colors(fill, ~"", 75%);
			}

			.bar-label {
				line {
					fill: none;
					stroke: @gray;
				}
				text {
					font-family: sans-serif;
					font-size: 12px;
					font-weight: 400;
					fill: @text-muted;
				}
			}
		}
	}
}

.line-chart-legend {
	padding-left: 70px;

	.item {
		display: inline-block;
		margin-right: 20px;

		.icon {
			@width: 30px;
			display: inline-block;
			vertical-align: middle;
			position: relative;

			&:before {
				content: "";
				display: block;
				width: @width;
				border: 1px solid;
			}

			&:after {
				@size: 12px;
				content: "";
				position: absolute;
				display: block;
				width: @size;
				height: @size;
				left: ((@width - @size) / 2);
				top: (-@size/2 + 1);
				border: 2px solid;
				background: #fff;
				border-radius: 50%;
			}
		}

		.magic-colors(color);

		.label {
			font-size: 12px;
			color: @text-muted;
			font-weight: 400;
			padding-left: 5px;
			&.disabled {
				opacity: .5;
			}
		}

	}
}

.dot-chart-legend {
	display: block;
	margin: 0;
	padding: 0 0 0 70px;
	list-style-type: none;
	li {
		display: inline-block;
		margin-right: 20px;
		@size: 12px;
		.icon {
			display: inline-block;
			width: @size;
			height: @size;
			vertical-align: middle;
			border-radius: 50%;
			.magic-colors(background-color);
		}

		.label {
			font-size: @size;
			font-weight: 400;
			color: @text-muted;
			padding-left: 5px;
			vertical-align: middle;

			&.disabled {
				opacity: .5;
			}

			b {

				color: @text-color;
				margin-left: 10px;
			}
		}
	}

	.ng-dropdown-panel {
		left: 0;
	}

	.for-ng-select {
		width: 240px;
		text-align: left;
	}
}

.checkbox-legend {
	padding-left: 70px;
	@duration: 0.2s;

	.item {
		@size: 17px;
		@halfSize: (@size/2);
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
		position: relative;
		user-select: none;

		.hidden-box {
			width: 0;
			opacity: 0;
			position: absolute;
		}

		.hidden-box + .icon {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			width: @size;
			height: @size;
			font-family: 'Glyphicons Halflings';
			font-size: @size - 7;

			&:before {
				content: '';
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				z-index: 0;
				border: (@size/2) solid;
				border-radius: 50%;
				box-sizing: border-box;
				outline: none;
			}

			&:after {
				display: block;
				line-height: 1em;
				content: '\e013';
				color: #fff;
				position: absolute;
				z-index: 1;
				padding: 3px 0 0 3px;
				width: 100%;
				height: 100%;
			}
		}

		.label {
			vertical-align: middle;
			font-size: 12px;
			color: @text-muted;
			font-weight: 400;
			padding-left: 5px;

			&:hover {
				color: darken(@text-muted, 10%);
			}
		}

		.hidden-box:not(:checked) {
			+ .icon {
				color: @magic-gray;
				&:before {
					border-width: 2px;
				}
			}

			~ .label {
				color: @magic-gray;
				&:hover {
					color: darken(@magic-gray, 10%);
				}
			}
		}

		.magic-colors(color);
	}
}

.inline-chart {
	@bar-height: @input-height-large;

	.inline-bar {
		height: @bar-height;
		background: #f5f5f5;
		cursor: default;

		&, & .inline-bar-inner {
			border-radius: 2px;
		}

		.inline-bar-inner {
			height: 100%;
		}

		&.color1 .inline-bar-inner {
			background: #98d3ff;
		}

		&.color2 .inline-bar-inner {
			background: #ffa495;
		}

		&.color3 .inline-bar-inner {
			background: #b8e8a1;
		}
	}

	.inline-bar-value {
		color: @text-muted;
		display: block;
		line-height: 1em;
		height: 1em;
		margin-top: 5px;
		margin-bottom: 20px;
	}


	&.no-data {
		.inline-bar {
			&:before {
				line-height: @bar-height;
				color: @text-muted;
				content: "No Data";
				padding-left: 15px;
			}

			.inline-bar-inner {
				width: 0;
			}
		}

		.inline-bar-value {
			opacity: 0;
			cursor: default;
		}
	}
}
