.new-registration {
    padding-left: 10px;
    margin-left: 10px;
}

.password-icon {
    padding-top: 5px;
}

.grecaptcha-badge {
    bottom: 100px !important;
}

registration-password-creation {
    @header-padding: 5px;
    @footer-padding: 15px;

    @header-footer-gap: @header-height + @footer-height + @header-padding + @footer-padding;
    @min-height-full: calc(~"100vh - @{header-footer-gap}");

    min-height: @min-height-full;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        margin: 0 0 15px 0;
    }
}
