@checkbox-size: 17px;

.social-compliance {
	.order {
		.contacts {
			.c-icon-loan {
				margin-top: 10px;
			}

			input[type=checkbox] {
				width: @checkbox-size;
				height: @checkbox-size;
			}

			.btn-group {
				padding: 0;

				.dropdown-toggle {
					padding: 7px 0 7px 14px;
				}
			}
		}
	}
}
