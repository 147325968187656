.modal {
	@modal-separator-color: fade(#000, 10%);

	.modal-new {
		.modal-content {
			border-radius: 4px;
			box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.20);
		}

		.modal-body {
			padding: 20px 20px 20px;

			.alert, .well {
				&:not(.framed) {
					padding: 15px 20px;
					margin: -20px -20px 20px;
					font-size: 14px;
				}
			}

			.well {
				border-bottom: 1px solid #eae1ca;
			}

			&.no-top-spacing {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.text-max-height {
			max-height: 400px;
			overflow-y: auto;
		}

		.close {
			color: @text-muted;
			font-size: 24px;
			opacity: 0.5;
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-right: -7px;

			&:hover {
				opacity: 1;
			}
		}

        .strip-horizontal-padding {
            padding-left: 0px;
            padding-right: 0px;
        }

		.modal-header {
			background: transparent;
			color: @text-color;
			border-bottom: 1px solid @modal-separator-color;
			min-height: 60px;
			padding: 21px 20px 18px;

			.modal-title {
				font-size: 18px;
				text-align: left;
			}
		}

		.modal-footer {
			padding: 15px 20px;
			border-top: 1px solid @modal-separator-color;

			.btn + .btn {
				margin-left: 10px;
			}
		}

		.alert-danger:not([form-errors]) {
			border-bottom: 1px solid fade(@brand-danger, 10%);
		}

		&.modal-restore-link {
			.modal-body {
				min-height: 200px;
			}
		}

		.rows-striped, .rows-bordered, .rows-white {
			margin-left: -20px;
			margin-right: -20px;

			> .row, > .row-fluid, > new-paging > .row-fluid, > paginator > .row-fluid {
				padding-left: 5px;
				padding-right: 5px;
			}

			> new-paging > .row-fluid, > paginator > .row-fluid {
				margin: -8px;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.required-block {
			line-height: 30px;
		}
	}

	.modal-min-body {
		.modal-body {
			min-height: 155px;
		}
	}

	.alert {
		border-radius: 0;
	}
	[form-errors],
	[form-errors-proper] {
		.form-error-inner {
			border-width: 0 0 1px 0;
			border-radius: 0;
		}
	}

    &.show {
        opacity: 1;
        display: flex !important;

        .modal-dialog { .translate(0, 0); }
    }
}

.modal-backdrop {
    backdrop-filter: blur(5px);
    &.show {
        opacity: 1;
    }
}

modal-container {
	.modal-dialog {
		margin: 30px;
	}
}

