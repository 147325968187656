@import '../../../commons/components/layouts/full-page/full-page-layout.common.less';

.social-compliance-publisher-finding {
	.full-page-header {
		.page-title {
			margin: 0 auto;
			.full-page-layout-header-font();
		}
	}

	.content {
		overflow-y: hidden;

		.panel {
			@panel-width: 420px;
			@footer-height: 60px;

			flex: 0 0 @panel-width;
			display: flex;
			flex-direction: column;
			margin: 0;
			padding-bottom: @footer-height;
			width: @panel-width;
			border: none;
			box-shadow: 1px 0 0 0 #e6e7ec;

			.panel-heading {
				padding: 20px 20px 10px;
			}

			.panel-body {
				padding: 10px 20px 20px;
				min-height: 100%;
				overflow-y: auto;
				background-color: #fff;

				section:last-child {
					margin-bottom: 0;
				}
			}

			.panel-footer {
				position: fixed;
				bottom: 0;
				display: flex;
				align-items: center;
				width: @panel-width;
				height: @footer-height;
				padding: 0 20px;
				background-color: #fff;
				border: none;
				box-shadow: 0 -1px 0 0 #e6e7ec;

				.pull-right {
					margin-left: auto;
				}
			}
		}

		section {
			margin: 0 0 20px;
		}

		h3 {
			font-size: 18px; //TODO: Should be changed globally

			.c-icon-phone {
				position: relative;
				font-size: 18px;
				top: -1px;
			}
		}

		.btn-group {
			display: flex;
			width: 100%;

			.btn {
				flex-grow: 1;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		.empty-preview {
			flex-grow: 1;
			background: url("/static/images/only-logo-big.png") center center no-repeat;
		}

		finding-screenshot {
			flex-grow: 1;
			overflow-y: hidden;
			position: relative;
			text-align: center;

			&.resolved {
				overflow-y: auto;
			}

			.screenshot-preview {
				display: inline-block;
				max-width: 100%;
				max-height: 100%;

				&.zoom {
					max-height: none;
				}
			}

			&.editable img {
				cursor: crosshair;
			}

			.issue-pin-container {
				left: 0;
				top: 0;
				width: 100%;
				position: absolute;
				overflow: hidden;
				pointer-events: none;


				@issue-size: 45px;
				@issue-padding: 2px;
				.issue-pin {
					width: @issue-size;
					height: @issue-size;
					line-height: @issue-size - 2 * @issue-padding;
					text-align: center;
					font-size: 21px;
					position: absolute;
					z-index: 1;
					transform: translate(-50%, -50%);
					background: @brand-danger;
					color: white;
					border: @issue-padding solid white;
					border-radius: 50%;
					box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.5);
					user-select: none;
					pointer-events: all;
					cursor: pointer;

					&.selected {
						background: darken(@brand-danger, 20%);
					}
				}
			}

			.zoom-btn {
				position: fixed;
				margin-top: 20px;
				right: 40px;
				opacity: 0.8;
				background: #333;
				border-color: #333;

				&:hover {
					opacity: 1;
				}
			}

		}

		finding-audit-log {
			.pagination {
				.btn:not(.page) {
					display: none;
				}
			}
		}
	}

}

.modal .modal-new {
	max-height: 100%;

	.modal-body {
		&.document-history-header {
			padding-top: 0;
			padding-bottom: 0;
			border-bottom: 1px solid #E6E7EC;

			.header > * {
				border-bottom: none;
			}
		}

		&.document-history-scrollable {
			padding-top: 0;
			padding-bottom: 0;
			min-height: 32px;
			max-height: 300px;
			overflow: hidden;
			overflow-y: auto;

			.row-fluid:last-child {
				border-bottom: none;
			}
		}

		&.finding-audit-details {
			padding-top: 0;
			min-height: 100px;

			.header {
				border-bottom: 1px solid #E6E7EC;

				> * {
					border-bottom: none;
				}
			}
		}
	}
}

.social-compliance-add-finding {
	.finding-sreenshot-preview {
		margin-top: 20px;
		border-radius: 4px;
		overflow: hidden;
		display: inline-block;
		max-width: 100%;

		img {
			width: 100%;
		}
	}
}


@import './details/finding-details.component.less';
@import './issues/issue-list.component.less';
@import './checklist/checklist.component.less';
@import './task/task.component.less';
@import './post-preview/post-preview.component.less';
