
@button-size: 40px;
@border-radius-full: 5px;
@border-radius-inner: 3px;
@main-background-color: #66a1dd;
@hover-background-color: #70abe7;
@icon-color: #acccec;
@icon-font-size: 8px;
@text-font-size: 15px;
@inactive-text-color: #bcd8f5;
@inactive-inner-text-color: @icon-color;
@active-text-color: #66a1dd;

.panel-hider, .panel-shower {
	display: none;
}

.overflow-show {
	overflow: visible !important;
}

.menu-switcher {
	position: absolute;
	text-align: center;
	border: none;
	margin-left: -@button-size + 15px;
	top: 72px;
	height: @button-size;
	width: @button-size;
	line-height: @button-size + 2px;
	display: block;
	color: @icon-color;
	background-color: @main-background-color;
	box-shadow: 1px 2px 2px fade(#000, 20%);
	font-size: 22px;
	border-radius: 3px;
	cursor: pointer;
	z-index: 50;
	-webkit-user-select: none;
	user-select: none;

	&:hover {
		background: @hover-background-color;
	}

	&.active {
		border-radius: 3px 3px 0 0;

		&:hover {
			background: @hover-background-color;
		}
	}
}

.status-active-new, .status-inactive-new, .status-pending-new, .status-suspended-new {
	content: "";
	top: auto;
	right: 20px;
	margin-top: 6px;
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: @brand-success;
}

.status-inactive-new {
	background: @brand-danger;
}

.status-pending-new {
	background: @brand-warning;
}

.status-suspended-new {
	background: @text-muted;
}

.navigation-panel, .navigation-panel-tpo-account {
	position: relative;
	background-color: @brand-nav-panel-bkg;
	width: @menu-width;
	box-shadow: 1px 2px 2px fade(#000, 20%);
	z-index: 1000;
	border-radius: @border-radius-full;
	font-size: @text-font-size;

	li {
		.icon-alert {
			top: auto;
			right: 12px;
			margin-top: 12px;
			position: absolute;
		}

		ul {
			li {
				.icon-alert {
					margin-top: 0px; //@SEE RM-20730
				}
			}
		}
	}

	.status-active:after, .status-inactive:after, .status-pending:after, .status-suspended:after {
		content: "";
		top: auto;
		right: 20px;
		margin-top: 6px;
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: @brand-success;
	}

	.status-inactive:after {
		background: @brand-danger;
	}

	.status-pending:after {
		background: @brand-warning;
	}

	.status-suspended:after {
		background: @text-muted;
	}

	.panel-switcher {
		font-size: @icon-font-size;
		text-align: right;
		border-radius: @border-radius-full @border-radius-full 0 0;

		&:hover {
			background: @hover-background-color;
		}

		> a {
			height: @button-size - 10px;
			line-height: @button-size - 10px;
			display: block;
			color: @icon-color;
			text-align: right;
			padding: 0 8px;
		}
	}

	a {
		color: @inactive-text-color;
		cursor: pointer;
		text-decoration: none;
	}

	li {
		list-style: none;
	}

	.c-icon {
		color: @icon-color;
	}

	> ul {
		padding: 0;
		margin: 0;

		> li {
			padding: 0 6px;

			&.expanded:hover, &.active:hover {
				background: none;
			}

			&:last-child {
				border-radius: 0 0 @border-radius-full @border-radius-full;
				padding-bottom: 6px;
			}

			> a {
				display: block;
				line-height: @menu-item-size + 4;
				padding: 11px 0 11px @menu-padding;

				&:hover {
					background: @hover-background-color;
					border-radius: @border-radius-inner;
				}
			}

			> a:before {
				content: '\e806';
				font-size: @icon-font-size;
				font-family: "comergence-icons";
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				width: 30px;
				margin-left: -30px;
				margin-top: -1px;
				color: @icon-color;
			}

			&.single {
				> a:before {
					display: none;
				}
			}

			> ul {
				padding: 0 0 8px @menu-padding;
				display: none;
				color: @nav-panel-link-active;
				background-color: @brand-nav-panel-expanded;

				> li {
					padding: 4px 0;
					font-size: @text-font-size - 1px;

					&:before {
						margin-top: 0;
					}
				}
			}

			&.expanded {
				a {
					display: block;
					color: @nav-panel-link-dark;
					background-color: @brand-nav-panel-expanded;

					&:hover {
						color: @nav-panel-link-active;
					}
				}

				> a:before {
					content: '\e804'
				}

				> a, &.active > a {
					border-radius: @border-radius-inner @border-radius-inner 0 0;
					padding: 9px 0 7px @menu-padding;
				}

				> ul {
					display: block;
					border-radius: 0 0 @border-radius-inner @border-radius-inner;
				}

				li.active > a {
					color: @nav-panel-link-active;
				}
			}

			&.active {
				> a {
					background-color: @brand-nav-panel-expanded;
					color: @nav-panel-link-active;
					border-radius: @border-radius-inner;
				}
			}
		}
	}
}

.with-left-navigation {
    .content {
        .gray-head {
            margin-top: -20px;
        }
    }
}

@media (max-width: @screen-md-max) {
	.menu-switcher {
		color: @icon-color;

		&:hover {
			color: @icon-color;
		}

		&.active, &.active:hover {
			background: @hover-background-color;
			color: @icon-color;
		}
	}

	.with-left-navigation-header {
		padding-left: @menu-padding;
	}

	.navigation-panel-base {
		position: absolute;
		border-radius: 0 3px 3px 3px;
		top: 72px;

		.panel-switcher {
			display: none;
		}

		~ .content {
			padding-left: 30px;
		}

		> ul > li:first-child {
			border-top: none;
			padding-top: 6px;
		}

		.menu-switcher.active ~ & {
			display: block;
			z-index: 1000;
		}
	}
	.navigation-panel {
        &:extend(.navigation-panel-base all);
        display: none;
    }
    .navigation-panel-tpo-account {
        &:extend(.navigation-panel-base all);
    }

	.with-left-navigation.tight {
		.navigation-panel, .navigation-panel-tpo-account {
			width: @menu-width-tight;
		}
	}
}

@media (min-width: @screen-lg-min) {
	.with-left-navigation {
		margin-top: 20px;
		padding-left: @menu-width + 30px;

		.navigation-panel, .navigation-panel-tpo-account {
			float: left;
			margin-left: -@menu-width - 30px;
		}

		.content {
			width: 100%;
			float: left;
		}


		&.collapsed {
			padding-left: 30px;
		}

		&.tight {
			padding-left: @menu-width-tight + 30px;

			.navigation-panel, .navigation-panel-tpo-account {
				float: left;
				margin-left: -@menu-width-tight - 30px;
				width: @menu-width-tight;
			}

			&.collapsed {
				padding-left: 30px;

				.navigation-panel, .navigation-panel-tpo-account {
					margin-left: -@button-size - 15px;
					width: @button-size;
				}
			}
		}
	}

	.menu-switcher {
		display: none;
	}

	.panel-hider:checked {
		& ~ .navigation-panel, & ~ .navigation-panel-tpo-account {
			z-index: 100;
			margin-left: -@button-size - 15px;
			width: @button-size + 2px;
			height: @button-size - 2px;
			position: absolute;
			border-radius: 3px;

			.panel-switcher {
				-webkit-transform: scaleX(-1);
				-ms-transform: scaleX(-1); /* IE 9 */
				transform: scaleX(-1); /* IE 9 */
				border-radius: 3px;

				> a {
					text-align: center;
					height: @button-size - 2px;
					line-height: @button-size - 2px;
				}
			}

			> ul {
				display: none;
			}
		}
	}

    .has-warning {
        color: red
    }

    .has-warning-select {
        border: solid 1px red;
        border-radius: 4px
    }
}

