finding-post {
	@panel-width: 400px;
	@border-color: #e6e7ec;

	width: @panel-width;
	flex: 0 0 @panel-width;
	align-self: flex-start;
	display: block;
	background-color: #fff;
	border: 1px solid @border-color;
	border-radius: 3px;

	&:first-child {
		margin-left: auto;
	}

	&:not(:last-child) {
		margin-right: 30px;
	}

	&:last-child {
		margin-right: auto;
	}

	header {
		padding: 0 15px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid @border-color;
	}

	.post-wrapper {
		position: relative;
		&:not(.current) {
			min-height: 120px;
		}

		.loader {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			&.resolved {
				pointer-events: none;
			}
		}

		.social-compliance-publisher-finding .content & finding-screenshot {
			display: block;
			overflow-y: visible;

			.issue-pin-container {
				overflow: visible;
			}
		}
	}

	.post-content {
		padding: 15px;
		min-height: 100px;

		.social-media-post {
			padding: 0;

			&-header {
				.c-icon-notification {
					padding-top: 5px;
					width: auto;
					font-size: 28px;
					color: #DF524B;
				}
			}

			&-comments a {
				pointer-events: none;
			}

			.trigger-keyword {
				background: #ffacaa;
			}
		}
	}
}
