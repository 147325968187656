.embed-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F3F9FE;
  padding: 15px;
  border: 1px solid #DFE9FB;
  border-radius: 5px;
  color: #87B7E6;

  a {
    margin-left: 15px;
  }
}
