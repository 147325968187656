campaign-participants {
	.expandable {
		position: relative;

		.expandable-icon {
			position: absolute;
			left: 15px;

			&:before {
				content: '\e806';
				font: 8px 'comergence-icons';
				color: @brand-primary;
				position: relative;
				top: -2px;
			}
		}

		&[aria-expanded='true'] {
			.expandable-icon {
				&:before {
					content: '\E804';
				}
			}
		}
	}

	@offset: 25px;

	.contact {
		padding-left: @offset;
	}

	.accounts-list {
		padding: 0 !important;
		background-color: #f4f5f7;

		.row-fluid {
			&:last-child {
				border-bottom: none;
			}
		}

		.account {
			padding-left: @offset;
		}
	}
}
