.collapsible-card {
    border: 1px solid lightgray;
    border-top: none;
    border-radius: 6px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    margin-right: 5px;
    .card-title-bar {
        cursor: pointer;
        border-radius: 6px 6px 0 0;
        background-color: @light-blue;
        font-size: 18.7px;
        color: #FFFFFF;
        padding: 10px;
        position: relative;
        &:hover {
            background-color: lighten(@light-blue, 5%);
        }
        &.collapsed {
            .glyphicon {
                transform: rotate(90deg);
                color: #FFFFFF;
            }
        }
    }
    .summary-link {
        a {
            padding-top: 5px !important;
        }
    }
    .card-content {
        min-height: 10rem !important;
        padding-top: 10px;
        padding-bottom: 10px;

        .description-text {
            text-align: justify;
        }
        .right-column-alignment {
            margin-left: 20px;
        }
    }

    // TODO: Relay these components out. These styles are all hacks

    .no-left-padding {
        padding-left: 0px !important;
    }
    .bottom-row-absolute-hack {
        padding-bottom: 20px;
    }

    label {
        margin-bottom: 0px !important;
        padding-top: 4px !important;
    }

    .request-button-padding {
        padding-top: 4px;
    }
}
