.modal-content {
  .client-financial-settings-modal {
    @border: 1px solid #e6e6e6;

    .modal-body {
      overflow-y: auto;
      max-height: 80vh;
      padding-bottom: 75px;
    }

    .guidance-text {
      padding: 10px;
      border-radius: 5px;
      background: #f4f8fd;
      border: 1px solid #daeafc;
    }

    .settings-row, .settings-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .settings-block {
      padding: 15px 15px 35px;
      border-right: @border;
      border-left: @border;

      &.first {
        border-top: @border;
        border-radius: 5px 5px 0 0;
      }

      &.last {
        border-bottom: @border;
        border-radius: 0 0 5px 5px;
      }

      & > i {
        top: 4px;
      }

      .c-icon-globe-lines {
        top: -1px;
      }
    }
  }
}