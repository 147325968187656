.prospecting-status {
  .star {
    .glyphicon;
    position: relative;
    top: 0;
    font-size: 1.5em;

    &.gold {
      .glyphicon-star;
      color: #ffd530;
    }

    &.grey {
      .glyphicon-star;
      color: #494949;
    }

    &.empty {
      .glyphicon-star-empty;
      color: #74a8da;
    }

    .plus {
      .glyphicon;
      .glyphicon-plus-sign;
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      font-size: .55em;
      color: #74a8da;
      background-color: #ffffff;
      border-radius: 50%;
      text-decoration: none;
    }
  }

  .tooltip-inner {
    .text-nowrap;
  }
}