.post-type {

	.preview {
		display: table;

		.image-size {
			width: 80px;
			height:  80px;
		}

		.image-holder {
			flex-shrink: 0;
			overflow: hidden;
			border: 1px solid #dbdbdb;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			i {
				color: #e6e7ec;
				font-size: 28px;
			}

			.image-size;

			img {
				.image-size;
			}

			.post-image {
				.image-size;
				background-size: cover;
			}
		}

		.attachment-video {
			&:after {
				content: "";
				.image-size;
				background: transparent url("/static/images/video-play.png") center no-repeat;
				background-size: 36px;
				position: absolute;
				top: 0;
				left: 15px;
			}
		}

		.description {
			display: table-cell;
			vertical-align: middle;
			overflow: hidden;
		}

		&.horizontal-flex-group {
			display: flex;

			.description {
				display: block;
			}
		}
	}
}
