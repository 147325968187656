.alerts-settings {
	.columns-wrapper {
		h4 {
			font-size: 17px;
			line-height: 40px;
			span {
				color: @text-muted;
			}
			border-bottom: 1px solid @brand-grey;
		}

		.settings-value {
			display: inline-block;
			width: 50px
		}
	}
}
.alert-name {
	padding-left: 25px;
	input[type='checkbox'] {
		position: absolute;
		left: 5px;
		top: 10px;
	}
}
.alerts h2 small{
	font-size: 14px;
}
