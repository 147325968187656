publisher-attachments-preview {
	.attachments {
		overflow: hidden;

		&.remote-resource:not(.resolved) {
			min-height: 250px;
			margin-top: -18px;
		}
	}

	.attachments-preview {
		.attachment-video {
			&:after {
				background-size: 14%;
			}
		}
	}
}

twitter-post {
	.attachments {
		&.attachments-fix-holder:not(.resolved) {
			@min-width: 397px;
			min-height: @min-width / 1.78;
			min-width: @min-width;
			border: 1px solid #CCD6DD;
			border-radius: 15px;
			padding: 0;
			margin: 0;
			width: fit-content;
			overflow: hidden;
		}
	}

	.attachments-preview {
		border: 1px solid #CCD6DD;
		border-radius: 15px;
		overflow: hidden;

		img {
			object-position: 50% 0;
		}
	}
}
