.client-document-dropdown-menu {
	.file-input {
		display: block;
		margin: 0;
		padding: 3px 15px;
		color: @text-color;
		font-weight: normal;
		cursor: pointer;

		&:hover {
			background-color: @main-menu-bg-active;
		}

		input[type=file] {
			.hidden;
		}
	}
}

.dropdown-menu-right.dropdown-btn-icon {
	right: -@input-height-base;
}

.remove-right-padding {
    padding-right: 0px;
}
