.activity-details {
  h3 {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .activity-buttons {
      margin-left: auto;
    }
  }

  label {
    white-space: nowrap;
  }

  .rows-striped > .row-fluid:nth-child(odd), .rows-striped > .row:nth-child(odd) {
    input {
      background-color: #fff;
    }
  }
}
