.findings span {
	> span, > a {
		display: inline-block;
		padding-top: 7px;
	}

	.mark-reviewed {
		padding-top: 0;
	}

	.removed {
		display: block;

		.tooltip.top-left {
			margin-left: 0px;
		}
	}
}

.finding-prefix {
	margin-top: 2px;
	position: absolute;

	& + .finding-link {
		padding-left: 34px;
	}
}

.finding-link {
	display: inline-block;

	&.manual {
		padding-right: 34px;
		background: url('/static/images/manual_finding.png') right 3px no-repeat;
	}
}

.mark-reviewed {
	display: inline-block;
	width: 138px;
	height: 34px;
	min-height: 34px;
	overflow: hidden;

	button, .updated {
		display: block;
	}

	.text {
		position: relative;
		display: block;
		width: 100%;
		padding: 2px 0 0 30px;
		font-size: 14px;
		line-height: 1.2;

		top: -120px;
		opacity: 0;
		visibility: hidden;

		transition-property: opacity, top, visibility;
		transition-duration: 0.2s;

		font-size: 14px;

		> span, > small {
			display: block;
			text-align: left;
		}

		small {
			font-size: 12px;
		}

		.glyphicon {
			position: absolute;
			top: 10px;
			left: 1px;
		}

	}

	&.reviewed {
		height: auto;

		.text {
			visibility: visible;
			top: 0;
			opacity: 1;
		}

		button, .updated {
			display: none;
		}
	}

	.glyphicon {
		top: 0px;
	}
}

.row-fluid .mark-reviewed {
	margin-bottom: -6px;
}

.supporting-docs {
	li {
		margin-bottom: 10px;

		.glyphicon {
			margin-right: 10px;
			color: #000;
		}
	}
}

.finding-box {
	margin-bottom: 25px;
}

.finding-summary-icon-space {
    padding-left: 20px;
}

.findings-text-nonComergenceUser {
    padding-left: 0px;
}

.findings-text-comergenceUser {
    padding-left: 23px;
    display: inline-block;
    text-align: justify;
}
