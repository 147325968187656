.mortgage-related-pages-modal {
	.modal-body {
		.flex-grow {
			flex-grow: 1;
		}

		p {
			margin-bottom: 25px;
		}

		.rows-bordered {
			margin: 0;

			> .row {
				padding: 5px 0;
				user-select: none;

				.account-select {
					flex: 0 0 110px;
					text-align: center;
					margin-right: 0;
				}

				&, label {
					.text-normal;
					margin-bottom: 0;
				}

				&.header {
					padding: 0;

					.header-title {
						line-height: 34px;
					}
				}
			}
		}

		.horizontal-flex-group {
			.nowrap {
				white-space: nowrap;
			}

			.full-width {
				overflow: hidden;
				text-overflow: ellipsis;
				.nowrap;
			}

			.page-select {
				margin: 0 15px 0 10px;
			}
		}
		.clickable .horizontal-flex-group {
			cursor: pointer;
		}
	}
}
