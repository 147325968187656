.modal .modal-new .individuals-modal {
	.modal-header {
		border-bottom: none;
	}
	.modal-body {
		padding: 0 15px;
	}
}

.individuals-modal {
	.row > div {
		padding-left: 20px;
	}

	header {
		background: #F4F5F7;
		padding: 8px 0;
		border-top: 2px solid #EEEFF2;
		border-bottom: 2px solid #EEEFF2;

		& ~ div {
			.row {
				padding: 17px 0;
				border-bottom: 2px solid #F2F3F5;
			}

			.row:last-child {
				min-height: 57px;
			}
		}
	}
}
