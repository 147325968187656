.settings {
	.title-space {
		padding: 40px 0 0 0;
	}

	.label-align {
		padding-left: 3px;
	}

	.row-align {
		margin-left: -40px;
	}

	.days-input {
		display: inline-block;
		width: 50px;
	}
}

.quesionnaire-head {
	.btn {
		margin-top: 15px;
	}
}

.questionnaire-questions {
	.invisible {
		opacity: 0;
	}
	.question-edit {
		.counter.alpha .countee {
			padding-left: 15px;
		}
	}

	.counter {
		font-size: 1em;
		font-weight: normal;

		&.alpha .countee {
			padding-left: 45px;

			&:before {
				width: 30px;
				margin-left: -30px;
				float: left;
				text-align: center;
			}
		}
	}

	.pick-list-asnwer {
		padding-top: 1px;
		padding-bottom: 1px;

		.form-control, [w11k-select] {
			vertical-align: middle;
			display: inline-block;
			width: ~"calc(100% - 55px)";
		}

		input[type=checkbox] {
			vertical-align: top;
			margin-top: 6px;
		}

		.tooltip-container {
			margin-left: 5px;
			position: absolute;
			width: 400px;
			height: 0;

			.glyphicon {
				display: inline-block;
				vertical-align: middle;
				font-size: 20px;
				margin-left: 5px;
			}

			.tooltip {
				margin-top: 0;
				margin-left: 10px;
			}

			.tooltip-inner {
				max-width: 350px;
			}
		}

		.remove {
			display: inline-block;
			vertical-align: middle;
			font-size: 20px;
			margin-left: 5px;
		}

		.tooltip {
			margin-top: -5px;
			margin-left: 5px;
		}

		&.countee:before {
			line-height: 28px;
		}
	}

	.form-group {
		margin-bottom: 0;
	}

	.rows-bordered > .question-container {
		margin: 0 -15px;
		border-bottom: 1px solid fade(#000, 15%);

		&.yellow-bg {
			color: @text-color;
		}

		&.no-border {
			border-bottom: none;
		}

		> .row-fluid, > .row {
			width: 100%;
			padding: 20px 0;
			margin: 0;
		}

		> .row-fluid > .col-md-a {
			padding-right: 15px;
		}

		h3 {
			margin: 0;
		}
	}

	.col-row {
		display: table-row;
		margin: 0 -15px;
	}

	.col-md-min, .col-md-max {
		display: table-cell;
		padding: 0 15px;
		vertical-align: middle;
	}

	.col-md-min {
		width: 1px;
		.text-nowrap;
	}

	.col-md-max {}

	.rows-bordered.top-border {
		> .question-container:first-child, > .question-container:first-child {
			border-top: 1px solid fade(#000, 15%);
		}
	}

	hr {
		margin: 10px 0;
		border-top-color: fade(#000, 15%);
	}

	button.link {
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		padding: 0;
		border: none;
		background: none;
		width: auto;
		height: auto;
		color: @link-color;

		&:hover,
		&:focus {
			color: @link-hover-color;
		}

		&:focus {
			.tab-focus();
		}

		.glyphicon {
			margin: 0;
		}
	}

	.add-after {
		.control-value {
			display: inline-block;
		}

		[w11k-select]  {
			width: 263px;
			vertical-align: middle;
			text-align: left;
			float: none;
			display: inline-block;
		}

		.glyphicon {
			margin-left: 10px;
			vertical-align: middle;
			font-size: 24px;
		}

		.tooltip {
			.tooltip-inner {
				max-width: 350px;
			}
		}

		&.in-bank {
			.control-label {
				margin-right: 15px;
			}

			[w11k-select]  {
				width: 160px;
			}

			.inactive {
				opacity: 0;
			}

			.glyphicon {
				margin-right: 30px;
			}
		}
	}

	.answer-list {
		margin-top: 10px;
		padding: 15px 30px;
		background: fade(#000, 5%);
	}



	.inplace-editing {
		background-color: mix(@brand-primary, #fff, 10%);

		.curtain {
			opacity: 0.25;
			&:after {
				background: transparent;
			}
		}
	}

	.order-actions, .edit-actions, .question-number {
		//vertical-align: top;
		display: table-cell;
		cursor: default;
		.user-select(none);

		.glyphicon {
			font-size: 24px;
		}

		a.ng-hide:not(.ng-hide-animate) {
			display: inline-block !important;
			visibility: hidden;
		}
	}

	a.text-muted:hover {
		color: darken(@text-muted, 10%);
	}

	.question-number {
		text-align: right;
		width: 40px;
		vertical-align: top;
	}

	.order-actions {
		width: 40px;

		.glyphicon {
			font-size: 18px;
		}

		a {
			color: #ccc;
		}
	}

	.edit-actions {
		width: 90px;

		.disabled {
			color: #ccc;
		}
	}

	.clients-explanation {
		margin-top: 15px;
		&.text-muted {
			.glyphicon {
				color: inherit;
			}

		}
		.glyphicon {
			font-size: 19px;
			vertical-align: text-bottom;
			color: @brand-green;

		}

		uber-editable {
			display: inline-block;
			font-weight: bold;

		}
	}
	textarea {
		min-height: 80px;
		margin-bottom: 15px;
	}
	.bottom-margin {
		margin-bottom: 15px;
	}
	.questions-list {
		margin-top: 20px;
	}
	.info {
		margin-left: 40px;
	}
}

#questionnaire-graph {
	@active-color: #275fff;
	@default-color: fade(@active-color, 70%);
	@muted-color: fade(@active-color, 40%);
	@unreachable-color: #ff9933;
	@detailsWidth: 400px;

	position: absolute;
	left: 0;
	right: 0;
	top: @header-height + @gray-head-height;
	bottom: @footer-height;
	overflow: hidden;

	&.details-view {
		padding-right: @detailsWidth;

		.details {
			display: block;
		}
	}

	.details {
		position: absolute;
		width: @detailsWidth;
		top: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		z-index: 1;
		display: none;
		padding: 30px;
		background: #f6f6f6;
		box-shadow: inset 1px 0 0 #ddd;
		overflow: auto;

		.close {
			font-size: 40px;
			width: 20px;
			line-height: 20px;
		}

		.number {
			@size: 66px;
			display: inline-block;
			width: @size;
			height: @size;
			border-radius: 50%;
			line-height: @size;
			font-size: 24px;
			text-align: center;
			background: @default-color;
			color: white;

			&.unreachable {
				background: @unreachable-color;
			}
		}

		.question {
			padding: 20px 0;
		}

		.answers {
			h4 {
				font-weight: 600;
			}

			.answer {
				display: table;
				width: 100%;
				padding: 15px 0;
				border-bottom: 1px solid #ddd;
				&:last-child {
					border: none;
				}

				.text, .next {
					display: table-cell;
					vertical-align: middle;
				}

				.text {
					width: 100%;
					padding-right: 20px;
				}

				.next {
					@size: 23px;
					padding-right: 60px;
					white-space: nowrap;

					.c-icon-arrow {
						font-size: 12px;
						line-height: @size;
						color: @muted-color;
					}

					.number {
						float: right;
						margin-right: -60px;
						vertical-align: middle;
						line-height: @size;
						min-width: @size;
						height: @size;
						width: auto;
						font-size: @font-size-small;
						border-radius: ceil((@size / 2));
						padding: 0 6px;
						background: @muted-color;
						margin-left: 15px;
						cursor: default;

						&.skip {
							cursor: pointer;
							background: @default-color;
						}
					}

				}
			}
		}
	}

	.zoom-controls {
		@size: 35px;
		position: absolute;
		top: 50%;
		left: 15px;
		margin-top: -(@size + 15px);


		.zoom-in, .zoom-out {
			margin: 10px 0;
			text-align: center;
			width: @size;
			height: @size;
			line-height: @size - 2;
			font-size: 15px;
			background: #fff;
			border-radius: 50%;
			border: 1px solid #999;
			box-shadow: 0 2px 2px fade(#000, 20%);
			cursor: pointer;

			&:not(.disabled):hover {
				background: #e4e4e4;
			}

			&.disabled {
				opacity: 0.5;
			}
		}
	}

	#viz-containter {
		display: block;
		width: 100%;
		height: 100%;
		cursor: move;
	}

	.node {
		cursor: pointer;

		ellipse {
			stroke: none;
			fill: @default-color;
		}

		text {
			font-family: 'Arial';
			font-weight: 600;
			fill: #fff;
		}

		&#qEnd{
			cursor: inherit;

			ellipse {
				stroke: #CCC;
				stroke-width: 1;
				fill: #f2f2f2;
			}

			text {
				fill: @text-color;
				font-weight: 400;
				font-size: 14px;
			}
		}


		&.hover{
			ellipse {
				stroke: none;
				fill: @active-color;
			}
		}

		&.selected{
			ellipse {
				stroke: @default-color;
				stroke-width: 2;
				fill: white;
			}

			text {
				fill: @default-color;
			}
		}


		&[id^=unreachable]{
			ellipse {
				stroke: none;
				fill: @unreachable-color;
			}

			&.selected{
				ellipse {
					stroke: @unreachable-color;
					stroke-width: 2;
					fill: white;
				}

				text {
					fill: @unreachable-color;
				}
			}
		}
	}

	.edge {
		path {
			stroke: #ccc;
		}

		polygon {
			fill: #ccc;
			stroke: none;
		}

		&.hover{
			path {
				stroke: @text-muted;
			}

			polygon {
				fill: @text-muted;
			}
		}

		&.selected{
			path {
				stroke: @default-color;
			}

			polygon {
				fill: @default-color;
			}
		}




		&#unreachable {
			path {
				stroke: fade(@unreachable-color, 0%);
			}

			polygon {
				fill: fade(@unreachable-color, 0%);
			}
		}
	}
}

.single-questionnaire {
	.flex-head {
		display: table;
		width: 100%;

		.col-fit, .col-min {
			display: table-cell;
			vertical-align: bottom;

			&.middle {
				vertical-align: middle;
			}
		}

		.col-min {
			width: 1%;
		}

		.col-row {
			width: 100%;
			display: table-row;
		}
	}

	.company-info {
		white-space: nowrap;
		.logo {
			display: inline-block;
			width: 65px;
			line-height: 65px;
			img {
				display: inline;
			}
			margin-right: 10px;
		}
		.info {
			white-space: normal;
			display: inline-block;
			text-align: left;
			vertical-align: middle;

			max-width: calc(~"100% - 85px");
		}
		h3.lender {
			font-size: 18px;
			margin: 0;
		}
		small.channel {
			font-size: 14px;
			color: @brand-disabled;
		}
	}
}
