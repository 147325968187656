.issue-add-panel {
	margin-bottom: 5px;
}

.issue-list issue {
	display: block;
	position: relative;
	margin: 0 -20px;
	padding: 15px 20px 20px 52px;
}

.issue-list issue, .issue-edit-modal {

	&.active {
		background: @btn-tertiary-hover-bg;
	}

	.issue-title {
		font-weight: bold;
		margin-bottom: 10px;
		position: relative;

		.issue-number {
			top: 50%;
			transform: translateY(-50%);
			left: -34px;
		}
	}

	.observations {
		padding: 0;
		margin: 0;

		li {
			margin-bottom: 10px;
			display: block;

			.observation-name {
				display: block;
				margin-bottom: 5px;
			}

			&:before {
				@size: 5px;
				content: "";
				display: block;
				position: absolute;
				background: #ccc;
				border-radius: 50%;
				width: @size;
				height: @size;
				margin: 7px 0 0 -26px;
			}

			&.text-muted, &.link {
				&:before {
					display: none;
				}
			}
		}
	}

	.issue-number {
		@size: 21px;
		min-width: @size;
		height: @size;
		border-radius: @size / 2;
		line-height: @size - 2;
		padding: 2px 4px 0;
		background: @brand-danger;
		color: #fff;
		font-size: 12px;
		font-weight: bold;
		position: absolute;
		left: 20px;
		top: 20px;
		text-align: center;
	}

	&.is-editing {
		@borderStyle: 1px solid #E6E7EC;
		background: #F4F5F7;
		padding-left: 20px;
		padding-top: 55px;
		border-top: @borderStyle;
		border-bottom: @borderStyle;

		.issue-number {
			margin-top: -1px;
		}

		& + .is-editing {
			border-top: none;

			.issue-number {
				margin-top: 0;
			}
		}
	}

	.ng-select .ng-dropdown-panel {
		max-width: 100%;

		.ng-dropdown-panel-items .ng-option {
			white-space: normal;
			line-height: 18px;
		}
	}
}

modal-container .modal-dialog.issue-edit-modal {
	position: fixed;
	top: 52px;
	left: 0;
	bottom: 0;
	width: 420px;
	margin: 0;

	.modal-content {
		height: 100%;
		box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.07);
	}

	.modal-body {
		padding: 0;

		.alert {
			margin: 0 -20px;
		}

		.issue-number {
			top: auto;
		}

		.modal-issue-header {
			padding: 15px 0 20px 30px;
		}
	}

	.flex-fixed, .flex-scroll {
		padding: 0 20px;
	}

	.flex-footer {
		padding: 20px 20px;
		border-top: 1px solid #e6e7ec;
	}

	.checkbox-inline {
		padding-top: 5px;
		padding-bottom: 5px;

		input[type="checkbox"] {
			//top: 5px;
		}
	}
}

.vertical-flex {
	display: flex;
	flex-direction: column;
	height: 100%;

	.flex-fixed {
	}

	.flex-grow {
		flex: 1 1;
		overflow-y: hidden;

		.flex-scroll {
			height: 100%;
			overflow-y: auto;
		}
	}
}

.observations-list {
	.list-name {
		padding: 5px 0;
	}
}
