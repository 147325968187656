.social-compliance-publisher-templates {
	.flex-head {
		margin-bottom: 10px;

		h2 {
			margin-bottom: 0;
		}

		.col-min {
			padding-left: 10px;
		}
	}

	.filters {
		.ng-select .ng-value-container .ng-input {
			margin-top: -6px;
		}
	}

	.template-row {
		padding: 10px 0;

		.preview {
			display: flex;
			align-items: center;

			.image-size(@size: 40px) {
				width: @size;
				height: @size;
			}

			.image-holder {
				flex-shrink: 0;
				overflow: hidden;
				border: 1px solid #dbdbdb;
				margin-right: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				i {
					color: #e6e7ec;
					font-size: 28px;
				}

				.image-size;

				img {
					.image-size;
				}

				.post-image {
					.image-size;
					background-size: cover;
				}

				&.folder {
					border: none;
					i.c-icon-folder {
						color: #78B2EC;
						font-size: 36px;
					}
				}
			}

			.attachment-video {
				&:after {
					content: "";
					.image-size(20px);
					background: transparent url("/static/images/video-play-without-border.png") center no-repeat;
					background-size: 18px;
					position: absolute;
					left: 25px;
				}
			}

			.description {
				display: table-cell;
				vertical-align: middle;
				overflow: hidden;
			}

			&.horizontal-flex-group {
				display: flex;

				.description {
					display: block;
				}
			}
		}
	}


}
