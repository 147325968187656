@max-img-size: 170px;
@gray: #e3e3e3;

.publisher {
	.review-details {
		width: 500px;
		height: 500px;
		border-radius: 6px;
		background-color: #fff;
		padding: 30px 20px 20px 20px;
		display: block;
		margin: 0px auto;
		box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.07);

		header {
			display: block;

			.reviewer {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.review-content {
			padding: 45px 0 64px 0;
			height: 395px;

			.image-holder {
				width: @max-img-size;
				height: @max-img-size;
				margin: 0px auto;
				box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.07);
				border-radius: 95px;
				display: flex;
				align-items: center;
				position: relative;
				background-size: cover;

				img {
					width: 150px;
					height: 48px;
					margin: 0px auto;
				}

				.glyphicon {
					position: absolute;
					top: 135px;
					right: 10px;
					border: 1px solid #e8e8e8;
					padding: 6px;
					border-radius: 15px;
					color: #999;
					background: #fff;
					cursor: pointer;
				}
			}

			.score {
				margin: 25px 0 10px 0;

				h3 {
					font-size: 22px;
					line-height: 22px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 0;
				}
			}

			reviews {
				font-size: 20px;
				padding-bottom: 10px;
				display: block;
			}

			.loNmlsId {
				color: #fc7537;
			}
		}

		footer {
			display: block;
			overflow: hidden;

			img {
				height: 26px;
				opacity: 0.5;
			}
		}

		.glyphicon-remove {
			display: none;
			color: #9b9b9b;
		}
	}

	.review-details.editing {
		.review-content {
			padding: 10px 0 64px 0;
		}

		.glyphicon-remove {
			display: inline-block;
		}

		.field {
			border: 1px solid @gray;
			padding: 10px;
			display: inline-block;
		}

		.field-toggle {
			cursor: pointer;
		}

		.disabled {
			border-style: dashed;
		}

		.review-content {
			height: 373px;
		}

		.nmlsId {
			.ng-select.ng-select-single {
				width: 170px;
				display: inline-block;

				.ng-select-container {
					height: 40px;
					padding: 5px;
					border-radius: 0;
					border-style: dashed;
				}
			}

			.ng-dropdown-panel-items {
				text-align: left;
			}
		}

		.score {
			position: relative;
			.ng-select.ng-select-single {
				width: 350px;
				margin: 0px auto;

				.ng-select-container {
					height: 40px;
					padding: 5px;
					border-radius: 0;
					border-style: dashed;
					.ng-value {
						margin: 0px auto;
					}
				}
			}
		}
	}
}


.review-modal {
	width: 600px;

	.review-lender-logos {
		overflow: hidden;
		div {
			float: left;
			width: 170px;
			height: 170px;
			padding: 10px;
			border-radius: 88px;
			cursor: pointer;
			overflow: hidden;
			margin: 0 10px 10px 0;
			background-size: cover;
			box-shadow: inset 0 0 0 2px #dbdbdb;
		}
	}

	.review-lender-logos {
		div.selected {
			border: 3px solid #66a1dd;
		}
	}
}
