.renewal-modal {
	.confirmation {
		display: flex;
		align-items: center;
		margin-top: 20px;
		line-height: 2;

		input {
			margin: 0;
		}
	}

	.input-date {
		margin-top: 10px;
		max-width: 125px;
	}
}