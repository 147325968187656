@brand-border-light: #CCCCCC;
.certifications{
	.certificate{
		margin-bottom: 20px;
	}

	.callout{
		border: 1px solid @brand-border-light;
		border-radius: 5px;
		.callout-body{
			min-height: 105px;
			padding: 12px 15px;
			margin-left: 30px;
			background-color: #fff;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		.certify {
			background-color: @brand-grey-bkg;
		}
		h4 {
			font-size: 18px;
			p {
				margin-top: 5px;
			}
		}
		.form-control {
			width: 25%;
			display: inline-block;
			&.name {
				margin-right: 20px;
			}
		}
		.form-group {
			border-top: 1px solid @brand-border-light;
			margin: 0 -15px;
			padding: 20px 15px 10px;
			color: @text-muted;
		}
		p {
			color: @text-muted;
			line-height: 1.2em;
		}
		.certify-btn {
			margin: 20px 10px;

			.request-history {
				padding-top: 10px;
			}
		}
	}

	.callout{
		h4 {
			color: #000;
			span {
				color: @text-muted;
				font-size: 14px;
				vertical-align: baseline;

				a {
					cursor: pointer;
				}

				&.when {
					font-size: 12px;
				}
			}

			.text-small {
				font-size: 12px;
			}
			.text-danger {
				color: @brand-red;
			};
		}
	}
	.callout-danger{
		.glyphicon.glyphicon-ok-sign{
			display: none;
		}
		background-color: @brand-red;
	}
	.callout-success{
		.glyphicon.glyphicon-ok-sign{
			background-color: #fff;
			color: @brand-green;
			font-size: 26px;
			border-radius: 15px;
			width: 15px;
			height: 15px;
			float: left;
			margin-top: 43px;
			margin-left: 7px;
			&:before{
				position: relative;
				left: -5px;
				top: -5px;
			}
		}


		background-color: @brand-green;
	}

	.requested-label{
		min-width: 150px;
		display: inline-block;
	 	color: @brand-red !important;
	 	font-size: 12px !important;
		border: 1px solid @brand-red;
		font-weight: normal;
		position: relative;
		cursor: pointer;

		&.clickable:hover{
			&::before{
				color: #fff !important;
				background-color: @brand-red;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				line-height: 20px;
				content: "Show Lenders";
				display: block;
				text-align: center;
				border-radius: 1px;
			}
		}
	}
}
