.w11k-select {
	> .form-control {

		padding-right: 25px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		min-width: 100% !important;

		.caret {
			position: absolute;
			top: (@input-height-base / 2) - 2;
			right: 10px;
		}
	}

	.dropdown-menu {
		min-width: 100%;

		.header {
			padding: 3px 8px;

			.clear {
				color: #333;
				position: absolute;
				top: 8px;
				right: 8px;
				text-decoration: none;
				text-align: center;
				line-height: @input-height-base;
				width: @input-height-base;
				height: @input-height-base;
			}

			.controls {
				margin-top: 3px;
			}

			.form-control {
				color: @input-color;
				border-color: @input-border;
				.form-control-focus();
				min-width: 100%;
			}
		}

		.divider {
			margin: 3px 0;
		}

		.content {
			max-height: 26*10px !important;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.items {
			margin: 0;

			> li {
				padding: 3px 16px 3px 16px;
				white-space: nowrap;
				overflow: hidden;
				cursor: default;

				&.divider {
					padding: 0 !important;
				}
			}

			> .item {
				padding-right: 52px;
				color: @gray-light;
				background-color: @dropdown-bg;
				position: relative;
				cursor: pointer;
				white-space: nowrap;
				word-wrap: normal;

				&.selected {
					color: #000;

					&:after {
						.glyphicon;
						position: absolute;
						// icon height is 14px
						top: calc(~"50% - 7px");
						right: 10px;
						content: "\e013";
					}
				}

				&:hover,
				&:focus {
					background-color: @dropdown-link-hover-bg;
				}
			}
		}

		&.single {
			.items > .item {
				color: @gray-light;
				background-color: @dropdown-bg;
				padding-right: 30px;

				&:hover,
				&:focus {
					background-color: @dropdown-link-hover-bg;
				}

				&.selected {
					color: #fff;
					background-color: @brand-primary;

					&:after {
						display: none;
					}
				}
			}
		}
	}

	searchable-entity & {
		.remote-resource:after {
			background-color: fade(#fff, 75%);
		}
		&.dropdown {
			.item() {
				display: flex;
				.text {
					flex: 1 1 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.label {
					flex: 0 0 auto;
					margin-top: 2px;
					margin-left: 5px;
				}
			}
			.has-feedback {
				.form-control {
					padding-left: 28px;
					&.selection {
						.item();
						cursor: pointer;
						user-select: none;
					}
				}
				.form-control-feedback {
					.icon() {
						position: absolute;
						top: 0;
						line-height: @input-height-base;
						color: @text-color;
					}
					&.field-icon {
						.icon();
						right: auto;
						left: 0;
					}
					&.field-control {
						.icon();
						text-decoration: none;
						&:hover {
							color: #000;
						}
					}
				}
			}
			.dropdown-menu {
				&.single {
					ul.items {
						li {
							padding: 3px 10px;
							&.item {
								.item();
								color: inherit;
								.text-light {
									color: inherit;
									opacity: 0.8;
								}
								&.selected {
									color: #fff;
									.label {
										.label-light(#fff);
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
