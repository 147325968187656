review-comments {
	display: block;
	padding: 0 20px 10px;
	background-color: #f4f5f7;

	.comment {
		padding: 15px 0 5px 0;

		&:not(:last-child) {
			border-bottom: 1px solid #E4E6EA;
		}

		.inline-menu {
			position: relative;

			ul.dropdown-menu {
				min-width: 110px;
			}
		}

		.show-more {
			padding: 5px 0;
		}

		review-reply {
			padding-bottom: 5px;

			.btn-tertiary {
				margin-left: -6px;
			}

			form:not(.editing) {
				margin-top: 15px;
				border-top: 1px solid #E4E6EA;
				padding-top: 15px;
				padding-left: 20px;
			}
		}
	}

	.toggle {
		display: block;
		padding-bottom: 10px;
	}

	review-comments {
		padding-right: 0;
		padding-bottom: 5px;

		&:not(:last-child) .comment:last-child {
			border-bottom: 1px solid #E4E6EA;
		}

		&:last-child {
			padding-bottom: 0;
		}

		.comment review-reply form:not(.editing) {
			padding-left: 0;
		}
	}
}
