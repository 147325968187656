.edit-values-modal {
	label {
		display: flex;
		align-items: center;
		font-weight: normal;
		cursor: pointer;

		input {
			vertical-align: middle;
			margin: 0 5px 0 0;
		}
	}
}