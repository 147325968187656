.show-regulations-icon {
	color: #9b9b9b;
	cursor: pointer;
	position: relative;
	top: 3px;

	.issues-list select-checkboxes > div .glyphicon& {
		position: absolute;
		left: 0;
		top: 3px;
	}

	.issues-list checklist > div .glyphicon& {
		margin-left: -50px;
		margin-top: 3px;
	}
}
