.wizard {
	a {
		cursor: pointer;
	}

	h1 {
		font-size: 28px;
		text-align: center;
		margin-bottom: 40px;
	}

    .contact-new-header {
        text-align: center;
    }

    section {
		margin-top: 40px;
	}

	.well {
		padding: 15px 30px;
		background: fade(#ebc300, 10%);
		color: #BFA892;

		p:last-child {
			margin-bottom: 0;
		}
	}

	.affiliate-group{
		&.form-group{
			background-color: #fff;
			margin-bottom: 0;
		}
		background-color: #eee;
		ul {
			margin-top: 10px;
			padding-left: 0;
			list-style-type: none;
		}
		&:before{
			padding-left: 10px;
			content:"1.";
			width: 30px;
			display: block;
			font-weight: bold;
		}
	}
	.form-group.affiliate-group:before{
		padding-top: 4px;
		font-weight: normal;
		color: @text-muted;
	}


	.affiliate-group + .affiliate-group:before{ content:"2."; }
	.affiliate-group + .affiliate-group + .affiliate-group:before{ content:"3."; }
	.affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group:before{ content:"4."; }
	.affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group:before{ content:"5."; }
	.affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group + .affiliate-group:before{ content:"6."; }

	.affiliate-group > div:first-child{
		padding-left: 30px;
	}

}
