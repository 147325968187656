realm-image-cropper {
    --aspect: 1;
    position: relative;
    z-index: 0;

    .image-wrapper {
        display: flex;
        position: relative;
        border: 1px solid #ccc;
        box-sizing: content-box;
        //height: @height;
        aspect-ratio: var(--aspect);
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .crop-zone {
            @border: 1px;
            position: absolute;
            z-index: 2;
            border: @border dashed @brand-primary;
            cursor: grab;

            &:active {
                cursor: grabbing;
            }

            .size-drag {
                @size: 14px;
                width: @size;
                height: @size;
                right: -@border;
                bottom: -@border;
                cursor: nwse-resize;

                position: absolute;
                border: 5px solid @brand-primary;
                border-left: 0;
                border-top: 0;
            }
        }
    }
}
