.attachments-preview {
	position: relative;
	display: block;
	width: 100%;
	max-width: 600px;
	overflow: hidden;

	.attachments {
		&-container {
			display: grid;

			&.masonry {}

			&.carousel {}
		}

		&-item {
			position: relative;

			img {
				width: 100%;
				object-fit: cover;
			}

			.excess {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
				font-size: 3.5rem;
				background: rgba(0, 0, 0, .4);
			}

			&.original {
				img {
					object-fit: contain;
				}
			}
		}
	}

	.carousel-scale {
		position: absolute;
		left: 10px;
		bottom: 25px;
		display: inline-block;
		width: 33px;
		height: 33px;
		padding: 0;
		line-height: 1;
		background: none;
		border: none;
		user-select: none;
		cursor: pointer;

		circle {
			fill: #545454;
			opacity: 0.74;

			&:hover {
				fill: #2b2b2b;
			}
		}

		polyline {
			fill: none;
			stroke: #fff;
			stroke-width: 20px;
		}
	}

	.carousel-navigation {
		ul {
			margin: 0;
			padding: 5px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.25s;

			li {
				margin: 0 0.25rem;
				padding: 0;
				display: inline-block;
				width: .75rem;
				height: .75rem;
				background-color: #a8a8a8;
				border-radius: 50%;
				cursor: pointer;

				&.active {
					transform: scale(1.3);
					transform-origin: center;
					background: #3498db;
				}
			}
		}
	}
}
