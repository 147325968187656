.li-pages-modal {
	.modal-body {
		p {
			margin-bottom: 25px;
		}

		.rows-bordered {
			margin-right: 0;
			margin-left: 0;

			.row {
				label {
					margin-bottom: 0;
				}
			}
		}
	}
}
