.entity-fields, .entity-fields-details {
	.arrows {
		.c-icon {
			position: relative;
			top: 1px;

			&.icon-reverse {
				transform: rotate(180deg);
			}
		}
	}

	.btn-ml2 + .btn-ml2 {
		margin-left: 2px;
	}

	.custom-field-values {
		padding: 0;
		border-bottom: 0;

		.row {
			margin: 0;
			padding: 8px 0;
			border-bottom: 1px solid @border-grey;

			&.form-group {
				margin-top: 0;
			}
		}

		.name-holder {
			padding-left: 0;
		}
	}

	.section-name {
		margin: 20px 0;
	}
}

.entity-fields {
	.group-form {
		background: #F3F9FE;
		padding: 10px 15px;
		margin-bottom: 10px;

		.group-name {
			width: 300px;
		}
	}

	.grouped-fields {
		&:first-of-type {
			margin-top: -10px;
		}
		.group {
			background: #f4f5f7;
			padding: 10px 0;
			min-height: 50px;
			.glyphicon {
				cursor: pointer;
			}
		}
	}

	.fields-drag-placeholder {
		background: #ccc;
		border: dotted 3px #999;
		min-height: 50px;
		transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	}

	.border-placeholder {
		border-bottom: #E7E8ED 2px solid;
		& + .border-placeholder {
			border-bottom: none;
		}
	}

	.field-element.cdk-drag:not(.cdk-drag-disabled) {
		cursor: move;
	}

	.col {
		padding: 10px 0;
	}
}

.field-element.cdk-drag-preview {
	&:extend(.group-fields .field-element);
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
	0 8px 10px 1px rgba(0, 0, 0, 0.14),
	0 3px 14px 2px rgba(0, 0, 0, 0.12);
	cursor: move;
}
