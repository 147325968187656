.team-access {
	.account-holder {
		margin-bottom: 30px;
	}

	.has-feedback.short {
		width: 300px;
	}

	.header-form {
		.control-label {
			padding-left: 3px;
			padding-right: 0;
		}

		.select-holder {
			padding-right: 0;
		}

		.button-holder {
			padding-top: 25px;
		}
	}

	.published-list {
		.active-row {
			background: #f3f9ff;
		}

		.label {
			min-width: 80px;
			font-size: 13px;
			font-weight: normal;
		}

		.row-fluid > div {
			height: @input-height-base;
		}
	}
}
