.additional-fields {
	h3 {
		position: relative;
		margin: 20px 0;
		z-index: 1;
	}

	.actions {
		position: absolute;
		width: 100%;
		margin-top: 0;

		button, a {
			position: relative;
			z-index: 2;
		}
	}
}
