.contact-menu-header {
    gap: 10px;
    padding-left: 20px;
    height: 80px;

    .contact-icon {
        width: 36px;
    }

    .ellipsis {
        overflow: hidden;
    }

    .text-muted {
        color: #BCD8F5;
    }

    a {
        color: inherit;
    }
}

.new-contacts {
    .flex-row {
        h2, h3 {
            margin: 0;
        }
    }

    section {
        margin-top: 10px;
    }
}
