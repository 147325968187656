.document-card(
	@min-height: 110px;
	@front-face-color: inherit;
	@front-face-background-color: #fff;
	@front-face-border-color: #d6d9dd;
	@back-face-color: #dd534e;
	@back-face-background-color: #fdf3f3;
	@back-face-border-color: #f6e2e1;
) {
	min-height: @min-height;
	position: relative;
	perspective: 5000px;

	.front-face,
	.back-face {
		border-width: 1px;
		border-style: solid;
		border-radius: 5px;
		backface-visibility: hidden;
		transform-origin: 50%;
		transition: transform 0.3s, background 0.3s;
	}

	.front-face {
		min-height: @min-height;
		padding: 15px 15px 15px 25px;
		color: @front-face-color;
		background-color: @front-face-background-color;
		border-color: @front-face-border-color;
	}

	.back-face {
		position: absolute;
		top: -1px;
		right: -1px;
		bottom: -1px;
		left: -1px;
		color: @back-face-color;
		background-color: @back-face-background-color;
		border-color: @back-face-border-color;
		transform: rotateX(-180deg);
	}

	&.drop-target {
		.front-face {
			border-style: dashed;
		}

		&.hover {
			.front-face {
				border-style: solid;
				border-color: @brand-primary;
			}
		}
	}

	&.error {
		.front-face {
			transform: rotateX(180deg);
		}

		.back-face {
			transform: rotateX(0deg);
		}
	}
}

.file-input() {
	display: block;
	margin: 0;

	input[type=file] {
		.hidden;
	}
}
