.investor-internal-document {
	&.document-card {
		.front-face {
			.indications {
				.updated-by {
					margin-top: 3px;
					.text-muted;
					.text-smaller;
				}
			}
		}
	}
}
