@import './common/request-document-history-modal/request-document-history-modal.component.less';
@import './common/document-modal.component.less';
@import './list/item/item.component.less';
@import './document/details/details.component.less';
@import './library/list/item/item.component.less';
@import './internal-documents/list/item/item.component.less';
@import './internal-documents/document/details/details.component.less';

.confirm-file-upload-modal .modal-content .modal-body {
	.modal-message {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		line-height: 1.5;

		.bigger {
			font-size: 19px;
		}

		.well:not(.framed) {
			width: 100%;
			background-color: #f4f5f7;
			color: @text-color;
			border: none !important;
			box-sizing: content-box;
			margin-bottom: 15px;

			&.bigger {
				font-size: 19px;
			}

			&.left {
				text-align: left;
			}
		}

		& > * {
			max-width: 100%;
		}

		strong {
			display: block;
		}

		.glyphicon {
			&.glyphicon-arrow-down {
				margin-bottom: 15px;
				color: #d8d8d8;
				font-size: 32px;
			}
		}

		.document-card {
			.bigger;
			width: 100%;
			padding: 20px 10px;
			border: 1px solid #d6d9dd;
			border-radius: 5px;
		}
	}
}

.modal {
	.modal-discontinue {
		.modal-header {
			color: #fff;
			background-color: #000;
			border-bottom: none;
			text-align: center;

			h4,
			.close {
				font-size: 18px;
				line-height: 18px;
			}
		}

		.modal-body,
		.modal-footer {
			background-color: #ffff00;
		}

		.modal-body {
			color: #000;
			text-align: center;

			.skull {
				margin: 5px auto 20px;
				width: 90px;
				height: 90px;
				background: url("/static/images/scull.svg") no-repeat center center;
			}

			.text-warning {
				color: #dd5350;
			}
		}

		.modal-footer {
			&,
			* {
				text-align: center;
			}

			.btn {
				&.btn-primary {
					background-color: #dd534f;
					border-color: #dd534f;
				}

				&.btn-default {
					color: #000;
					background-color: transparent;
					border-color: #000;
				}
			}
		}
	}
}
