production-total-volume {
  display: block;

  .data {
    padding: 10px;
    background: #f4f5f7;
    border: 1px solid #E6E7EC;

    .text-big {
      font-size: 21px;
      line-height: 23px;
    }
  }
}
