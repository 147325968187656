@import "@{src}shared/global-search/individulals/individulas.less";
.search {
  .details {
    padding-right: 0;
  }
  .icon {
    padding: 0
  }
  .client {
    .name{
      padding: 0;
    }
    .logo {
      text-align: right;
      padding: 8px 10px 0 0;
      .logo-image{
        width: 21px;
        height: 21px;
        background: url("/static/images/optimalblue/ob-logo.png") no-repeat;
      }
    }
  }
  a.form-control-feedback.glyphicon{
    position: absolute;
  }
}
.input-lg ~ .form-control-feedback {
  width: 34px;
  height: 34px;
  line-height: 34px;
}
.navbar {
  input[type="search"]{
    color: @main-menu-color;
    border-color: @brand-nav-panel-search-border;
    background-color: #fff;
    &:focus {
      background-color: #fff;
      border-color: darken(@brand-nav-panel-search-border, 10%);
      color: @brand-dark-grey;
    }
  }
}
