.required-documents-partners-holder {
	min-height: 120px;

	&.resolved {
		min-height: 0;
	}
}

.required-documents-partners {
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 15px;

	.row-fluid {
		display: flex;
	}

	.title-holder {
		padding: 20px 15px;
		border-right: 1px solid #ccc;
		display: flex;
		align-items: center;
	}

	h3 {
		margin: 0;
		padding: 0;
	}

	.links-column {
		display: flex;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.links-holder {
		padding-top: 5px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.link {
		width: 50%;
		padding: 0 15px 5px 0;
	}
}
