.label-wireframe (@color) {
	@highColor: hsv(hsvhue(@color), hsvsaturation(@color), 99%);
	background-color: transparent;
	color: @color;
	border-color: @color;
	border-width: 1px;
	border-style: solid;
}

.label-light (@color) {
	background-color: fade(@color, 20%);
	color: @color;
}

.stats-bar {
	display: flex;
	margin-bottom: 10px;
	height: 40px;
	background-color: transparent;
	border-radius: 0;
	box-shadow: none;
	overflow: visible;

	.item {
		flex-shrink: 0;
		flex-basis: 0;
		position: relative;
		height: 40px;
		font-size: 1em;
		text-align: center;
		box-shadow: none;
		transition: none;
		color: #fff;
		&:not(:last-child) {
			//margin-right: 1px;
		}
		.item-percent {
			padding: 0 3px;
			line-height: 40px;
			font-size: 1.1em;
			font-weight: 600;
			text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
			position: relative;
			z-index: 1;
			white-space: nowrap;
		}

		&.empty {
			background-color: #eeeeee;
			.item-percent {
				color: @text-muted;
				text-shadow: none;
			}
		}
	}
}

[stats-bar] .dot-chart-legend {
	padding-left: 0;
}
