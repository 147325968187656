/* Pill style */
#scrollUp {
	bottom: 50px;
	right: 30px;
	background: #555;
	text-decoration: none;
	color: #fff;
	opacity: .9;
	padding: 11px 15px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	-webkit-transition: background 200ms linear;
	-moz-transition: background 200ms linear;
	transition: background 200ms linear;
	-webkit-backface-visibility: hidden;
	&:after {
		font-family: "Glyphicons Halflings";
		content: "\e113";
	}
}
#scrollUp:hover {
	background: #000;
}
