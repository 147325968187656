review-reply {
	display: block;

	.remote-resource:not(.resolved) {
		background-color: #dcd9d9;
	}

	.c-icon-message {
		position: relative;
		top: 2px;
	}
}
