post {
	twitter-post {
		&.post-content {
			.post-text, publisher-attachments-preview {
				padding: 10px 15px 0 0;
			}

			.attachments {
				padding: 0
			}

			publisher-attachments-preview {
				display: flex;
				justify-content: center;
			}
			.link-preview {
				overflow: hidden;
				margin-top: 10px;
				border-radius: 15px;
				border: 1px solid rgb(204, 214, 221);

				.title {
					font-weight: normal;
				}
				.link-section {
					display: flex;
					height: 100px;

					img {
						display: block;
						width: 100px;
						object-position: center;
						object-fit: cover;
						border-right: 1px solid #ccd6dd;
					}
				}
				.text-holder {
					overflow: hidden;

					.title {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.twitter-link {
						display: flex;
						color: #808080;
					}
					.twitter-link:before {
						padding-right: 1px;
						font-size: 15px;
					}
					.description {
						height: calc(17px * 2);
						overflow: hidden;
						line-height: 17px;
						text-align: justify;

						display: -webkit-box;
						/* autoprefixer: ignore next */
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						display: block;
						display: -webkit-box;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}
