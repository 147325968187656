//@Notice: copy from src/main/webapp/styles/social-media.less, please remove after integration
.social-media-new {
	@dark-blue: #5474B7;
	@nmls-blue: #313C9D;
	@deep-blue: #0076B7;
	@sky-blue: #55ACEE;
	@red: #D44444;
	@light-red: #F83F2E;
	@red-danger: #dd534f;
	@light-blue: #66A1DD;

	.pseudo-row {
		.pseudo-cell {
			&:first-child {
				padding-left: 0;
			}
		}
	}

	.c-icon {
		font-size: 40px;

		&.c-icon-phone {
			top: 5px;
			font-size: 1em;
		}

		&.c-icon-normal {
			font-size: 1em;
		}

		&.small-icon {
			font-size: 13px;
		}
	}

	.pseudo-cell {
		vertical-align: middle;

		a.url-holder {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			word-wrap: normal;
			max-width: 535px;
			display: block
		}

		.form-control-feedback {
			position: absolute;
			top: 0px;
			line-height: @input-height-base;
			color: @text-color;
			text-decoration: none;
		}

	}

	.pending-removal {
		width: 132px;
	}

	.keep-remove {
		white-space: nowrap;
		width: 193px;
	}

	.has-error .title-holder {
		color: #dd534f;
	}

	.button-offset {
		margin-left: 148px;
	}

	.title-holder {
		width: 130px;
	}

	.icon-holder {
		width: 24px;
		position: relative;

		.profile {
			position: relative;
			z-index: 1;
		}
	}

	.sm-link-wrapper {
		.control-value {
			padding-bottom: 0px;
			line-height: 17px;
		}
	}

	.cell-remove {
		width: 75px;
	}

	.pseudo-tabel.fixed .icon-holder {
		width: 56px;
	}

	.cell-space {
		padding: 0 10px;
	}

	.sm-links-control {
		padding-bottom: 20px;
		.text-medium {
			line-height: 33px;
		}
	}

	.status-holder {
		width: 125px;
		position: relative;
		padding-right: 30px;

		.c-icon {
			position: absolute;
			right: 0;
		}

		&.small {
			.c-icon {
				font-size: 18px;
				right: 5px;
				top: 5px;
			}
		}
	}

	.rss-status {
		width: 100px;
	}
	.btn-monitoring {
		margin: -5px 0;
	}

	&.digital-media {

		.status-holder {
			position: absolute;
			padding-right: 0;
			min-height: 26px;
			width: 30px;
			right: 10px;
			top: 0;

			&.small {
				.c-icon {
					font-size: 18px;
					left: 4px;
					top: 5px;
				}
			}
		}

		.remove-holder {
			position: relative;

			.status-holder {
				left: 0;
			}
		}

		.pseudo-row {
			uber-editable {
				margin: -1px 0;
			}
		}

		.space-right {
			margin-right: 15px;
		}

	}

	.reloading {
		&:before {
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			background: url('/static/images/loader-circular-primary.gif') center center no-repeat;
			background-size: 75%;
		}

		* {
			display: none;
		}
	}

	.highlight {
		background: #e0ecf8;
	}

	//Icons
	.icon-dark-blue {
		color: @dark-blue;
	}

	.icon-deep-blue {
		color: @deep-blue;
	}

	.icon-nmls-blue {
		color: @nmls-blue;
	}

	.icon-sky-blue {
		color: @sky-blue;
	}

	.icon-red {
		color: @red;
	}

	.icon-light-red {
		color: @light-red;
	}

	.icon-light-blue {
		color: @light-blue;
	}

	.light-gray {
		i {
			color: lightgray;
		}
	}

	.light-green {
		color: #73c572;
	}

	.red-danger {
		color: @red-danger;
	}

	//end:Icons

	//Buttons
	.default-sm-button(@color) {
		background: @color;
		border-color: @color;
		color: #fff;
		.c-icon {
			font-size: 13px;
		}
	}

	.btn-dark-blue {
		.default-sm-button(@dark-blue);
	}

	.btn-sky-blue {
		.default-sm-button(@sky-blue);
	}

	.btn-gmb-blue {
		.default-sm-button(#518EF8);
	}

	.btn-deep-blue {
		.default-sm-button(@deep-blue);
	}

	.btn-red {
		.default-sm-button(@red);
	}

	.btn-light-red {
		.default-sm-button(@light-red);
	}

	.instagram-gradient {
		color: #fff;
		.c-icon {
			font-size: 13px;
		}
		border: none;
		background: rgb(207,38,151);
		background: -moz-linear-gradient(90deg, rgba(207,38,151,1) 0%, rgba(253,154,51,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(207,38,151,1) 0%, rgba(253,154,51,1) 100%);
		background: linear-gradient(90deg, rgba(207,38,151,1) 0%, rgba(253,154,51,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cf2697",endColorstr="#fd9a33",GradientType=1);
	}
	//end:Buttons

	//@Notice RM-11006 (Ms edge fix)
	.row-fluid {
		display: inline-table;
	}

	.medium-icon-holder {
		line-height: 24px;

		.glyphicon.va-m {
			top: 0;
		}
	}

	/**
	 * Social Media svg icons
	 */
	.icon {
		display: inline-block;
		width: 26px;
		height: 26px;
		vertical-align: middle;
	}
	.icon-time {
		background: url('/static/images/time.svg') no-repeat;
	}
	.icon-user-check {
		background: url('/static/images/user-check.svg') no-repeat;
	}
	.icon-user-plus {
		background: url('/static/images/user-plus.svg') no-repeat;
	}

	.btn-fixed-w {
		width: 126px;
		padding-right: 0;
		padding-left: 0;
	}

	.btn-fixed-h {
		height: 34px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.btn-fixed {
		.btn-fixed-w;
		.btn-fixed-h;
	}

	.controls-section {
		white-space: nowrap; // prevent new line
		.va-m;

		.section-control {
			display: inline-block;
		}

		.section-control + .section-control {
			margin-left: 10px
		}

		.team-access {
			display: inline-block;
			width: 126px;
			.text-center;
		}

		.inline-menu {
			.display-inline;
			width: 20px;
			.va-m;

			.c-icon {
				font-size: 20px;
				cursor: pointer;
			}
			.dropdown-menu {
				right: 0;
				left: auto!important;
				min-width: 100px;
			}
		}
	}

	/**
	 * Team Access modals
	 */
	&.request-publisher-access-modal,
	&.cancel-publisher-access-modal,
	&.accept-publisher-access-modal,
	&.decline-publisher-access-modal,
	&.remove-current-publisher-access-modal{
		.modal-dialog {
			width: 550px;
			.modal-body {
				.url-holder {
					margin-left: 10px;
				}
			}
		}
	}

	&.publisher-requests {
		sm-publisher-request {
			display: block;
			padding: 15px;
			background-color: #e0ecf8;
			.clearfix;

			&:not(:last-child) {
				margin-bottom: 15px;
			}

			.description {
				display: inline-block;
				margin-left: 10px;
				.va-m;
			}
		}
	}

	&.publishers {

		.url-holder {
			margin-left: 10px;
		}

		.add-publisher {
			padding: 15px;
			background-color: rgba(0, 0, 0, 0.03);

			p {
				margin-bottom: 15px;
			}
		}

		.revoke {
			color: #66a1dd;
		}

		section {
			margin-top: 0;
			margin-bottom: 15px;
		}
	}
}

@import "connect-account-modal/connect-account-modal.component.less";
