.from-bank {
	.place-select {
		margin: 0 10px;
	}

	.glyphicon-eye-open {
		margin-right: 20px;
	}

	.question-container:last-child {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}
}
