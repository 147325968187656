tag-input {
	color: #333;
	border: 1px solid #D6D9DD;
	border-radius: 4px;

	.ng2-tag-input {
		padding: 3px !important;
		min-height: 28px !important;
		border-bottom: none !important;

		.ng2-tags-container {
			tag {
				margin: 0 3px 3px 0;
				padding: 0;
				width: unset;
				height: unset;
				line-height: unset;
				font-family: inherit;
				background-color: #F4F5F7;
				border-radius: 2px;
				cursor: auto;

				.tag-wrapper {
					@size: 18px;

					flex-direction: row-reverse;
					line-height: @size;

					.tag__text {
						padding: 0 3px;
						height: @size;
						font-size: 12px;
					}

					delete-icon {
						display: block;
						width: unset;
						height: unset;
						border-right: 1px solid fade(@text-muted, 20%);
						cursor: pointer;

						&::before {
							content: '×';
							display: block;
							text-align: center;
							width: @size;
							height: @size;
							font-size: 18px;
							color: fade(@text-muted, 90%);
						}

						& > * {
							display: none;
						}

						&:hover {
							background-color: #DEE0E7;
							transform: none;
						}
					}
				}

				&:hover,
				&:focus {
					color: initial !important;
					background-color: #F4F5F7 !important;
					box-shadow: none !important;
				}
			}

			tag-input-form {
				input {
					height: unset;
				}
			}
		}

		.progress-bar {
			display: none;
		}
	}

	&[disable] {
		border: none;

		.ng2-tag-input {
			opacity: 1 !important;

			tag-input-form {
				display: none;
			}
		}
	}
}
