// NOTICE: lot's of useless rules added to make autoprefixer create proper layout for IE11
// if you change something, please, refer to articles and check after in IE11
// https://medium.com/@elad/supporting-css-grid-in-internet-explorer-b38669e75d66
// https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/
.calendar {
	@min-height: 800px;
	@min-width: 1180px;

	@maxPostsInSelectedMode: 12;
	@postMargin: 4px;
	@postHeight: 24px;
	@postOverlayOverflow: -5px;

	@campaignsMonthsAmount: 18;

	@calendar-border-color: #f4f4f4;

	@headerHeight: 52px;

	@editHolderWidth: 400px;

	min-height: @min-height;
	min-width: @min-width;

	&.calendar-campaigns {
		min-height: 0;

		.main-wrapper, .main-wrapper > .well {
			min-height: 0;
		}
	}

	.create-grid-row(@selector, @cols, @row: 1, @i: 1) when (@i =< @cols) {
		@childNumber: (@row - 1) * @cols + @i;
		.@{selector}:nth-child(@{childNumber}) {
			grid-column: @i;
			grid-row: @row;
		}

		.create-grid-row(@selector, @cols, @row, @i + 1);
	}

	.create-grid-layout(@selector, @rows, @cols, @i: 1) when (@i =< @rows) {
		.create-grid-layout(@selector, @rows, @cols, @i + 1);
		.create-grid-row(@selector, @cols, @i);
	}

	.flex-row {
		display: flex;
		align-items: center;
		.flex-grow {
			flex: 1 0;
		}
	}

	loader {
		top: -2px;
	}

	.main-wrapper, .main-wrapper > .well {
		min-height: @min-height;
		min-width: @min-width;
	}

	.month-mode {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-areas:
			"headerMonth headerMonth"
			"postEdit dayNames"
			"postEdit days";
		grid-template-rows: @headerHeight 47px 1fr;
		grid-template-columns: 0 1fr;

		&.post-edit-opened {
			grid-template-columns: @editHolderWidth 1fr;
		}

		// IE11 fix, cannot use single name for header area for same selector
		.header {
			grid-area: headerMonth;
			grid-template: "dataSelectors modeSwitchers closeHolder";
			grid-template-columns: 1fr 220px 1fr;
		}

		.data-selectors {
			grid-area: dataSelectors;
		}

		.mode-switchers {
			grid-area: modeSwitchers;
		}

		.close-holder {
			grid-area: closeHolder;
		}
	}

	.year-mode {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-areas:
			"headerYear headerYear"
			"campaignEdit monthNames"
			"campaignEdit months";
		grid-template-rows: @headerHeight 74px 1fr;
		grid-template-columns: 0 1fr;

		&.campaign-edit-opened {
			grid-template-columns: @editHolderWidth 1fr;
		}

		// IE11 fix, cannot use single name for header area for same selector
		.header {
			grid-area: headerYear;
			grid-template: "dataSelectors modeSwitchers closeHolder";
			grid-template-columns: 1fr 220px 1fr;
		}

		.data-selectors {
			grid-area: dataSelectors;
		}

		.mode-switchers {
			grid-area: modeSwitchers;
		}

		.close-holder {
			grid-area: closeHolder;
		}
	}

	.header {
		display: grid;
		grid-template-rows: 1fr;
		padding: 0 20px;
		// align-items: center;
		width: 100%;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
		background: #fff;

		// IE11 support
		> * { align-self: center }
	}

	.data-selectors {
		display: grid;
		grid-gap: 15px;
		grid-template: "todayLink monthsSelectors customMonthSelector loaderHolder" 1fr /
						auto auto auto 1fr;
		// justify-items: stretch;
		// align-items: center;

		// IE11 support
		.today-link, .months-selectors, .custom-month-selector, .loader-holder {
			align-self: center;
			justify-self: stretch;
		}

		.today-link {
			grid-area: todayLink;
		}

		.months-selectors {
			grid-area: monthsSelectors;
		}

		.custom-month-selector {
			grid-area: customMonthSelector;
		}

		.loader-holder {
			grid-area: loaderHolder;
		}
	}

	.today-link {
		line-height: 20px;

		&:hover {
			text-decoration: none;
		}
	}

	.months-selectors {
		display: grid;
		grid-gap: 5px;
		grid-template: "prevMonthSelector nextMonthSelector" 1fr /
						auto auto;

		.prev-month-selector {
			grid-area: prevMonthSelector;
		}

		.next-month-selector {
			grid-area: nextMonthSelector;
		}
	}

	.mode-switchers {
		justify-self: center;

		.btn {
			min-width: 100px;
		}
	}

	.close-holder {
		justify-self: end;
	}

	.close-link {
		display: inline-block;

		.c-icon {
			font-size: 0.8em;
			top: -1px;
			margin-left: 10px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.prev-month-selector, .next-month-selector {
		color: @main-menu-color;
		cursor: pointer;
		position: relative;
		top: -3px;
		line-height: 20px;
		text-align: center;

		&:hover {
			opacity: 0.3;
		}
	}

	.custom-month-selector {
		color: @text-color;
		font-weight: bold;
		line-height: 20px;
	}

	.day-names {
		grid-area: dayNames;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.13);
		// justify-items: stretch;
		// align-items: center;
		font-size: 14px;
		text-align: center;
		color: @text-color;

		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-template-rows: 1fr;
		.create-grid-layout(day-name, 1, 7);

		.day-name {
			align-self: center;
			justify-self: stretch;
		}
	}

	.days {
		grid-area: days;
		border: 1px solid #e3e3e3;
		border-right-width: 0px;
		border-bottom-width: 0px;
		overflow-y: hidden;
	}

	.weeks-4 {
		display: grid;
		grid-template-rows: repeat(4, 1fr);
		grid-template-columns: repeat(7, 1fr);
		.create-grid-layout(day, 4, 7);
	}

	.weeks-5 {
		display: grid;
		grid-template-rows: repeat(5, 1fr);
		grid-template-columns: repeat(7, 1fr);
		.create-grid-layout(day, 5, 7);
	}

	.weeks-6 {
		display: grid;
		grid-template-rows: repeat(6, 1fr);
		grid-template-columns: repeat(7, 1fr);
		.create-grid-layout(day, 6, 7);
	}

	.day {
		display: flex;
		position: relative;
		flex-grow: 1;
		flex-direction: column;
		width: 100%;
		height: 100%;
		border: 1px solid #e3e3e3;
		border-left-width: 0px;
		border-top-width: 0px;
		padding: 10px;
	}

	.full-overlay {
		position: absolute;
		right: @postOverlayOverflow;
		left: @postOverlayOverflow;
		background: #fff;
		box-shadow: 0 19px 51px 0 rgba(0, 0, 0, 0.23);
		border-radius: 3px;
		z-index: 3;
		padding: 15px;
		visibility: hidden;

		&.visible {
			visibility: visible;
		}

		.day-label {
			font-size: 19px;
			margin-bottom: 5px;
		}

		.post-close-handler {
			position: absolute;
			right: 18px;
			top: 23px;
			color: @text-muted;
			font-size: 11px;
			cursor: pointer;
		}

		.post-list {
			max-height: @maxPostsInSelectedMode * (@postHeight + @postMargin) - @postMargin;
			overflow-y: auto;
		}
	}

	.start-week-day .full-overlay {
		left: 0;
		right: 2 * @postOverlayOverflow;
	}

	.end-week-day .full-overlay {
		left: 2 * @postOverlayOverflow;
		right: 0;
	}

	.day-label {
		width: 27px;
		height: 27px;
		line-height: 27px;
		border-radius: 27px;
		font-size: 14px;
		text-align: center;
		color: @text-color;

		&.otherMonth {
			color: @text-muted;
			opacity: 0.4;
		}

		&.today {
			background: #629fde;
			color: #fff;
		}
	}

	.post-list {
		padding: 5px 0 0;
		width: 100%;
	}

	.post-entry {
		line-height: @postHeight;
		white-space: nowrap;
		margin-bottom: @postMargin;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);
		overflow: hidden;
		font-size: 12px;
		cursor: pointer;
		width: 100%;

		display: grid;
		grid-template: "mainColor otherColors" /
						1fr auto;

		&.posted {
			box-shadow: none;

			.colors-holder, .background-holder {
				opacity: 0.2;
			}

			.text-holder {
				opacity: 0.7;
			}
		}

		&.selected {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.28);
		}

		&.opaque {
			opacity: .3;
		}
	}

	.main-color {
		grid-area: mainColor;
		position: relative;
		padding: 0 8px;

		&, .text-holder {
			position: relative;
			text-overflow: clip;
			white-space: nowrap;
			overflow: hidden;
			height: @postHeight;
			line-height: @postHeight;
		}

		.text-holder {
			z-index: 2;
		}

		.background-holder {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
	}

	.colors-holder {
		grid-area: otherColors;
		display: flex;
		flex-direction: row;
		align-content: stretch;

		.color {
			width: 5px;
		}
	}

	.posts-to-show {
		padding: 0 2px 2px;
		font-size: 12px;
		line-height: 20px;
		white-space: nowrap;
		color: #898e94;
		cursor: pointer;
	}

	.month-names {
		grid-area: monthNames;
		box-shadow: 0 1px 0 0 #e6e7ec;

		display: grid;
		grid-template-columns: repeat(@campaignsMonthsAmount, 1fr);
		grid-template-rows: 1fr;
		.create-grid-layout(month-indicator, 1, @campaignsMonthsAmount);

		// justify-items: stretch;
		// align-items: center;
		font-size: 14px;
		text-align: center;
		color: @text-color;

		// IE11 support
		.month-indicator {
			align-self: center;
			justify-self: stretch;
		}
	}

	.month-indicator {
		display: grid;
		grid-template: "yearIndicator" "monthIndicator";
		grid-template-columns: 1fr;
		grid-template-rows: 34px 40px;

		&:first-child {
			border-left: 1px solid @calendar-border-color;
		}
	}

	.month-year {
		grid-area: yearIndicator;
		color: @text-muted;
		padding: 13px 0 0;
		justify-self: center;
		align-self: start;
	}

	.month-label {
		grid-area: monthIndicator;
		justify-self: center;
		align-self: end;
		padding-bottom: 10px;

		span {
			display: inline-block;
			min-width: 37px;
			line-height: 26px;
			height: 26px;
		}

		.current {
			background-color: #66a1dd;
			color: #fff;
			border-radius: 16px;
		}
	}

	.months {
		grid-area: months;

		display: grid;
		grid-template-columns: repeat(@campaignsMonthsAmount, 1fr);
		grid-template-rows: 1fr;
		.create-grid-layout(month-column, 1, @campaignsMonthsAmount);

		border-top: 1px solid @calendar-border-color;
		// IMPORTANT: do not add borders around months,
		// since it breaks precise calculation of campaigns
		overflow-x: hidden;
		overflow-y: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.month-column {
		// IMPORTANT: do not add borders left/right here,
		// since it breaks precise calculation of campaigns
		border-bottom: 1px solid @calendar-border-color;
		position: relative;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	.month-border {
		width: 1px;
		height: 100%;
		background: @calendar-border-color;
		flex-grow: 1;
	}

	.campaign {
		height: 40px;
		padding: 10px;
		position: absolute;
		border-radius: 4px;
		color: #fff;
		line-height: 20px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
		z-index: 3;
		cursor: pointer;

		&.narrow {
			padding-left: 3px;
			padding-right: 3px;
		}

		&.leftOverflow {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.rightOverflow {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&.selected {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.28);
		}

		&.opaque {
			opacity: .3;
		}
	}

	.campaign-name {
		text-overflow: clip;
		white-space: nowrap;
		overflow: hidden;
		line-height: 20px;
		height: 20px;
	}

	.campaign-edit {
		grid-area: campaignEdit;

		> div, calendar-campaign-edit {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			height: 100%;
			overflow-y: auto;
		}

		.share-to-selector  {
			.ng-value-container .ng-value, .entry .full-width {
				max-width: 300px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.campaign-edit-holder {
		display: grid;
		grid-template: "campaignEditContent" "campaignEditActions";
		grid-template-rows: 1fr 60px;
		grid-template-columns: 1fr;
		height: 100%;
	}

	.campaign-edit-content {
		grid-area: campaignEditContent;
		padding: 25px 20px;
		border-bottom: 1px solid @calendar-border-color;

		.field-name {
			font-size: 12px;
			line-height: 1.5;
			color: @text-muted;
			padding-bottom: 4px;
		}

		.field-value {
			font-size: 14px;
			color: @text-color;
			margin: 0;
			padding: 0 0 15px;
		}
	}

	.campaign-edit-header {
		position: relative;
		padding: 0 88px 0 20px;
		margin: 0 0 17px;

		.in-control {
			position: absolute;
			right: 0;
			top: 0;
			left: auto;
			font-size: 12px;
		}
	}

	.campaign-edit-form-header {
		padding: 0;
		margin: 0 0 17px;
	}

	.campaign-edit-color {
		display: block;
		position: absolute;
		top: 5px;
		left: 2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
	}

	.campaign-edit-actions {
		grid-area: campaignEditActions;
		padding: 13px 20px;
	}

	.post-edit {
		grid-area: postEdit;

		calendar-post-edit {
			height: 100%;

			.post-edit-holder {
				height: 100%;

				.post-edit-content {
					height: 100%;

					publisher-post-view,
					publisher-post-edit {
						height: 100%;

						.social-compliance-publisher-post {
							height: 100%;

							.panel {
								@panel-width: @editHolderWidth;
								@footer-height: 60px;

								display: flex;
								flex-direction: column;
								margin: 0;
								padding-bottom: @footer-height;
								width: @panel-width;
								height: 100%;
								border: none;
								box-shadow: 1px 0 0 0 #e6e7ec;

								.panel-body {
									padding: 20px 20px 30px;
									overflow-y: auto;
									background-color: #fff;

									.panel-title {
										margin-bottom: 15px;
										font-size: 19px;
										font-weight: 400;
										line-height: 1.5;
									}
								}

								.panel-footer {
									position: fixed;
									bottom: 0;
									display: flex;
									align-items: center;
									width: @panel-width;
									height: @footer-height;
									padding: 0 20px;
									background-color: #fff;
									border: none;
									box-shadow: 0 -1px 0 0 #e6e7ec;

									.pull-right {
										margin-left: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
