.archive {
	.archive-entity {
		.ng-dropdown-panel-items.scroll-host {
			.ng-option:nth-child(3) {
				border-bottom: 1px solid #F1F1F1;
			}
		}
	}

	.loader {
		margin-top: 65px;
	}

	.filters-rows, .filters-rows .filter-selectors, .filters-rows .filter-selectors > div, .filters-rows .filter-selectors > div .ng-select {
		z-index: 2;
	}

	.post-list, .post-list infinite-scroll, .post-list .infinite-scroll, .infinite-scroll-entry {
		z-index: 1;
	}
}
