.add-comment-form {
	.btn .c-icon {
		margin-right: 2px;
	}

	.add-form-inline {
		.btn {
			align-self: flex-start;
		}
	}
}

.comments-list {
	.owner-icon {
		margin-left: 7px;
		font-size: 18px;;
		line-height: 18px;
		color: rgb(99, 159, 221);
		position: relative;
		top: 1px;
	}
}
