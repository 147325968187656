.get-content {
	background: #f3f9ff;
	padding: 25px 0;
	border-bottom: 1px solid #ebecf0;
	margin-bottom: 16px;
}

.horizontal-flex-group {
	.image-size {
		width: 80px;
		height:  80px;
	}

	.image-holder {
		align-self: start;
		flex-shrink: 0;
		overflow: hidden;
		border: 1px solid #dbdbdb;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		.rss-image {
			.image-size;
			background-size: cover;
		}

		i {
			color: #e6e7ec;
			font-size: 28px;
		}

		.image-size;

		img {
			.image-size;
		}
	}

	.description {
		display: flex;
		flex-direction: column;
		vertical-align: top;
		overflow: hidden;

		.media-link {
			text-overflow: ellipsis;
			max-width: 300px;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.feed-logo {
	height: 20px;
	width: 20px;
}
