.bottom-toolbar {
    .clearfix();
    margin: 30px 0;
}

.btn-holder {
    min-height: @input-height-base;
}

.aligned-label {
    line-height: @input-height-base;

    &.control-label {
        margin-bottom: 0;
    }
}

.control-label {
    .form-vertical & {
        margin-bottom: 0;
        .text-smaller;
    }
}

.control-value {
    display: block;
    margin-bottom: 0;
    line-height: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.control-field {
    margin-left: 0;
}

.rows-striped, .rows-bordered, .rows-white {
    margin: 0 15px;

    .inline-table-w11k {
        margin: -4px;
    }

    > .row-fluid , >.row {
        @row-min-height: 46px;

        min-height: @row-min-height;
        padding: ((@row-min-height - @input-height-base) / 2) 0;
        border-radius: @border-radius-base;

        &.empty {
            min-height: 0;
            padding: 0;
        }

        &.form-group {
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 0;

            + .form-group {
                margin-top: 10px;
            }
        }

        .control-label,
        .control-value {
            margin-bottom: 0;
            line-height: @line-height-computed;
            padding-top: ((@input-height-base - @line-height-computed) / 2);
            padding-bottom: ((@input-height-base - @line-height-computed) / 2);
        }

        .control-label {
            .form-vertical & {
                padding: 0;
            }
        }

        .form-control, .w11k-select, input:not([type=radio]):not([type=checkbox]):not([type=search]), .ng-select, .ng-select-aligned {
            margin-left: 0px;

            .form-vertical & {
                margin-left: 0;
            }
        }

        .ng-select .ng-input input {
            margin-left: 0 !important;
        }

        .btn + .dropdown-menu {
            margin-left: 0;
        }

        .select.dropdown-menu {
            min-width: calc(~"100% - 4px");
        }

        .w11k-select {
            .form-control, .dropdown-menu, input:not([type=radio]):not([type=checkbox]) {
                margin-left: 0;
            }
        }

        .form-control, uber-editable .select.dropdown-menu, .w11k-select {
            //min-width: calc(~"100% + 13px");
        }

        .form-control.form-control-default {
            min-width: 0;
            margin-left: 0 !important;
        }

        .form-control.checkbox-input {
            min-width: 0;
            display: inline-block;
        }

        &.header .form-control {
            min-width: 100%;
        }
    }

    .row, .row-fluid {
        &.clickable:hover,
        &.highlighted {
            background: fade(@brand-primary, 10%);
        }

        &.light-gray {
            background: fade(@text-muted, 10%);
        }
    }

    .action {
        &:hover {
            color: @brand-primary;
        }
    }

    .modal-body & {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 0;
        padding-right: 0;

        > .row-fluid, > .row {
            width: 100%;
            margin: 0 0;
            padding-left: 15px;
            padding-right: 15px;
        }

        &.max-h300 {
            max-height: 67*5px;
            overflow-x: hidden;
        }
    }
}

.rows-striped {
    > .row-fluid,
    > .row {
        &:nth-child(odd) {
            @bgColor: #F4F5F7;
            background: @bgColor;

            .remote-resource:after {
                background-color: fade(@bgColor, 75%);
            }
        }
    }
}

.rows-bordered {
    .value {
        line-height: 40px;
        color: @text-muted;
    }
    > .row-fluid, > .row {
        border-radius: 0px;
        border-bottom: 1px solid @border-grey;

        .button-holder {
            padding-right: 1px;
        }

        &.no-border {
            border-bottom: none;
        }
    }
    &.top-border > .row-fluid:first-child, &.top-border > .row:first-child {
        border-top: 1px solid fade(#000, 15%);
    }

    > .row-fluid.header,  > .row.header {
        @line-height: 17px;
        @min-height: @line-height * 2;

        border: none;
        background:  #f4f5f7;
        color: #808080;
        min-height: @min-height;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 12px;
        line-height: @line-height;
        box-sizing: border-box;
        padding: 0;

        b {
            // this is sick!
            font-weight: normal;
        }

        &.no-padding {
            padding: 0;
        }

        .button-holder {
            .btn {
                border-radius: 0;
            }
        }

        .modal-body & {
            padding-left: 15px;
            padding-right: 15px;
        }

        .has-feedback {
            padding-top: 8px;
            padding-bottom: 7px;
        }

        .form-control {
            border: 1px solid #d6d9dd;
            margin-left: 0;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-size: 13px;
            line-height: @line-height + 3px;
            color: #333;
            background: #fff;
            border-radius: 3px;
            padding: 5px 8px 5px 30px;
            height: 30px;

            @placeholder-opacity: 0.6;
            &::-webkit-input-placeholder {
                color: inherit;
                opacity: @placeholder-opacity;
            }

            &::-moz-placeholder {
                opacity: @placeholder-opacity;
            }

            &:-ms-input-placeholder {
                opacity: 1;
                background: #fff;
                color: fade(#333, @placeholder-opacity * 100);
            }

            &::-ms-clear {
                display: none;
            }
        }
        .ng-select {
            font-size: 13px;
            line-height: @line-height + 6px;
        }

        > * {
            white-space: nowrap;
            vertical-align: middle;
            word-wrap: normal;
            padding-top: 1px;
            padding-bottom: 0;
            min-height: @min-height;
            border-bottom: 1px solid @border-grey;
            box-sizing: border-box;

            & > b, & > .title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 100%;
                vertical-align: middle;

                &.two-liners {
                    max-height: @min-height;
                    line-height: 14px;
                    white-space: normal;
                    word-break: normal;
                }

                &.header-title {
                    padding-top: 2px;
                    padding-bottom: 2px;
                    line-height: 14px;
                    white-space: normal;
                    word-break: normal;
                }
            }
        }

        .form-control-feedback {
            position: absolute;
            line-height: 30px;
            width: 30px;
            height: 30px;
            font-size: 15px;
            top: 8px;
            left: 3px;
            right: auto;
            color: #cdcdcd;
            opacity: 1;
            text-decoration: none;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.list-filters {
    &:extend(.rows-bordered > .row-fluid.header all,  > .row.header all);
    background: none;

    .has-feedback {
        padding-top: 0px;
        .form-control-feedback {
            top: -1px;
            left: 1px;
        }
    }

    > * {
        border-bottom: none;
    }
}
