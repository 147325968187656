production-top-list {
    position: relative;
    display: block;
    .alert-info {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 40px;
        z-index: 1;
        padding: 3px;
    }
}