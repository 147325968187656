.share-to-selector {
	&.view {
		padding: 5px 0 0 8px;

		.entry-item {
			padding-bottom: 5px;
		}
	}

	.bordered {
		border-bottom: 1px solid #ececec;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		padding: 0;
	}

	.entry {
		padding: 4px 10px;
		color: @text-color;
	}

	.group-icon {
		color: @brand-grey-light;
	}

	.ng-option-selected {
		.entry {
			font-weight: bold;
		}
	}
}
