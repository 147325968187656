columns {
    display: flex;
    gap: var(--col-gap);

    .flex-col {
        flex: 1 1 0;
        min-width: 0;
        display: flex;
        flex-direction: column;
        gap: var(--col-gap);
    }
}

card-preview {
    display: block;
    padding-block: 20px;
}

card {
    --card-border-radius: 6px;
    --border-color: @brand-primary;
    --border-color-inner: mix(@brand-primary, transparent, 15%);
    --header-bg-color: @brand-primary;
    --dura: 0.3s;

    display: block;
    border: 1px solid var(--border-color);
    border-radius: var(--card-border-radius);
    box-shadow: 1px 1px 5px 1px rgba(0 0 0 / 0.08);
    transition: box-shadow var(--dura) linear;

    .card-header {
        --card-header-radius: calc(var(--card-border-radius) - 0px);
        --title-color: #FFF;
        color: var(--title-color);
        background: var(--header-bg-color);
        border-bottom: 1px solid var(--border-color-inner);
        border-radius: var(--card-header-radius) var(--card-header-radius) 0 0;
        margin: -1px;
        display: flex;
        align-items: center;
        padding: 10px 10px 10px 10px;
        gap: 20px;
        transition: border-radius var(--dura) linear;

        &:hover {
            --header-bg-color: darken(@brand-primary, 4%);
            cursor: pointer;
        }

        .card-header-title {
            flex: 1 1 0;
            font-size: 18px;
            line-height: 1.15;
            min-width: 1px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .card-header-buttons {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .collapse-button {
            font-size: 21px;
            font-weight: 700;
            width: 30px;
            line-height: 30px;
            text-align: center;
            rotate: 90deg;
            transform-origin: 55% 50%;
            transition: rotate var(--dura) ease-in-out;
        }

        &:last-child {
            border-bottom: 0;
            border-radius: var(--card-header-radius);
        }
    }

    &.expanded {
        box-shadow: 2px 2px 15px 1px rgba(0 0 0 / 0.15);
        .collapse-button {
            rotate: -90deg;
        }
    }

    .card-contents {
        padding: 20px 10px;

        hr {
            border-color: var(--border-color-inner);
            margin-block: 10px;
        }

        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;

            &:first-child {
                margin-top: 0;
            }
        }

        .form-row {
            padding-inline: 10px;
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-rows: auto auto;
            column-gap: 30px;

            .form-cell {
                min-width: 0; // Prevents cell expanding to fit content
                display: grid;
                grid-row: 1 / -1;
                grid-template-rows: subgrid;
                overflow-wrap: anywhere;
            }

            .control-label {
                margin: 0;
                font-size: 12px;
                align-self: flex-end;
            }
        }
    }
}
