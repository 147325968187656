.company-listing-group-details {
	@width: 290px;
	@height: @width/@cover-aspect-ratio;
	.listing-group-image {
		.list-item-image-fn(@width, @height);
	}

	.listing-group-logo {
		.list-item-image-fn(@height, @height, 50%);
	}

	.listing-group-cover {
		.list-item-image-fn(@width, @height);
	}

	.listing-group-logo-description {
		width: 180px;
	}

	.listing-manage-images {
		h3 {
			margin-bottom: 10px;
		}

		p {
			margin-bottom: 15px;
		}
	}

	.file-handler {
		margin: 0;

		input[type=file] {
			.ng-hide;
		}

		.glyphicon-question-sign {
			display: inline-block;
			.va-m;
			color: #AFB9BD;
			top: -1px;
			margin-left: @padding-base-horizontal;
		}
	}

	.create-grid-row(@cols, @row: 1, @i: 1) when (@i =< @cols) {
		@childNumber: (@row - 1) * @cols + @i;
		:nth-child(@{childNumber}) {
			grid-column: 2 * @i - 1;
			grid-row: @row;
			margin-bottom: 5px;
		}

		.create-grid-row(@cols, @row, @i + 1);
	}

	.create-grid-layout(@rows, @cols, @i: 1) when (@i =< @rows) {
		.create-grid-layout(@rows, @cols, @i + 1);
		.create-grid-row(@cols, @i);
	}

	.listing-group-details {
		display: grid;
		// IE11 doesn't support grid-gaps, so column gap is just an empty column, while row-gap is margin-bottom
		grid-template-columns: auto 15px 1fr;
		align-self: center;
		.create-grid-layout(10, 2);

		:nth-last-child(-n+2) {
			margin-bottom: 0;
		}

	}

	.regular-hours .rows-bordered > .row-fluid .form-group + .form-group {
		margin-top: 0;
	}

	.btn-holder {
		box-sizing: content-box;
	}

	.business-name.has-feedback {
		sm-network-icon {
			top: 2px;
			left: initial;
			margin-left: 2px;
			right: initial;
			opacity: 1;

			.sm-network-icon-icon {
				font-size: 20px;
			}
		}

		.form-control {
			padding-left: @input-height-base;
			paddight-right: @padding-base-horizontal;
		}
	}
}

@import './overview/common/move-listing-modal/move-listing-modal.component.less';
