.text-overflow {
    .text-overflow();
    word-wrap: normal;
}

.text-overflow-wrap {
    .text-overflow();
    white-space: normal;
}

.tags-holder > ul {
    > li {
        .text-overflow-wrap();
        margin-right: 5px;
        margin-bottom: 2px;
    }

    .label {
        font-size: 14px;

        &.selected {
            .label-light(darken(@brand-primary, 25%));
        }
    }
}
