.template-network-selector {
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		padding: 0;
	}

	.entry {
		padding: 4px 10px;
		color: @text-color;
	}

	.ng-option-selected {
		.entry {
			font-weight: bold;
		}
	}
}

.template-network-list {
	padding: 5px 0 0 8px;

	.entry-item {
		padding-bottom: 5px;
	}
}
