.social-compliance-review-checklist {
	.observation {
		margin-bottom: 7px;
	}

	.status-icon-holder {
		.text-muted;
		padding: 5px 0 0;
	}
}
