.prospects {
	.flex-head {
		.state-badge {
			display: inline-block;
			vertical-align: top;
		}
	}
	.notification {
		display: none;
	}
	.line-holder {
		position: relative;
		min-height: 68px;
		padding: 12px;
		.notification {
			background: #F6FAFE;
			line-height: 33px;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 999;
			margin: 0;
			padding: 15px 0;
			.btn {
				background: none;
			}
		}
	}
	.option-separator {
		.ng-dropdown-panel-items div > div:first-child{
			border-bottom: 1px solid #E5E5E5;
			font-weight: bold;
		}
	}
}
