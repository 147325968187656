.manage-feed {
	.empty-list {
		border: 1px solid #e6e7ec;
		border-width: 1px 0;
		min-width: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 14px;

		color: @text-muted;
	}

	.logo-holder {
		width: 40px;
	}

	.logo {
		width: 40px;
		height: 40px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.rss-content {
		.title {
			color: @text-color;
			line-height: 20px;
			font-size: 14px;
			margin-top: 0;
			margin-bottom: 5px;
		}

		.description {
			font-size: 14px;
			line-height: 1.43;
			color: @text-muted;
		}
	}

	.full-width {
		width: 100%;
	}

	.inline-menu {
		position: relative;
		cursor: pointer;
	}

	.addLinkGroup {
		display: flex;
		padding: 8px 15px;
		background: #f4f9ff;
		border-bottom: 1px solid #E6E7EC;

		.logo-holder {
			.logo {
				background-image: url('/static/images/backend/rss/default-rss.png');
			}
		}

		.full-width {
			position: relative;

			.glyphicon-remove {
				position: absolute;
				top: 0px;
				line-height: 30px;
				color: #333;
				text-decoration: none;
				color: #CDCED0;
			}

			input {
				width: 100%;
			}
		}
	}
}
