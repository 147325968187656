.alphabetic-filter {

	.item {
		padding: 3px 6px;
		display: block;
		text-decoration: none;
	}

	.active {
		background: #66A1DD;
		color: #fff;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		li {
			margin-right: 3px;
			float: left;
			a {
				.item;
				&:hover {
					.active;
				}
				border-radius: 6px;
			}
			span {
				.item;
			}
		}
	}

}
