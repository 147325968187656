.title-label {
	span.label {
		font-size: 42%;
		vertical-align: 3px;
	}
}

.users-management, .list-management {
	.permission .glyphicon {
		position: absolute;
		margin-left: -20px;
		top: auto;

		&.glyphicon-ok {
			color: @brand-success;
			margin-top: 2px;
		}

		&.glyphicon-remove {
			color: @brand-danger;
			margin-top: 3px;
		}
	}
	#userInformation {
		.reports-to .dropdown-menu .content {
			max-width: 350px;
		}
	}

	.columns2 {
		column-count: 2;
	}

	.left-align {
		padding-left: 5px;

	}

	.dropdown-target {
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: #808080;
		border-radius: 3px;
		cursor: pointer;

		&:hover, .open {
			background-color: #f4f5f7;
		}
	}

	.link-option {
		padding-left: 10px;
		padding-right: 8px;
	}

	.inline-edit {
		margin-top: -5px;
		min-width: 48px;
		padding-right: 5px;
		min-height: 30px;
		text-align: left;
		height: auto;

		.glyphicon {
			font-size: 13px;
		}

		&, .text-default {
			white-space: normal;
			word-break: break-all;
		}
	}
}
