.reviews-settings-images {
	.wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.review-details {
			&:extend(.publisher .review-details all);
			margin: 0px 25px 0 0;

			.data-provider {
				padding: 5px 25px;
				border: 1px solid #dbdbdb;
				color: grey;
				display: inline-block;
				border-radius: 2px;
			}

			footer img {
				width: 120px;
				height: 32px;
			}
		}

		.review-lender-logos {
			width: 395px;

			> div {
				float: left;
				width: 125px;
				height: 125px;
				padding: 10px;
				border-radius: 50%;
				margin: 0 10px 10px 0;
				background-size: cover;
				box-shadow: inset 0 0 0 1px #dbdbdb;
				position: relative;

				&.add-image {
					display: flex;
					justify-content: center;
					align-items: center;
					color: @brand-primary;
					cursor: pointer;
				}

				.icon-holder {
					padding: 2px 5px;
					border: 1px solid #ccc;
					position: absolute;
					bottom: 5px;
					right: 5px;
					display: block;
					border-radius: 500px;
					background: #fff;

					.c-icon {
						color: #aa9898;
						cursor: pointer;
					}

					.c-icon-flag {
						color: #66a2de;
					}
				}
			}

			.defalut {
				box-shadow: inset 0 0 0 2px #66a1dd;

				.icon-holder {
					.c-icon {
						cursor: auto;
					}
				}
			}

			div:nth-child(3n) {
				margin-right: 0px;
			}
		}
	}
}
