.main-menu-holder {
	.dropdown {
		.dropdown-menu {
			.icon-container {
				display: flex;
				justify-content: space-between;
				.c-icon {
					line-height: inherit;
				}
			}
		}
	}
}
