.move-template-modal {
	label.control-label {
		margin-bottom: 10px;
	}
	.first-item-border {
		.ng-option:first-child {
			border-bottom: 1px solid #ececec;
		}
	}
}
