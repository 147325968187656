posts-preview {
	flex-grow: 1;
	overflow-y: auto;
	padding-top: 20px;
	background: url("/static/images/only-logo-big.png") center center no-repeat;
}

posts-preview.hide-logo {
	background: none;
}

.social-compliance-publisher-post,
.social-compliance-publisher-post-template {
	.content {
		posts-preview {
			.alert {
				width: 500px;
				margin: 0 auto 20px;
			}

			.alert-limits{
				margin: 35px auto 10px;
				white-space: pre-line;
			}
		}
	}
}
