@import "connects-modals/connects-modal.component.less";
@import "connect/fb-pages-modal/facebook-pages-modal.component.less";
@import "connect/linkedin-pages-modal/linkedin-pages-modal.component.less";
@import "connect/fb-mortgage-related-modal/facebook-related-modal.component.less";
@import "connect/gmb-listings/gmb-listings.modal.component.less";
@import "inventory/inventory.component.less";

.social-accounts-company {
	.medium-icon-holder {
		line-height: 24px;

		.glyphicon {
			top: -2px;
		}
	}

	.row-fluid.danger {
		background: @btn-outline-danger-hover-bg;
	}

	.team-access-holder {
		width: 26px;
	}

	.icon {
		display: inline-block;
		width: 26px;
		height: 26px;
		vertical-align: middle;
	}

	.icon-user-check {
		background: url('/static/images/user-check.svg') no-repeat;
	}

	.icon-time {
		background: url('/static/images/time.svg') no-repeat;
	}

	.text-overflow-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		display: inline-block;
	}
}

//----------------------------------------------Digital Media------------------------------------------------\\
	@dark-blue: #5474B7;
	@nmls-blue: #313C9D;
	@sky-blue: #55ACEE;
	@deep-blue: #0076B7;
	@red: #D44444;
	@light-red: #F83F2E;
	@red-danger: #dd534f;
	@light-blue: #66A1DD;

	//Icons
	.icon-dark-blue {
		color: @dark-blue;
	}

	.icon-deep-blue {
		color: @deep-blue;
	}

	.icon-nmls-blue {
		color: @nmls-blue;
	}

	.icon-sky-blue {
		color: @sky-blue;
	}

	.icon-red {
		color: @red;
	}

	.icon-light-red {
		color: @light-red;
	}

	.icon-light-blue {
		color: @light-blue;
	}

	.light-gray {
		i {
			color: lightgray;
		}
	}

	.light-green {
		color: #73c572;
	}

	.red-danger {
		color: @red-danger;
	}
	//end:Icons

	//Buttons
	.btn-section {
		.default-sm-button(@color) {
			background: @color;
			border-color: @color;
			color: #fff;
			.c-icon {
				font-size: 13px;
			}
		}

		.btn-dark-blue {
			.default-sm-button(@dark-blue);
		}

		.btn-sky-blue {
			.default-sm-button(@sky-blue);
		}

		.btn-gmb-blue {
			.default-sm-button(#518EF8);
		}

		.btn-deep-blue {
			.default-sm-button(@deep-blue);
		}

		.btn-red {
			.default-sm-button(@red);
		}

		.btn-light-red {
			.default-sm-button(@light-red);
		}

		.instagram-gradient {
			color: #fff;

			.c-icon {
				font-size: 13px;
			}

			border: none;
			background: rgb(207, 38, 151);
			background: -moz-linear-gradient(90deg, rgba(207, 38, 151, 1) 0%, rgba(253, 154, 51, 1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(207, 38, 151, 1) 0%, rgba(253, 154, 51, 1) 100%);
			background: linear-gradient(90deg, rgba(207, 38, 151, 1) 0%, rgba(253, 154, 51, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cf2697",endColorstr="#fd9a33",GradientType=1);
		}
	}

	//end: Buttons
//-------------------------------------------end: Digital Media----------------------------------------------\\
