@import "fonts-source";

.ng-hide, #content-holder.logout {
	display: none !important;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important;
}

body.dev:before,  body.Stage:before, body.Beta:before{
	content: "dev";
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	background-color: #dd534f;
	color: #fff;
	font-size: 1.5em;
	opacity: 0.8;
	pointer-events: none;
	-webkit-transform-origin: 33px 45px;
	transform-origin: 33px 45px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	width: 80px;
	text-align: center;
	border: 3px dotted;
	line-height: 0.9em;
}

#browser-is-outdated-message,
#cookie-enabled-message {
	background-color: #fff;
	.alert {
		margin-bottom: 0;
	}
}

.small-left-padding {
    padding-left: 0.5em;
}
@quillPath: '~quill/dist';
@import "comergence-icons";
@import "bootstrap.bootstrap.less";
@import '@{quillPath}/quill.snow.css';
@import "vars";
@import "mixins";

/** common styles **/
@import "loader";
@import "common";
@import "horizontal-flexgroup";
@import "dp-grid";
@import "misc/w11k-select";
@import "misc/alerts";
@import "misc/ng-image-gallery";

@import "misc/resource";
@import "misc/pagination";
@import "misc/breadcrumbs";
@import "misc/scroll-up";
@import "common/modal";
@import "field-icons";
@import "top-menu";
@import "documents";
@import "datamatch";
@import "login/brandingLogin";
@import "account";
@import "account/company";
@import "account/contacts";
@import "account/certifications";
@import "account/company/insurance-bonds";
@import "account/relationships/agency";
@import "account/relationships/affiliate";
@import "account/production/origination";
@import "wizard";
@import "hmda";
@import "application";
@import "home";
@import "lenders";
@import "lender";
@import "manage";
@import "settings";
@import "notifications";
@import "alerts-settings";
@import "payments";
@import "badges";
@import "information";
@import "channel-settings";
@import "users-management";
@import "my-settings";
@import "footer";
@import "search";
@import "findings";
@import "comergence";
@import "services";
@import "mcr";
@import "market";
@import "market-map";
@import "accordion";
@import "alphabetic-filter";
@import "territory";
@import "misc/tree";
@import "misc/chart";
@import "crm/prospects";
@import "promo";
@import "neighborhood-watch";
@import "crm/clients-report";
@import "crm/activities-report";
@import "social-media";
@import "social-media-campaigns";
@import "social-media-posts";
@import "lender/contact";
@import "social-compliance";
@import "publisher-requests";
@import "remediation";
@import "payment";
@import "misc/contact-us";
@import "terms-of-use";
@import "team-access";
@import "hecm";
@import "collapsible-card.less";
@import "contacts/import";

//lender Solution Center
@import "lender/solution-center.component.less";


//Updated
@src: "~";

//Common
@import "calendar";
@import "@{src}commons/components/navigation/sidenav-menu/sidenav-menu.component.less";
@import "@{src}commons/components/layouts/full-page/full-page-layout.component.less";
@import "@{src}commons/components/toggle/toggle.component.less";
@import "@{src}commons/components/new-sm-link/new-sm-link.component.less";
@import "@{src}commons/components/sm-icons/sm-icons.less";
@import "@{src}commons/components/sm-post/sm-post.less";
@import "@{src}commons/components/uploader/uploader";
@import "@{src}commons/components/infinite-scroll/infinite-scroll.component.less";
@import "@{src}commons/components/show-more/show-more.component.less";
@import "@{src}commons/components/comments/comments.component.less";
@import "@{src}commons/components/checkboxes-group/checkboxes-group.component.less";
@import "@{src}commons/components/select-checkboxes/select-checkboxes.component.less";
@import "@{src}commons/components/checklist/checklist.component.less";
@import "@{src}commons/components/attachments-preview/attachments-preview.component.less";
@import "@{src}commons/components/formatted-text/formatted-text.component.less";
@import "@{src}commons/components/cell-select/cell-select.component.less";
@import "@{src}commons/components/short-hand-edit/popover-edit/popover-edit.component.less";

@import "login/generic-login";

@import "ng-select/default.theme.less";
@import "ng-select.override.less";

@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import "ngx-bootstrap/datepicker/bs-datepicker.override.less";
@import "common/components/tag-input";
@import "common/components/image-cropper";
@import "common/components/card-layout";
@import "common/components/new-inline-edit";
@import "@{src}commons/components/reviews/reviews.component.less";

@import "@{src}commons/components/segmented-controls/segmented-controls.less";
@import "@{src}commons/components/new-list/new-reset-filters.component.less";
//end:Common

//Shared
@import "@{src}shared/social-media/social-media.less";
@import "@{src}shared/account/licenses/licenses.less";
@import "@{src}shared/reviews/reviews.less";
@import "@{src}shared/findings/findings.less";
@import "@{src}shared/questionnaires/questionnaires.less";
@import "@{src}shared/social-compliance/audits/info/audit-findings/audit-findings.component.less";
@import "@{src}shared/social-compliance/audits/info/comments-filter/comments-filter.component.less";
@import "@{src}shared/social-compliance/triggers/word-editor/word-editor.less";
@import "@{src}shared/social-compliance/triggers/list/history/trigger-history-modal.component.less";
@import "@{src}shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/tpo-financial-document-upload-modal/tpo-financial-document-upload-modal.component.less";
@import "@{src}shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/client-financial-settings-modal/client-financial-settings-modal.component.less";
@import "@{src}shared/account/financials/tpo-financial-document-list/tpo-financial-summary/tpo-financial-summary.component.less";
@import "@{src}shared/prospecting/prospecting-status/prospecting-status.component.less";
@import "@{src}shared/production/total-volume/total-valume.less";
@import "@{src}shared/production/top-list/top-list.less";
//end:Shared

//Tpo
@import "@{src}tpo/social-accounts/social-accounts.less";
@import "@{src}tpo/social-compliance/monitoring-settings/monitoring-settings.less";
@import "@{src}tpo/social-compliance/publisher/publisher.less";
@import "@{src}tpo/social-compliance/publisher/attachments/attachments.less";
@import "@{src}tpo/social-compliance/publisher/attachments/edit-thumbnail-modal/edit-thumbnail-modal.component.less";
@import "@{src}tpo/social-compliance/publisher/campaigns/$campaignId/share-to/share-to.less";
@import "@{src}tpo/social-compliance/orders/new/new.component.less";
@import "@{src}tpo/social-compliance/reviews/reviews.less";
@import "@{src}tpo/social-compliance/settings/settings.less";
@import "@{src}tpo/social-compliance/publisher/campaigns/list/enroll-accounts-modal.component.less";
@import "@{src}tpo/social-compliance/archive/archive.less";
@import "@{src}tpo/export/export.less";
@import "@{src}tpo/applications/list/applications-list.component.less";
@import "@{src}tpo/contacts/tpo-mobile-registration/tpo-mobile-registration.component.less";
@import "@{src}tpo/listings/listings.less";
@import "@{src}tpo/home/required-documents-partners/required-documents-partners.less";
@import "@{src}tpo/social-media/subscription/subscription.component.less";
@import "@{src}tpo/social-media/request-demo/request-demo-modal/request-demo-modal.component.less";
@import "@{src}tpo/social-media/subscription/payment/payment.component.less";
@import "@{src}tpo/account/investors/investors.less";
@import "@{src}tpo/account/underwriting/underwriting.less";
@import "@{src}tpo/solution-center/solution-center.component.less";
@import "@{src}tpo/solution-center/tabs/products/products-subscription/product-subscription.component.less";
//end:Tpo

@import "@{src}lender/questionnaires/questionnaires.less";
@import "@{src}lender/crm/market/map/map.component.less";

// shared documents
@import '@{src}shared/documents/documents.less';
@import '@{src}shared/supporting-documents/supporting-documents.less';

// global documents
@import "@{src}lender/documents/documents.less";

// client documents
@import "@{src}lender/clients/$id/documents/client-documents.less";
@import "@{src}lender/clients/list/relationship-status-modal/relationship-status-modal.component.less";
@import "@{src}lender/clients/list/renewal-modal/renewal-modal.component.less";

// lender alerts
@import "@{src}lender/alerts/alerts-modal/alerts-modal.component.less";

// lender crm
@import "@{src}lender/crm/activities/$id/details/details.component.less";
@import "@{src}lender/crm/market/msa-list/msa-list.component.less";
@import "@{src}lender/crm/market/market-search/market-search.component.less";
@import "@{src}lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list.less";
@import "@{src}lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/contact-list-contacts-list.less";
@import "@{src}lender/crm/contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/add-contact-list-contact/add-contact-search/add-contact-search.component.less";

// lender fields
@import "@{src}shared/fields/custom-fields.less";
@import "@{src}lender/clients/clients.less";

//shared contacts
@import "@{src}shared/new-contacts/new-contacts.less";

// shared scorecard
@import "@{src}shared/due-diligence/scorecard/scorecard-finding/details-header/scorecard-finding-details-header.less";

//lender Application Summary
@import "@{src}lender/applications/application-summary/application-summary.less";

//registration
@import "@{src}login/registration/new-registration-component.less";

@import "@{src}tpo/account/section-review/section-review.component.less";

@import "@{src}lender/manage-channels/manage-channel-cards.component.less";

@import "@{src}lender/manage-channels/manage-channel-alerts/manage-channel-alerts.component.less";
