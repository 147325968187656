.services {
	.client-id {
		.row-fluid {
			background: #fff;

			.control-label {
				white-space: nowrap;
				margin-left: -15px;
			}

			.col-sm-8.preview {
				padding-left: 0px;
			}
		}
	}

	.radio-holder {
		padding-left: 20px;
		font-size: 15px;
		line-height: 24px;
		display: inline-block;

		&.multiple {
			padding-left: 0;

			input {
				top: 4px;
			}
		}
	}
	.off {
		font-weight: bold;
		color: @text-muted;
		font-size: 18px;
		line-height: 20px;
	}
}

ul.dashed-list {
	margin: 0;
	padding: 0;
	li {
		display: block;
		list-style: none;
		&:before {
			display: inline-block;
			content: "-";
			margin-right: 5px;
		}
	}
}
