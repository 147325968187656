.my-settings {
	.bg-white {
		background: #fff!important;
	}
	.has-feedback {
		.form-control-feedback {
			right: 5px;
		}
		.pin-code {
			.form-control-feedback {
				right: 15px;
			}
		}
	}
	.pin-code {
		width: 100px;
	}
}
