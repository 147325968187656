.social-media-post-attachments {
	.attachments-preview {
		.attachments {
			&-container {
				max-height: 400px !important;
			}

			&-item {
				img {
					cursor: pointer;
				}

				.excess {
					cursor: pointer;
				}
			}
		}
	}

	.video-preview {
		.glyphicon;
		position: relative;
		top: 0;

		&:after {
			content: "\e029";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 999;
			display: flex;
			justify-content: center;
			align-items: center;
			color: fade(@text-muted, 75%);
			font-size: 3.5em;
			font-weight: bold;
			background-color: #fff;
			opacity: .5;
			cursor: pointer;
			pointer-events: none;
		}
	}

	.TW {
		.attachments-preview {
			border: 1px solid #CCD6DD;
			border-radius: 15px;
			width: fit-content;

			img {
				object-position: 50% 66%;
			}
		}
	}

	.IG {
		.attachments-preview {
			max-width: 600px;
		}
	}
}
