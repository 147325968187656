.modal .modal-new .groups-modal {
  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 15px;
    max-height: 400px;
    overflow-y: auto;
  }
}

.groups-modal {
  .row > div {
    padding-left: 20px;
  }

  header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #F4F5F7;
    padding: 8px 0;
    border-top: 2px solid #EEEFF2;
    border-bottom: 2px solid #EEEFF2;

    & ~ div {
      .row {
        padding: 17px 0;
        border-bottom: 2px solid #F2F3F5;
      }

      .row:last-child {
        min-height: 57px;
      }
    }
  }

  .c-icon-users {
    min-width: 35px;
  }
}
