.tpo-financial-document-modal {

    .flex-row.gap-l > .flex-grow.rows-white {
        margin-left: 0 !important;
        margin-right: -20px !important;
        margin-block: -10px;
    }

    .tpo-financial-document-modal-header {
        margin-top: 0;
        margin-bottom: 0;
    }

    .tpo-financial-document-modal-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    // TODO: Figure out how to get the columns to split the row 50/50
    .tpo-financial-document-modal-period-year-hack {
        min-width: -webkit-fill-available;
    }

    .period-select {
        width: 130px;
    }
}