.modal .modal-new .edit-thumbnail-modal .modal-body {
	padding-bottom: 10px;
}

.edit-thumbnail-modal {
	@light-blue: #66A1DD;
	@thumbnail-size: 120px;

	.thumbnails {
		display: flex;
		justify-content: space-between;

		.thumbnail  {
			background: #F3F9FF;
			border: 1px solid #EEF0F4;
			width: @thumbnail-size;
			height: @thumbnail-size;
			border-radius: 5px;
			overflow: hidden;
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;

			&:hover:not(.curtain):not(.selected) {
				border: 2px solid rgba(102, 161, 221, 0.51);
			}

			img {
				width: 100%;
			}
			&.curtain {
				cursor: auto;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			loader {
				position: absolute;
				width: 100%;

				& > div {
					margin: auto;
				}
			}

			&.selected {
				.glyphicon;
				.glyphicon-ok-sign;
				display: flex;
				border: 3px solid @light-blue;
				border-radius: 8px;
				box-sizing: content-box;

				&:before {
					position: absolute;
					top: 3px;
					right: 3px;
					font-size: 24px;
					color: @light-blue;
					background: white;
					border-radius: 50%;
					padding: 2px;
				}
			}
		}

		uploader {
			width: @thumbnail-size;

			label {
				width: @thumbnail-size;
				margin-top: 8px;
				text-align: center;
			}

			.remove {
				position: absolute;
				top: 3px;
				right: 3px;
				color: white;
				text-align: center;
				font-size: 16px;
				line-height: 16px;
				background: #aaa;
				padding: 4px;
				box-sizing: content-box;
				border-radius: 50%;
				opacity: 0;
				transition: opacity 0.3s;
				cursor: pointer;
				z-index: 1000;
			}

			.selected {
				& ~ label {
					margin-left: 3px;
				}

				&.no-generated {
					&:before {
						opacity: 0;
					}

					&:hover .remove {
						opacity: 1;
					}

					&:hover .remove:hover {
						box-shadow: 0 0 4px black;
						background: #8e8e8ee0;
					}
				}
			}

			&.hover {
				.thumbnail {
					border: 2px solid #66A1DD;
				}
			}
		}

		.thumbnail-icon {
			font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 36px;
			color: white;
			background: @light-blue;
			border-radius: 50%;
			width: 44px;
			line-height: 45px;
			text-align: center;
			margin: auto;

			&.plus:before {
				content: '+';
			}
		}

		.attachment-video:before {
			&:extend(post-attachments .attachment-video:after all);
		}
	}

	input[type=file] {
		display: none;
	}
}
