.social-compliance-publisher-post-template {
	.first-item-border {
		.ng-option:first-child {
			border-bottom: 1px solid #ececec;
		}
	}

	.ng-select.campaigns {
		.ng-option-disabled {
			background-color: #F4F5F7;
		}
		.ng-value-container .ng-value.ng-value-disabled {
			padding: 1px;
			background-color: #F4F5F7;
		}
	}
}
.template-update-modal {
	.radio-holder {
		padding-left: 22px;
	}
}
