.social-media-post {
	padding: 20px;

	& > * {
		display: block;

		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

@import 'header/sm-post-header.component.less';
@import 'message/sm-post-message.component.less';
@import 'attachments/sm-post-attachments.component.less';
@import 'comments/sm-post-comments.component.less';
