post-attachments {
	@side-padding: 3px;

	.attachments {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -@side-padding (10px - @side-padding);

		.attachment-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.attachment {
				display: block;
				width: 110px;
				height: 110px;
				margin: 0 @side-padding 2*@side-padding;
				border: 1px solid rgba(0, 0, 0, 0.07);
				border-radius: 3px;
				position: relative;
				background-color: #f6f9fa;

				img {
					display: block;
					width: 110px;
					height: 110px;
					margin: -1px;
					border-radius: 3px;
					z-index: -1;
					object-fit: cover;
				}

				.remove {
					position: absolute;
					top: 6px;
					right: 6px;
					color: white;
					text-align: center;
					font-size: 16px;
					line-height: 16px;
					background: #aaa;
					padding: 4px;
					box-sizing: content-box;
					border-radius: 50%;
					opacity: 0;
					transition: opacity 0.3s;
					cursor: pointer;
					z-index: 1000;
				}

				&:hover .remove {
					opacity: 1;
				}

				.error {
					position: absolute;
					bottom: 6px;
					left: 6px;
					top: auto;
					text-align: center;
					font-weight: 600;
					width: 24px;
					height: 24px;
					line-height: 24px;
					background: @brand-danger;
					color: white;
					border-radius: 50%;
					cursor: default;
					display: block;
				}

				.loader-container {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.edit-thumbnail {
				font-size: 12px;
				padding-bottom: 4px;
			}
		}
	}

	.attachment-video {
		 &:after {
			 content: "";
			 width: 100%;
			 height: 100%;
			 background: transparent url("/static/images/video-play.png") center no-repeat;
			 background-size: 36px;
			 position: absolute;
			 left: 0;
			 top: 0;
		 }
	}

	.attachment-image {
		background: url("/static/images/image_icon.png") center no-repeat;
	}
}
