@border-color: #e6e7ec;
@side-padding: 3px;

post {
	&:extend(post-attachments all);
	&:extend(.soc-icon all);
	display: block;
	border-radius: 3px;
	border: solid 1px @border-color;
	background-color: #fff;
	width: 500px;
	margin: 0 auto 20px;

	header {
		display: flex;
		align-items: center;
		height: 45px;
		padding: 0 16px;
		border-bottom: 1px solid @border-color;
	}

	.post-content {
		.post-row {
			display: flex;
			padding: 15px;
			overflow-wrap: break-word;
			word-wrap: break-word;

			.column {
				padding-right: 10px;
			}

			.column:last-child {
				flex: 1 1 auto;
				padding-right: 0;
				width: 0; //trick to force overflow-wrap to work
			}
		}

		.profile-image {
			z-index: 1000; // fix overlapping by load animation
			i {
				font-size: 46px;
				color: #c0c0c0;
				width: 46px;
				height: 46px;
				display: block;
				border-radius: 25px;
				background-size: cover;
				box-shadow: inset 0 0 0 1px #00000024;
			}
		}

		.profile-name {
			padding-top: 5px;
			.text-muted {
				font-size: 12px;
			}
		}

		.post-text {
			padding: 0 18px 15px 18px;
			.highlight {
				background-color: #cce3fa;
			}
		}

		.attachments {
			padding: 0 15px 15px 15px;
			margin: 0;
		}
	}

	.rss-data-preview,
	.link-preview {
		background: #f5f5f5;

		img {
			display: block;
			width: 100%;
		}

		.text-holder {
			display: block;
			padding: 10px 18px 8px;

			.description {
				display: block;
			}
		}

		.link-section {
			display: block;

			&:hover {
				text-decoration: none;
			}
		}

		.link {
			.text-overflow();
			color: @text-muted;
			font-size: 12px;
			margin-bottom: 1px;
			display: block;
		}

		.title {
			display: block;
			font-weight: bold;
			color: #2f2f2f;
		}
	}

	footer {
		display: block;
		border-top: solid 1px @border-color;
		overflow: hidden;
	}
}
