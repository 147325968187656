.social-compliance-monitoring-settings-individuals, .social-accounts-individuals {
	.expandable {
		.expandable-icon {
			&:before {
				content: '\e806';
				font: 8px 'comergence-icons';
				color: @brand-primary;
				position: relative;
				top: -2px;
			}
		}

		&:hover {
			cursor: pointer;
			background-color: @btn-secondary-hover-bg;
		}

		&[aria-expanded='true'] {
			.expandable-icon {
				&:before {
					content: '\E804';
				}
			}
		}
	}

	@offset: 25px;
	.contact {
		padding-left: @offset;
		position: relative;
		display: flex;
		align-items: center;

		.expandable-icon {
			position: absolute;
			left: 0;
		}
	}

	.accounts {
		background-color: @btn-tertiary-hover-bg;

		.rows-bordered {
			margin-top: -8px;
			margin-bottom: -8px;

			.row-fluid {
				&:last-child {
					border-bottom: none;
				}
			}
		}

		.account {
			padding-left: @offset;
		}
	}
}
