.hmda {
	.respondent-id {
		position: relative;
		padding: 0 15px;

		.light-primary {
			padding: 15px 0;
			background: mix(#fff, @brand-primary, 90%);

			.glyphicon-remove {
				position: absolute;
				top: 10px;
				right: 4px;
				text-decoration: none;
				color: fade(@text-color, 70%);

				&:hover {
					color: @text-color;
				}
			}
		}

		margin-bottom: 30px;
	}

	.compact-grid() {
		.compact-row {
			margin-left: -5px;
			margin-right: -5px;
		}
		.compact-col {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.chart {
		.chart-filters {
			.compact-grid();
			.checkbox-inline {
				margin-right: 25px;
				& + .checkbox-inline {
					margin-left: 0;
				}
			}
		}

		h3 {
			margin: 0;
		}

		svg.chart-container {
			height: 500px;
		}
	}

	.map {
		.map-filters {
			.compact-grid();
		}
		.map-container {
			position: relative;
			width: 100%;
			height: 500px;
			.datamaps-hoverover {
				font-family: inherit;
				.hoverinfo {
					padding: 5px;
					border-radius: 2px;
					box-shadow: 1px 1px 5px #ccc;
					border: 1px solid #ddd;
				}
			}
			.datamaps-legend {
				margin: 0;
				padding: 0;
				position: absolute;
				left: 0;
				bottom: 0;
				font-size: inherit;
				font-family: inherit;
				dl {
					margin: 0;
					padding: 0;
					@item-width: 70px;
					@item-height: 25px;
					dt {
						margin: 0 0 0 -@item-width;
						padding: 0;
						width: @item-width;
						height: @item-height;
						line-height: @item-height;
						text-align: center;
						font-weight: normal;
						transform: translateX(@item-width);
					}
					dd {
						margin: 0;
						padding: 0;
						width: @item-width;
						height: @item-height;
						border-radius: 0;
						border: 1px solid #ddd;
						box-sizing: border-box;
						&:not(last-child) {
							margin-right: 1px;
						}
					}
				}
			}
		}
	}
}
