.data-match {
	.status() {
		.status {
			margin-top: 4px;
			font-size: 12px;
		}
	}
	.data-match-history {
		.status();
	}
	.data-match-upload {
		.form-control {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.droppable {
			position: relative;
			.frontface {
				padding: 10px 20px;
				min-height: 110px;
				display: flex;
				align-items: center;
				border: 1px dashed #ccc;
				border-radius: 6px;
				.file-handler() {
					.file-handler {
						margin-bottom: 0;
						input[type=file] {
							.ng-hide();
						}
						.btn {
							width: 150px;
						}
					}
				}
				.upload {
					margin: 0 auto;
					text-align: center;
					.file-handler();
					.uploading {
						position: absolute;
						top: 1px;
						right: 1px;
						left: 1px;
						bottom: 1px;
						background: #fff url(/static/images/loader-circular-primary.gif) no-repeat center;
						border-radius: 6px;
					}
				}
				.uploaded {
					width: 100%;
					.file-handler();
					.file {
						margin: 0;
						float: left;
					}
					.actions {}
				}
			}
			// TODO: verify implementation
			.backface {
				color: fade(@brand-primary, 100%);
				background: mix(@brand-primary, #fff, 20%);
				border: 1px dashed @brand-primary;
				border-radius: 6px;
				text-align: center;
				.error {
					padding: 20px 0 0;
					color: @brand-danger;
					border: 1px solid @brand-danger;
					background: mix(@brand-danger, #fff, 20%);
					border-radius: inherit;
				}
			}
		}
		.run-verification-btn {
			width: 100px;
		}
	}
	.data-match-result {
		.status();
		.match {
			margin-bottom: 40px;
		}
		.custom-rows-striped() {
			.rows-striped {
				.row-fluid {
					background-color: rgba(0, 0, 0, 0.03);
					&:not(:last-child) {
						margin-bottom: 3px;
					}
				}
			}
		}
		.statistics {
			margin-bottom: 40px;
			.custom-rows-striped();
			.row-fluid {
				&.total {
					.bg-info;
					.control-label,
					.control-value {
						font-size: 1.1em;
						font-weight: bold;
					}
				}
			}
		}
		.verification-results {
			margin-bottom: 40px;
			.custom-rows-striped();
		}
	}
	&.document-settings-modal {
		.modal-dialog {
			width: 550px;
			form {
				[form-errors],
				[form-errors] .form-error-inner {
					margin-bottom: 0;
				}
				.modal-body {
					p, section {
						margin-bottom: 15px;
					}
					ul.inactive-relationships {
						min-height: 30px; // loading indication
						margin: 0;
						padding: 0;
						list-style: none;
						li {
							display: block;
						}
					}
				}
			}
		}
	}
}
