.social-media-campaigns {

	.preview {

		.image-size {
			width: 80px;
			height:  80px;
		}

		.image-holder {
			&:extend(.post-type .preview .image-holder all);
		}

		.attachment-video {
			&:extend(.post-type .preview .attachment-video all);
		}

		.text-overflow-wrap {
			white-space: nowrap;
		}

		display: flex;
		align-items: center;
	}
}
