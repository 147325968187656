.social-compliance-publisher-post {
	&.view {
		.panel {
			.panel-body {
				.label {
					font-size: 12px;
					vertical-align: middle;
				}

				.panel-title {
					.label {
						margin-left: 10px;
					}
				}

				.accounts {
					.account-container {
						padding-top: 5px;
						padding-bottom: 5px;

						.account-status {
							font-size: 28px;
							line-height: 28px;
						}

						.text-gray {
							color: #CDCDCD;
						}

						.text-red {
							color: #ED6162;
						}

						.text-green {
							color: #61B65F;
						}
					}
				}

				.campaigns {
					margin-top: 5px;

					& .flex-row {
						align-items: flex-start;
					}

					.campaign-container {
						padding-top: 5px;
						padding-bottom: 5px;

						.icon-container {
							margin-right: 10px;

							.campaign-color {
								display: inline-block;
								width: 8px;
								height: 8px;
								border-radius: 50%;
							}
						}

						.campaign-dates {
							margin-top: 5px;
							font-size: 12px;
							color: #808080;
						}

						.campaign-status {
							margin-left: auto;
						}
					}
				}
			}
		}
	}
}
