.social-compliance-publisher-finding-task {
	&:not(.resolved) {
		min-height: 60px;
	}

	.issue {
		@issue-number-size: 21px;
		@issue-number-margin: 15px;

		&:first-of-type {
			margin-top: 15px;
		}

		.issue-number {
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: @issue-number-size;
			height: @issue-number-size;
			.text-smaller;
			font-weight: bold;
			color: white;
			background-color: @brand-danger;
			border-radius: 50%;
			text-align: center;
		}

		ul.observations {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@observation-padding: @issue-number-size + @issue-number-margin;
				padding-bottom: 10px;

				.checkbox-inline {
					padding-left: @observation-padding;
					.text-muted;

					input[type='checkbox'] {
						margin-left: 4px - @observation-padding;
					}
				}
			}
		}
	}
}
