.review-header-wrapper {
    margin-bottom: @gap-vertical;

    h2 {
        margin: 0;
    }
}

section-review {
    .flex-row;

    .condensed {
        line-height: 1.2;
    }

    .review-block {
        margin: -10px 0;
    }

    loader svg {
        display: block;
    }
}
