@import './documents.mixins.less';
@import './client-document-card/client-document-card.component.less';

.document-card {
	.document-card();

	.front-face {
		display: grid;
		grid-template-columns: minmax(0, 1fr) auto;
		grid-template-rows: repeat(2, auto);
		grid-template-areas:
			'details actions'
			'indications actions';
		grid-gap: 0 20px;

		.details {
			grid-area: details;

			.title,
			.description {
				margin: 0 0 8px;
			}
		}

		.indications {
			grid-area: indications;

			.btn {
				.c-icon {
					line-height: 14px;

					&-back-in-time {
						width: auto;
						font-size: 20px;
						top: 2px;
					}

					&-file {
						width: auto;
						font-size: 12px;
					}
				}
			}

			.last-history-action {
				margin-top: 3px;
				.text-smaller;
				.text-light;
			}

			.info-text {
				color: #808080;
				margin-top: 2.5px;
				padding-left: 25px;
				padding-right: 25px;
			}
		}

		.actions {
			@container-width: 150px;

			grid-area: actions;
			width: @container-width;

			.btn:not(.btn-icon) {
				display: block;
				width: 100px;

				&.w-auto {
					width: auto;
				}

				&.w-full {
					width: @container-width;
				}
			}

			.success {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: @brand-success;

				& > * {
					margin: 0; // reset margin

					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}

				.glyphicon {
					font-size: 28px;
				}
			}
		}

		.file-input {
			.file-input();
		}
	}
}

.kill-padding-left {
	padding-left: 0 !important;
}

.small-document-card {
	.document-card(70px);

	.front-face {
		.title {
			font-size: 16px;
			word-wrap: break-word;
			white-space: normal;

			.c-icon {
				&-file {
					width: auto;
					font-size: 12px;
				}
			}
		}

		.file-input {
			.file-input();
		}
	}
}
