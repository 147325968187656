.questionnaire-documents {
	.add-documents {
		background: #F3F9FF;
		padding: 10px;

		ng-select {
			width: 500px;
			margin-right: 10px;
		}
	}

	.no-records {
		border-top: 1px solid @border-grey;
	}
}
