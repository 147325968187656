@import './document/details/details.component.less';

.lender-documents {
  .tab-menu {
    .icon-alert {
      position: relative;
      top: 3px;
      right: 9px;
    }
  }
}

channels-schedule-settings {
    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
}