.company-pages-modal {
	.modal-body {
		p {
			margin-bottom: 25px;
		}

		.rows-bordered {
			margin: 0;

			> .row {
				padding: 5px 0;

				&, label {
					.text-normal;
				}

				&.header {
					padding: 0;

					label {
						padding: 0;
						margin: 0;
					}
				}
			}
		}

		.horizontal-flex-group {
			.nowrap {
				white-space: nowrap;
			}

			.full-width {
				overflow: hidden;
				text-overflow: ellipsis;
				.nowrap;
			}

			.page-select {
				margin: 0 15px 0 10px;
			}
		}
	}
}
