facebook-review {
	.recommend {
		color: #F26B7F;
	}

	.not-recommend {
		color: #9B9B9B;
	}

	.review-text ~ review-comments {
		margin-top: 20px;
	}
}
