// transparent labels (tertiary)
.label-danger-wired {
    .label-wireframe(@brand-danger);
}

.label-success-wired {
    .label-wireframe(@brand-success);
}

.label-warning-wired {
    .label-wireframe(@brand-warning);
}

.label-default-wired {
    .label-wireframe(@brand-grey);
}

.label-primary {
    .label-light(@brand-primary);
}

.label-primary-wired {
    .label-wireframe(@brand-primary);
}

.label-tertiary {
    color: @text-color;
    background-color: @btn-tertiary-hover-bg;
    //background-color: @btn-tertiary-active-bg;
}
