.notifications {
	.columns-wrapper {
		h4 {
			font-size: 17px;
			line-height: 40px;
			span {
				color: @text-muted;
			}
			border-bottom: 1px solid @brand-grey;
		}
	}

	.line-space {
		padding-bottom: 10px;
	}

	.separator {
		clear: both;
	}

	.user-list {
		list-style-type: none;
		padding: 0px;
		margin: 0px;
	}

	.remove-user {
		color:  @nav-panel-link-active;
		cursor: pointer;
	}

	.off-notifications {
		font-weight: bold;
		color: @text-muted;
		font-size: 18px;
		line-height: 20px;
	}

	.cancel-select {
		margin-top: 3px;
		display: inline-block;
	}

	.glyphicon-envelope {
		color: #b4b4b4;
	}
}