contact-list-contacts-list {
    .red {
        color: red;
        padding-right: 10px;
    }

    .green {
        color: green;
        padding-right: 10px;
    }
}
