.global-footer {
	height: @footer-height;
	padding: 15px;
	font-size: 11px;
	color: @text-muted;
	background: #f6f6f6;
	box-shadow: inset 0 1px 0 #ddd;
	overflow: hidden;

	nav {
		float: right;
		ul {
			list-style-type: none;
			li {
				float: left;
				padding-right: 20px;
				a {
					color: @text-muted;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
