.recurly-hosted-field {
    .form-control;
    padding: 0;

    @border-color: @brand-danger;
    .has-error & {
        border-color: @border-color;
        .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work

        &:focus {
            border-color: darken(@border-color, 10%);
            @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
            .box-shadow(@shadow);
        }
    }
}

.recurly-hosted-field-card {
    height: @input-height-base + 2;
}

.recurly-hosted-field-invalid {
    border-color: @brand-red;
}
