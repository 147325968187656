.remediation {
	.issues {
		padding-top: 5px;

		.issue {
			padding-bottom: 5px;
		}
	}

	.cell-align {
		padding-left: 45px;

		.c-icon-phone {
			float: left;
			margin-left: -30px;
			margin-top: 3px;
		}
	}

	h3 {
		.v-align {
			top: 5px;
		}

		.c-icon-phone {
			top: 0;
			font-size: 17px;
		}
	}
}
