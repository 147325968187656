.products-subscription {
    .container {
        display: flex;
        justify-content: space-between;

    }
    .column {
        flex: 1;
        padding: 10px;
        box-sizing: border-box;
    }
    .inner-column {
        margin-left: -25px;
    }
    .right-column {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .sub-button {
        width: 100%;
    }
    .charge-amount-container {
        width: 75%;
        margin-top: 10px;
    }
    .payments-method-selector {
        .ng-value {
            width: 100%;
        }
        .ng-dropdown-panel {
            .ng-dropdown-panel-items {
                max-height: 280px;
                .ng-option {
                    padding: 6px 10px;
                }
            }
        }
    }
    .column-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
}
