.social-accounts-inventory {
	.export {
		text-decoration: none;
		cursor: pointer;
		color: #808080;
		height: 30px;
		width: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		&:hover, &:active {
			background: #f4f5f7;
		}

		.export-tooltip {
			visibility: hidden;
			@height: 100px;
			width: 300px;
			height: @height;
			border: solid 1px #CCC;
			border-radius: 3px;
			padding: 10px 12px;
			background: white;
			position: absolute;
			bottom: -@height;
			right: 0;
			z-index: 1;

			div {
				display: flex;
			}

			.c-icon-user-gear {
				font-size: 22px;
			}
		}

		&.processing:hover .export-tooltip {
			visibility: visible;
		}
	}

	.url {
		white-space: nowrap;
		overflow: hidden;
		padding: 5px;
		text-overflow: ellipsis;

		&.blue {
			color: #66A1DD;
		}
	}

	sm-icon {
		@icon-size: 25px;

		i {
			font-size: @icon-size;
		}

		.title {
			line-height: @icon-size;
		}
	}
}
