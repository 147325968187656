.company{
	h2 > span{
		color: @text-muted;
	}
}

.licenses {
	.button{
		padding: 0 30px;
		min-height: 20px;
		height: auto;
	}
	.top-margin {
		margin-top: 8px;
	}
}
