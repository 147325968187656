.edit-widget {
  @checkbox-size: 17px;

  #sources.remote-resource:after {
    content: 'Fetching your data';
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    font-weight: bold;
  }

  .col-f-40-custom {
    width: 40px;
    display: table-cell;
  }

  input[type=checkbox] {
    width: @checkbox-size;
    height: @checkbox-size;
  }

  .btn-group {
    padding: 0;

    .dropdown-toggle {
      padding: 7px 0 7px 10px;
    }
  }
}
