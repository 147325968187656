.bs-datepicker-container {
	padding: 0;
	.bs-datepicker-head {
		text-align: center;
		background-color: @brand-primary;
	}
	.bs-datepicker-body {
		table {
			td {
				&.week {
					span {
						color: @brand-primary;
					}
				}
				span {
					&.selected {
						background-color: @brand-primary;
					}
				}
			}
		}
	}

    .bs-datepicker-buttons {
        padding: 8px;
    }

    .btn-today-wrapper .btn-success, .btn-clear-wrapper .btn-success {
        .btn .btn(@btn-secondary-color, @btn-secondary-bg, @btn-secondary-hover-bg, @btn-secondary-active-bg, @btn-secondary-border);

        &:not(:disabled):not(.disabled):active {
            background-color: @btn-secondary-active-bg;
            border-color: @btn-secondary-active-bg;
        }
    }
}

.form-control[bsDatepicker] {
	background: #fff data-uri('/static/images/datepicker.png') 100% 50% no-repeat;
}
