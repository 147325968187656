sm-network-icon {
	@size: 40px;

	display: block;
	width: @size;

	.sm-network-icon {
		&-container {
			position: relative;
		}

		&-icon {
			font-size: @size;
			border-radius: 50%;
		}

		&-badge {
			position: absolute;
			top: auto;
			right: -9px;
			bottom: 0;
			left: auto;
			font-size: 18px !important;
			text-shadow: 1px 0 #FFF, 0 1px #FFF, -1px 0 #FFF, 0 -1px #FFF;
			background-color: #FFF;
			border-radius: 50%;
		}
	}

	&.minified {
		@size: 26px;

		display: block;
		width: @size;

		.sm-network-icon {
			&-icon {
				font-size: @size;
			}

			&-badge {
				right: -7px;
				font-size: 14px !important;
			}
		}
	}

	&.show-title {
		.flex-row;
		.gap;
		width: auto;
	}

	.icon-circled {
		font-size: 10px !important; //override the important
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-shadow: none;
		background-color: #63A0DF;
		color: white;
		box-shadow: 1px 0 #FFF, 0 1px #FFF, -1px 0 #FFF, 0 -1px #FFF;

		&:before {
			margin-bottom: -2px;
			margin-right: -1px;
		}
	}
}
