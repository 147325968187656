.gmb-locations-modal {
	.modal-body {
		.rows-bordered > .row.header {
			padding: 0;

			label {
				padding: 0 5px;
				margin: 0;
			}
		}

		label {
			cursor: pointer;
			.text-normal;
		}

		.horizontal-flex-group {
			.nowrap {
				white-space: nowrap;
			}

			.full-width {
				overflow: hidden;
				text-overflow: ellipsis;
				.nowrap;
			}

			.location-checkbox {
				margin: 0 15px 0 10px;
			}
		}
	}
}
