@import "./full-page-layout.common.less";

full-page-layout {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 'layout-header' 'layout-body';

	.full-page-header {
		@height: 52px;

		height: @height;
		grid-area: layout-header;
		display: flex;
		align-items: center;
		color: #333;
		background-color: #fff;
		border: solid 1px rgba(0, 0, 0, 0.01);
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
		z-index: 1030;
		.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 20px;
			.full-page-layout-header-font();
			line-height: @height;
			color: #66A1DD;
			opacity: 1;
			&:hover {
				opacity: .8;
			}
			.glyphicon {
				margin-right: 0;
			}
		}
	}
	.content {
		grid-area: layout-body;
		display: flex;
		background-color: #f3f5f7;
	}
}

.full-page-view .container.main-wrapper {
    padding: 0;
}
