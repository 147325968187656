.application {
    &.remote-resource {
        display: block;
    }

	@text-warning: #A57100;
	.text-warning {
		margin: 10px 0 2px;
		color: @text-warning;
	}

    .contact-new-header {
        text-align: center;
    }

	h3.underlined {
		font-weight: bold;
		color: @brand-primary-dark;
		border-bottom: 3px solid @brand-primary-dark;
		padding: 10px 0;
		line-height: 34px;
	}
	.print-button {
		width: 120px;
		float: right;
		text-align: right;
		margin-bottom: 1px;
		span {
			font-size: 26px;
			vertical-align: text-bottom;
		}
	}
	.common-progress > div {
		padding-block: 25px;
	}
	.well {
		padding: 15px 30px;
		background: fade(#ebc300, 10%);
		color: @text-warning;

		p:last-child {
			margin-bottom: 0;
		}
	}

	.company-info {
		margin-top: -10px;
		margin-bottom: -20px;
		.logo {
			display: inline-block;
			width: 65px;
			line-height: 65px;
			img {
				display: inline;
			}
			margin-right: 10px;
		}
		.info {
			display: inline-block;
			text-align: left;
			vertical-align: middle;
		}
		h3.lender {
			font-size: 18px;
			margin: 0;
		}
		small.channel {
			font-size: 14px;
			color: @brand-disabled;
		}
	}

	.rows-striped .expiration {
		display: inline-block;
		width: 80px;
		min-width: 80px;
		margin-right: 5px;
	}
	.rows-striped .year.expiration {
		margin-left: 5px;
	}
	hr.or {
		border-top: none;
		border-bottom: 3px solid @brand-primary-dark;
		position: relative;
		&::before {
			content: "or";
			position: absolute;
			left: 49.5%;
			z-index: 10;
			display: block;
			top: -20px;
			background-color: white;
			padding: 5px;
			font-size: 21px;
			font-weight: bold;
			color: @brand-primary-dark;
		}
	}

	h3.headline {
		font-weight: bold;
		color: @brand-primary-dark;
		padding: 10px 0;
		line-height: 34px;
	}

	.common-progress .nav-pills > li {
		padding-left: 20px;
		a {
			margin-right: 10px;
		}
	}
	.rows-bordered {
		.row.form-group {
			margin-bottom: 0;
		}

		.not-preffered {
			background: rgba(235, 195, 0, 0.1);
		}
	}

	.counter .row {
		@count-width: 50px;
		padding-left: @count-width;

		.countee:before {
			width: @count-width - 20;
			float: left;
			margin-left: -@count-width;
			text-align: right;
			font-size: 16px;
			font-weight: 600;
		}

		&.group {
			padding: 0;
			margin-top: -1px;
			background: #fff;
		}
	}



	.payment {
		text-align: right;

		.done {
			width: 100px;
			line-height: 42px;
			margin-left: 15px;
			color: @brand-green;
			span {
				display: inline-block;
				background-color: green;
				border-radius: 10px;
				color: white;
				width: 20px;
				height: 20px;
				text-align: center;
				vertical-align: text-bottom;
				font-size: 14px;
			}
			span:before {
				vertical-align: sub;
			}
		}
	}
	#paymentInfo {
		small {
			line-height: 16px;
			display: inherit;
		}
		.required-block-lg {
			margin-top: 50px;
		}
	}

	.payment.isPaying .payBtn {
		background-image: url('/static/images/spiffygif_30x30.gif');
		background-repeat: no-repeat;
		background-position: center;
		text-indent: -100000px;
		background-color: #75b8fc;
		border-color: #75b8fc;
	}
	.payBtn {
		width: 100px;
		margin-left: 15px;
	}
	.company-info {
		padding-right: 30px;
		h3 {
			font-size: 20px;
		}
		small {
			font-size: 16px;
		}
	}
	.documents {
		.alert {
			margin-top: 6px;
			margin-bottom: 40px;
		}
		ul.files {
			padding-bottom: 1px;
		}
	}
}

.app-code-payment {
	button.btn-sm {
		padding: 3px 15px
	}
}

.ordered-group {
	.counter {
		width: 40px;
		text-align: right;
		padding-right: 15px;
		float: left;
		font-size: 1.2em;
		color: #000000;
		font-weight: bold;
	}

	.counter ~ div {
		overflow: hidden;
		vertical-align: middle;
	}

	.radio {
		padding-left: 20px;
	}

	.rows-bordered > .row& {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.form-control {
		+ .form-control-addon {
			display: inline-block;
			position: absolute;
		}

		&.form-control-USD {
			padding-left: 20px;

			+ .form-control-addon {
				width: 15px;
				text-align: right;
				line-height: @input-height-base;
				top: 0;
				z-index: 10;

				&:before {
					content: "$";
				}
			}
		}

		&.form-control-PCT {
			padding-right: 20px;

			+ .form-control-addon {
				width: 15px;
				text-align: left;
				line-height: @input-height-base;
				top: 0;
				left: 100%;
				z-index: 10;
				margin-left: -35px;

				&:before {
					content: "%";
				}
			}
		}
	}
}
