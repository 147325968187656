.landing-page {
	@column-gap: 30px;
	@row-gap: 15px;

	&-grid-container {
		margin-top: 4em;
		display: grid;
		grid-template-rows: repeat(3, auto);
		grid-column-gap: @column-gap;
		grid-row-gap: @row-gap;
		-ms-grid-columns: 1fr @column-gap 1fr @column-gap 1fr;
		-ms-grid-rows: auto @row-gap auto @row-gap auto;

		.icon-container {
			position: relative;

            .material {
                display: flex;
                width: 160px;
                height: 160px;
                align-items: center;
                justify-content: center;
                background: #F5F5F5;
                margin: 0 auto;
                border-radius: 50%;
                color: red;

                img {
                    @size: 72px;
                    width: @size;
                    height: @size;
                    filter: invert(64%) sepia(23%) saturate(941%) hue-rotate(171deg) brightness(90%) contrast(91%);
                }
            }

			.label {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 50%);
			}
		}

		.generate(@name, @i) when (@i > 0) {
			.@{name}-@{i} {
				grid-@{name}: @i;
				-ms-grid-@{name}: @i * 2 - 1;
			}

			.generate(@name, @i - 1);
		}

		.generate(column, 3);

		.generate(row, 3);
	}
}
