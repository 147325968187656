.market-map-new {
    position: fixed;
    top: @header-height + @gray-head-height;
    right: 0;
    bottom: @footer-height;
    left: 0;

    display: flex;
    flex-direction: column;

    > * {
        flex: 0 0;
    }

    .header-buttons {
        @buttons-height: 30px;

        position: absolute;
        top: (-(@gray-head-height + @buttons-height)/2);
        right: 30px;
    }

    .map-filters {
        margin: 30px 30px 10px;
    }

    .market-map-container {
        flex: 1 1;

        .map-info-window {
            display: block;
            width: 250px;
            min-height: 100px;
            overflow: hidden;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-size: 14px;
            .info-window-header {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid fade(#000, 15%);
                .logo {
                    @size: 16px;
                    width: @size;
                    height: @size;
                    float: left;
                    margin-right: 8px;
                    background-image: url("/static/images/optimalblue/ob-logo.png");
                    background-size: contain;
                }
            }
            .info-window-header,
            .info-window-body {
                & > * {
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }
            h1,
            h2 {
                margin: 0;
            }
            h1 {
                .text-bigger;
            }
            h2 {
                .text-medium;
            }
            .nmls-id {
                .text-muted;
                .text-smaller;
            }
        }
        .map-legend {
            position: absolute;
            bottom: 30px;
            left: 10px;
            width: 200px;
            .panel {
                margin: 0;
                padding: 0;
                .panel-heading {
                    background-color: transparent;
                    cursor: pointer;
                    &.collapsed {
                        border-bottom: none;
                        .caret {
                            transform: rotate(180deg)
                        }
                    }
                }
                .panel-body {
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li {
                            margin-bottom: 5px;
                            .list-icon () {
                                width: 16px;
                                text-align: center;
                            }
                            &.relationships {
                                .icon {
                                    display: inline-block;
                                    .list-icon();
                                    &:before {
                                        @size: 10px;
                                        content: '';
                                        display: inline-block;
                                        width: @size;
                                        height: @size;
                                        background-color: red;
                                        border-radius: 50%;
                                    }
                                }
                            }
                            &.prospect {
                                .icon {
                                    .glyphicon;
                                    .glyphicon-star;
                                    .list-icon();
                                    .text-muted;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
