.card-payment {

	.rows-striped .expiration {
		display: inline-block;
		width: 80px;
		min-width: 80px;
		margin-right: 5px;
	}
	.rows-striped .year.expiration {
		margin-left: 5px;
	}
	hr.or {
		border-top: none;
		border-bottom: 3px solid @brand-primary-dark;
		position: relative;
		&::before {
			content: "or";
			position: absolute;
			left: 49.5%;
			z-index: 10;
			display: block;
			top: -20px;
			background-color: white;
			padding: 5px;
			font-size: 21px;
			font-weight: bold;
			color: @brand-primary-dark;
		}
	}

	.payment {
		border: 4px solid #9AA7B5;
		text-align: right;
		padding: 15px;
		span {
			font-size: 16px;
		}
		span:first-child {
			color: #aaa;
		}
		.done {
			width: 100px;
			line-height: 42px;
			margin-left: 15px;
			color: @brand-green;
			span {
				display: inline-block;
				background-color: green;
				border-radius: 10px;
				color: white;
				width: 20px;
				height: 20px;
				text-align: center;
				vertical-align: text-bottom;
				font-size: 14px;
			}
			span:before {
				vertical-align: sub;
			}
		}
	}
	#paymentInfo {
		small {
			line-height: 16px;
			display: inherit;
		}
		.required-block-lg {
			margin-top: 50px;
		}
	}

	.payment.isPaying .payBtn {
		background-image: url('/static/images/spiffygif_30x30.gif');
		background-repeat: no-repeat;
		background-position: center;
		text-indent: -100000px;
		background-color: #75b8fc;
	}
	.payBtn {
		min-width: 100px;
		margin-left: 15px;
	}
}

.fee {
	&:extend(.card-payment .payment);
	span {
		&:extend(.card-payment .payment span);
	}
}

