sm-subscription {

    .border-blocks {
        gap: 60px;
        align-items: stretch;

        > .flex-grow {
            border: 1px solid @brand-border-light;
            border-radius: 4px;
            padding: 20px 30px;
        }

        ul.marked {
            padding-left: 16px;

            li {
                margin: 5px 0;
                padding-inline-start: 1ch;

                &::marker {
                    color: @brand-success;
                    font-family: 'Glyphicons Halflings';
                    font-size: 16px;
                    content: "\e013";
                }
            }
       }

        h3 {
            margin: 30px 0 10px;
        }
    }
}
