.social-media-post-comments {
	padding: 24px;
	background: #F5F5F7;
	clear: both;

	.sub-comments {
		padding-left: 25px;
	}

	.social-media-post-attachments {
		img {
			max-height: 200px;
		}
	}

	hr {
		margin: 10px 0;
		border-top: 1px solid #E4E6EA;
	}

	//  FIXME: legacy styles
	//.subComment {
	//	border-left: 2px solid #D8D8D8;
	//	padding-left: 15px;
	//}
	//
	//.attachments {
	//	display: flex;
	//	flex-wrap: wrap;
	//
	//	span {
	//		img {
	//			&.sticker {
	//				max-height: 80px;
	//			}
	//		}
	//	}
	//}
}
