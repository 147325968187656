@sidenav-switcher-gap: 30px;
@sidenav-switcher-size: 24px;
@sidenav-padding: 15px;
@sidenav-content-padding: 30px;
@sidenav-width: 270px;
@sidenav-width-narrow: @menu-width-tight;
@sidenav-background-color: @brand-primary;
@sidenav-line-height: 27px;
@sidenav-font-size: 15px;
@sidenav-text-color: #fff;
@sidenav-submenu-text-color: @sidenav-text-color;
@sidenav-active-text-color: @brand-primary;
@sidenav-active-background-color: #fff;
@sidenav-icon-font-size: 8px;
@sidenav-icon-color: @sidenav-text-color;
@sidenav-outer-border-radius: 5px;
@sidenav-inner-border-radius: 3px;
@sidenav-dura: 0.3s;
@sidenav-border-color: fade(#fff, 20%);

.side-menu-element {
    display: flex;
    width: 100%;
    min-height: @min-height-full;
    margin-bottom: -@gap-vertical;
}

.sidenav-container {
    display: flex;
    width: 100%;
    position: relative;

    .sidenav {
        flex: 0 0 auto;
        color: @sidenav-text-color;
        width: @sidenav-width;
        margin-left: -@sidenav-content-padding;
        margin-right: @sidenav-content-padding;
        background-color: @sidenav-background-color;
        transition: margin-left @sidenav-dura ease-in-out, transform @sidenav-dura ease-in-out;
        z-index: 1000;

        &-header {
            flex: 1 1 auto;
            min-height: 40px;
            overflow: hidden;
        }

        &-header-container {
            display: flex;
            align-items: stretch;
            width: 100%;
            border-bottom: 1px solid @sidenav-border-color;
        }

        &-switcher {
            @padding: 10px;
            flex: 0 0;
            color: #666;
            outline: none;
            width: min-content;
            padding-inline: 10px;
            display: flex;
            align-items: center;
            margin-right: (-@sidenav-switcher-size/2) - @padding;
            text-decoration: none;
            user-select: none;

            .c-icon {
                height: @sidenav-switcher-size;
                width: @sidenav-switcher-size;
                line-height: @sidenav-switcher-size;
                font-size: @sidenav-icon-font-size;
                border-radius: 50%;
                background: #fff;
                text-align: center;
                display: block;
                box-shadow: ~"0 0 0 1px rgb(0 0 0 / 5%), 0 2px 4px rgb(0 0 0 / 17%) ";
                transform: scaleX(-1);
                opacity: 0;
                transition: opacity @sidenav-dura linear;

                &:before {
                    margin-left: 2px;
                }
            }
        }

        &:hover {
            .sidenav-switcher {
                .c-icon {
                    opacity: 1;
                }
            }
        }

        &-menu, &-header {
            transition: opacity @sidenav-dura linear;
        }

        &-menu {
            display: block;
            padding: @sidenav-padding;
            width: @sidenav-width;
            font-size: @sidenav-font-size;


            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li.sidenav-menu-item {
                    position: relative;
                    line-height: 43px;

                    a {
                        &:hover {
                            color: @sidenav-text-color;
                        }

                        .icon-alert {
                            flex: 0 0 28px;
                        }
                    }

                    a, .not-link {
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        width: 100%;
                        text-decoration: none;
                        border-radius: @sidenav-inner-border-radius;
                        color: fade(@sidenav-text-color, 70%);

                        > * {
                            flex: 1;
                        }

                        .shrink {
                            flex: 0 1;
                        }

                        .channel-badge {
                            white-space: nowrap;
                            padding-right: 10px;
                            align-self: center;
                        }

                        loader[size=toggle] {
                            height: 20px;
                        }

                        &:before {
                            content: '';
                            display: block;
                            flex: 0 0 @sidenav-content-padding;
                        }
                    }


                    &.active:not(.custom-inactive),
                    &.custom-active {
                        background-color: @sidenav-active-background-color;
                        border-radius: @sidenav-inner-border-radius;

                        > a {
                            color: @sidenav-active-text-color;

                            &:hover, &:active {
                                background-color: transparent;
                            }
                        }
                    }

                    &.submenu {
                        > a {
                            &::before {
                                content: '\e806';
                                font-family: 'comergence-icons';
                                text-align: center;
                                font-size: @sidenav-icon-font-size;
                                color: @sidenav-icon-color;
                            }
                        }

                        ul {
                            display: none;
                        }

                        &.active:not(.custom-inactive),
                        &.custom-active {
                            a {
                                color: fade(@sidenav-active-text-color, 70%);

                                &:hover {
                                    color: @sidenav-active-text-color;
                                    background-color: transparent;
                                }
                            }

                            > a {
                                color: @sidenav-active-text-color;

                                &::before {
                                    content: '\e804';
                                    color: @sidenav-active-text-color;
                                }
                            }

                            ul {

                                display: block;
                                margin-top: -6px;
                                padding-bottom: 6px;


                                li {
                                    &.active:not(.custom-inactive),
                                    &.custom-active {
                                        a {
                                            color: @sidenav-active-text-color;
                                        }
                                    }

                                    &.sidenav-menu-item {
                                        font-size: @sidenav-font-size - 1px;
                                        line-height: @sidenav-line-height;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > ul > li > a {
                &:hover {
                    background-color: darken(@brand-primary, 3%);
                }

                &:active {
                    background-color: darken(@brand-primary, 5%);
                }
            }
        }
    }

    .sidenav + .content {
        flex: 1 1 auto;
        margin-block: 20px;
        width: ~"calc(100% - @{sidenav-width})";

        .gray-head {
            margin-top: -20px;
        }
    }

    &.narrow {
        .sidenav {
            ul.sidenav-menu {
                width: @sidenav-width-narrow;
            }
        }
    }
}

@media (max-width: @screen-md-max) {
    .sidenav-container {
        .sidenav {
            margin-left: -(@sidenav-width + @sidenav-content-padding) + @sidenav-switcher-gap;

            &-switcher {
                &.visible-switcher {
                    display: flex;

                    .c-icon {
                        opacity: 1;
                        transform: scaleX(1);
                    }
                }

                &.expanded-switcher {
                    display: none;
                }
            }

            &-menu, &-header {
                opacity: 0
            }
        }

        &.visible {
            .sidenav {
                transform: translateX(@sidenav-width - @sidenav-switcher-gap);

                &-switcher.visible-switcher {
                    .c-icon {
                        transform: scaleX(-1);
                    }
                }

                &-menu, &-header {
                    opacity: 1;
                }
            }
        }
    }
}

@media (min-width: @screen-lg-min) {
    .sidenav-container {

        .sidenav {

            &-switcher {
                &.visible-switcher {
                    display: none;
                }

                &.expanded-switcher {
                    display: flex;
                }
            }
        }

        &.narrow {
            padding-left: @sidenav-width-narrow;

            .sidenav {
                margin-left: -@sidenav-width-narrow;
            }
        }

        &.collapsed {
            padding-left: 0;

            .sidenav {
                margin-left: -(@sidenav-width+@sidenav-content-padding) + @sidenav-switcher-gap;

                &-switcher {
                    &.expanded-switcher {
                        .c-icon {
                            transform: scaleX(1);
                            opacity: 1;
                        }
                    }
                }

                &-menu, &-header {
                    opacity: 0;
                }
            }
        }
    }
}
