.home{
	margin-top: @gap-vertical;

	.well {
		padding: 10px;
		background: fade(#ebc300, 10%);
		color: #BFA892;

		p:last-child {
			margin-bottom: 0;
		}
	}

	.pending-application-holder,
	.client-assigned-questionnaires-holder {
		padding: 0 15px;
		min-height: 120px;

		&.resolved {
			min-height: 0;
		}
	}

	.pending-application,
	.client-assigned-questionnaires {
		display: flex;
		border: 1px solid #ccc;
		border-radius: 5px;
		//min-height: 248px;
		margin-bottom: 20px;
	}

	.application-status,
	.client-assigned-questionnaires-status {
		// flex: 0 0 170px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: @brand-grey-bkg;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;


		a, button {
			width: 100%;
		}

		.status {
			text-align: center;
			margin-bottom: 10px;
			min-height: 80px;

			> span{
				color: @text-muted;
				font-size: 32px;
			}

			h6 {
				font-weight: bold;
				font-size: 16px;
				margin-top: 7px;
				margin-bottom: 5px;
			}

			.expired {
				color: @text-muted;
				font-size: 12px;

				a {
					cursor: default;
					text-decoration: underline;
					color: @brand-red;
				}
			}
			&+ div + div .btn{
				margin-top: 10px;
			}
		}

		.upload-documents-button {
			margin-top: 10px;
		}
	}

	.client-assigned-questionnaires-tbd-holder,
	.application-tbd-holder {
		padding: 0;
		display: flex;

		> div {
			width: 100%;
		}

		.client-assigned-questionnaires-tbd,
		.application-tbd {
			width: calc(~"100% - 200px");
		}

		.client-assigned-questionnaires-status,
		.application-status {
			padding: 15px;
			width: 200px;
			height: 100%;
			position: relative;
		}
	}

	.application-tbd,
	.client-assigned-questionnaires-tbd {
		padding: 15px;
	}

	.application-info,
	.client-assigned-questionnaires-info {
		padding: 15px;
		//min-height: 246px;
		border-right: 1px solid #ccc;
		.logo{
			line-height: 65px;
			img{
				display: inline;
			}
		}
		h3 {
			font-size: 20px;
			margin-bottom: 0;
		}
		small {
			font-size: 15px;
			color: @text-muted;
		}
		small + div{
			margin-top: 15px;
		}
		.row {
			margin-bottom: 6px;
			div + div{
				padding-left: 0;
			}
		}
	}
}

.verify-ownership {
	.warning {
		display: flex;
		margin: -20px -20px 0 -20px;
		padding: 20px;
		font-size: 12px;
		line-height: 14px;
		background-color: @brand-well;
		color: @brand-well-text;
		border-bottom: 1px solid fade(@brand-well-text, 10%);
		justify-content: flex-start;

		.glyphicon {
			top: 0;
			font-size: 33px;
			color: @brand-well-text;
			margin: 0 20px 0 0;
		}
	}

	.modal &.modal-new .modal-body .alert {
		margin: 0 -20px;
	}

	.your-information {
		.row {
			margin: 8px 0;
		}
	}

	.modal-body + .modal-footer {
		padding: 20px;
		background-color: @brand-grey-bkg;
		margin-top: -5px;
		border-radius: 0 0 4px 4px;

		div {
			font-size: 14px;

		}

		span.glyphicon {
			color: @brand-grey;
		}

		div:first-child {
			font-size: 16px;
			padding-left: 0;
			color: @text-muted;
		}

		.contacts {
			margin-top: 7px;
			color: #000;
		}
	}
}

.invite-application {
	background: fade(@brand-primary, 20%);
	margin-bottom: 20px;
	padding: 20px 15px 20px 30px;
	border-radius: 5px;

	.title {
		.text-primary;
	}

	.info {
		font-size: 13px;

		& > * {
			float: left;
			margin-top: 10px;

			&:not(:last-child) {
				margin-right: @grid-gutter-width
			}
		}
	}

	.control-label {
		color: #666;
	}

	.remote-resource::after {
		background-color: fade(mix(@brand-primary, #FFF, 20%), 75%);
	}
}

.logo-sm {
	width: 21px;
	height: 21px;
}

.lender-home-dashboard {
	@height: 500px;
	@spacing: 30px;
	@bg-color: #f2f2f2;

	display: table;
	width: 100%;
	height: @height;
	empty-cells: show;
	table-layout: fixed;
//	border-spacing: @spacing;
	margin-top: @spacing;

	.block-link {
		color: #F30;
		line-height: 1.1;

		/*&:hover {
			color: darken(#F30, 10%);
		}*/
	}

	.left-blocks, .right-block {
		display: table-cell;
		border-spacing: 0;
	}

	.left-block, .right-block {
		background: @bg-color;
		position: relative;
		vertical-align: top;
		height: @height;
	}

	.left-block {
		padding: 15px 20px;
		height: ((@height - @spacing) / 2);
		margin-top: @spacing;
		border-right: @spacing solid #fff;

		&:first-child {
			margin-top: 0;
		}

		.number {
			font-size: 34px;
			font-weight: bold;
		}

		.dimension {
			font-size: 20px;
		}

		.title {
			display: block;
			position: absolute;
			bottom: 15px;
		}
	}

	.right-block {
		box-sizing: border-box;
		padding: 30px;
		width: 82.5%;
		@dura: 0.5s;

		&.remote-resource:after {
			background-color: fade(@bg-color, 75%);
		}

		.graph {
			position: absolute;
			width: calc(~"100% - 60px");
			height: 360px;
			bottom: 30px;
			//background: #ddd;
			display: table;
			table-layout: fixed;

			&.months-6 {
				.month:nth-last-child(-n + 6) {
					width: (100% / 6);
				}
				.month:nth-last-child(n + 7) {
					width: 0%;
					opacity: 0;
				}
			}

			&.months-3 {
				.month:nth-last-child(-n + 3) {
					width: (100% / 3);
				}
				.month:nth-last-child(n + 4) {
					width: 0%;
					opacity: 0;
				}
			}

			.month {
				display: table-cell;
				text-align: center;
				vertical-align: bottom;
				overflow: hidden;
				transition: width @dura, opacity @dura;
				opacity: 1;
				white-space: nowrap;

				.name {
					padding-top: 20px;
					height: 40px;
				}

				.bars {
					white-space: nowrap;
					word-wrap: normal;

					.bar {
						margin: 0 2px;
						display: inline-block;
						vertical-align: bottom;
						width: 43px;

						.title {
							white-space: nowrap;
							font-size: 12px;
							margin-bottom: 2px;
						}

						.count {
							box-sizing: content-box;
							color: #FFF;
							background: #999;
							position: relative;
							border-bottom: 40px solid #999;
							font-weight: bold;
							font-size: 18px;
							transition: height @dura, border @dura, margin @dura;

							&:before {
								content: attr(value);
								position: absolute;
								display: block;
								color: #fff;
								left: 0;
								bottom: -30px;
								height: 20px;
								width: 100%;
								overflow: hidden;
								z-index: 1;
								transition: bottom @dura, color @dura;
							}

							&.new {
								background: @brand-success;
								border-bottom-color: @brand-success;
							}

							&[value="0"] {
								border-bottom-width: 3px;
								margin-top: 27px;

								&:before {
									color: #999;
									bottom: 7px;
								}

								&.new:before {
									color: @brand-success;
								}
							}
						}
					}
				}
			}
		}
	}
}

home-share-requests, tpo-home-share-requests {
    display: block;
    margin-bottom: 20px;

    // Hide when empty
    &.resolved:empty {
        display: none;
    }
}

.gray-well {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid @border-grey;
    background-color: @brand-grey-bkg;
    border-radius: 4px;
}

@import "@{src}lender/home/sections/info-block/investor-info-block.less";
