post {
	instagram-post.post-content {
		font-size: 13px;

		.post-row {
			padding: 10px 15px;

			.profile-image i {
				font-size: 28px;
				width: 28px;
				height: 28px;
				box-shadow: none;
			}

			img {
				transform: rotate(90deg);
				width: 15px;
				margin-left: auto;
			}
		}

		.attachments {
			padding: 0;
			img {
				margin: auto;
			}
		}

		footer {
			padding: 14px;
			border-top: none;

			.icon-bar {
				display: flex;

				img {
					height: 20px;
					margin-right: 17px;

					&:last-child {
						height: 18px;
						margin-left: auto;
						margin-right: 0;
					}
				}
			}

			.post-text {
				display: flex;
				padding: 11px 0 0;
			}

			.add-comment {
				display: flex;
				align-items: center;
				padding-top: 9px;

				i {
					margin-right: 10px;
					font-size: 25px;
					width: 25px;
					height: 25px;
					border-radius: 25px;
					background-size: cover;
				}

				img {
					margin-left: auto;
				}
			}

			small {
				font-size: 9px;
			}
		}

		.account-name {
			color: #262626;
		}

		.muted-light {
			color: #A5A5A5;
		}
	}
}
