.light-blue {
	background: #f5f8fa;
}

.social-compliance {
	@import "@{src}shared/social-media/landing-page/landing-page.component.less";

	.table-icon {
		position: relative;
		top: 3px;
	}

	.finding-tabs {
		min-width: 292px; //@Notice: this is a temporary fix, discussed with PM
	}

	.monitoring-board {
		.id-holder-cell {
			position: relative;
			margin-left: 50px;

			.id-holder {
				position: absolute;
				top: 3px;
				left: -50px;
			}
		}

		.monitoring-source {
			display: block;
			color: @text-color;
			line-height: 20px;

			.c-icon, .glyphicon {
				margin-right: 10px;
				color: @text-muted;
				font-size: 18px;
				line-height: 20px;
				position: relative;
				top: 1px;
			}
		}

		.icon-holder {
			width: 45px;

			.profile-image {
				display: block;
				width: 34px;
				height: 34px;
				border-radius: 20px;
				background-size: cover;
			}

			.connection-status-holder {
				position: absolute;
				right: -7px;
				bottom: -2px;
				top: inherit;

				.connection-status {
					z-index: 2;
					font-size: 16px;
				}

				.network-logo {
					.connection-status;

					&:before {
						text-shadow:
							0 0 #fff,
							1px 0px #fff,
							0px 1px #fff,
							-1px 0px #fff,
								0px -1px #fff;
						border-radius: 50%;
						position: relative;
						z-index: 1;
					}

					&:after {
						content: "";
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
						width: 1em;
						height: 1em;
						background: #fff;
						border-radius: 50%;
					}
				}
			}


			.c-icon {
				font-size: 35px;
				position: relative;
			}
		}

		.truncate {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: middle;
			min-width: 0;
			flex-shrink: 1;
		}
	}

	.gray-head.flex-head {
		.tab-menu {
			li {
				.beta {
					position: relative;
					padding-right: 68px;

					.label {
						position: absolute;
						right: 15px;
						top: -2px;
					}
				}

				&.b-underline {
					a:hover {
						text-decoration: none;

						& > b {
							text-decoration: underline
						}
					}

					&.active {
						a:hover > b {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.unselect {
		.glyphicon {
			color: #66a1dd;
			cursor: pointer;
		}

		& + .text-holder {
			display: block;
			margin-right: 30px;
		}
	}

	.remove-excluded {
		text-decoration: none;
		font-size: 23px;
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
	}

	.pad-left {
		padding-left: 5px;
	}

	.gray-container {
		padding: 15px 0;
		background: fade(#000, 3%);

		searchable-entity, .searchable-entity {
			margin: 5px 0 10px;
		}
	}

	.order-payment, .monitoring-payment {
		.unselect {
			display: inline-block;
		}

		.contacts-holder {
			max-height: 312px;
			border: 1px solid #ccc;
			padding: 10px;
			overflow-y: scroll;

			.contact-details {
				padding-bottom: 10px;
			}
		}
	}

	.comments-list {

		.filter-holder {
			.inline-toggle {
				margin-right: 0;
				margin-left: 0;

				li {
					&:last-child {
						margin-right: 0;
					}
				}
			}

			.c-icon {
				&.c-icon-phone {
					top: 2px;
					font-size: 18px;
				}
			}

			.glyphicon {
				display: inline-block;
				color: @brand-grey;
				top: 3px;
				font-size: 0.8em;
				cursor: pointer;
			}

			.tooltip-inner {
				.text-center;
			}
		}
	}

	.new-review-finding {
		.alert-danger:not(.collapsed) {
			margin-bottom: @line-height-computed;
		}

		h3 {
			margin-top: 0;
			color: @text-color;
		}

		.fixed-dropdown-menu {
			.dropdown-menu {
				max-width: 100%;
				width: 100%;

				li {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.remove {
			line-height: 26px;
			margin-left: -15px;
		}
	}

	.promo {
		.top-holder {
			padding: 50px 0;

			.carousel-holder {
				img {
					max-width: inherit;
					max-height: inherit;
				}
			}

			.description-holder {
				.description {
					top: 68%;

					h2 {
						margin-bottom: 0px;
					}

					p {
						padding: 0 0 20px 0;
						margin: 0px;
					}

					small {
						font-size: 14px;
						line-height: 16px;
						color: white;
					}
				}
			}
		}

		.promo-image {
			margin-bottom: -20px;
		}

		.item-description {
			h2 {
				margin-top: 0;
			}

			ul {
				padding-left: 16px;
			}

			p, ul {
				color: #8A9BAE;
			}

			ul li, p {
				padding-bottom: 15px;
			}

			.play-market {
				color: #8A9BAE;
			}
		}
	}

	.archive {
		.post-list {
			& > *:not(:last-child) {
				margin-bottom: 15px;
			}

			.post-container {
				margin-bottom: 15px;
				padding: 0;
				background: #fff;
				border: 1px solid #DDE4E9;

				&-header {
					padding: 18px 20px;
					display: block;
					min-height: 66px;
					border-bottom: 1px solid #DDE4E9;

					// TODO: investigate
					.popover {
						width: 212px;

						.finding-holder {
							display: flex;
							padding-bottom: 10px;

							.overflow-label-wrapper {
								max-width: 75px;
							}

							&:last-child {
								padding: 0;
							}
						}
					}

					button:not(.flex-button) {
						margin: 0 15px 0 0;
					}

					//  FIXME: legacy styles
					.reviewed-date {
						line-height: 36px;
					}

					// global archive
					.history-icon {
						font-size: 20px;
						color: @text-muted;
						cursor: pointer;
					}

					.primary-owner {
						font-size: 14px;
						line-height: 1.29;
						color: @text-color;

						&.margined {
							margin-top: -3px;
						}

						.nmls {
							font-size: 12px;
							line-height: 1.42;
							color: @text-muted;
						}
					}

					.owners {
						font-size: 14px;
						line-height: 1.43;
						color: #a2a2a2;

						.c-icon {
							margin-right: 8px;
						}
					}
				}

				.social-media-post {
					&-header {
						.add-link {
							font-size: 16px;

							i {
								padding-right: 10px;
								font-size: 18px;
								transform: rotate(224deg);
							}
						}

						.c-icon-notification {
							font-size: 28px;
							color: #DF524B;
						}
					}
				}
			}
		}
	}

	&.post-history-modal {
		.modal-body {
			padding: 0 20px;
			// TODO: better sizing with `vh`
			height: 500px;
			overflow-x: auto;

			.post-container {
				border-width: 0 0 1px 0;

				&:last-child {
					border-width: 0;
				}
			}
		}
	}

	.trigger-keyword {
		background: #ffacaa;
	}

	.search-result {
		background: yellow;
	}

	.triggers-list {
		.rule-holder {
			white-space: nowrap;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				max-width: 100%;
			}
		}
	}

	.triggers {
		.badges {
			.badge-holder {
				font-size: 13px;
				margin: 0 5px 5px 0;
				padding: 3px;
				display: inline-block;

				.text-holder {
					display: inline-block;
					line-height: 15px;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 360px;
				}
			}

			.glyphicon-remove {
				cursor: pointer;
				top: 0px;
			}
		}

		.preview {
			background: #f2f2f2;
			padding: 30px;
		}

		.nopadding {
			padding: 0;
		}
	}

	.section-toggle {
		display: inline-block;
		vertical-align: middle;

		.control-value {
			padding-top: 0;
		}
	}

	.hint-wrapper {
		.editing {
			width: 88%;
		}

		& > * {
			display: inline-block;
		}

		i {
			vertical-align: top;
			line-height: 25px;
			color: #808080;
		}
	}

	.social-compliance {
		.icon-offset-left {
			margin-left: -20px;
		}
	}
}

.grouped-input {
	display: flex;

	> .form-control, > .ng-select, > .form-group {
		& + .form-control, & + .ng-select, & + .form-group {
			margin-left: 10px !important;
		}
	}

	// Common styles for all sizes of grid columns, widths 1-12
	.icol(@index) when (@index = 1) {
		// initial
		> .input-@{index} {
			width: (100% * @index / @grid-columns);
		}

		.icol((@index + 1));
	}
	.icol(@index) when (@index =< @grid-columns) {
		> .input-@{index} {
			width: (100% * @index / @grid-columns);
		}

		.icol((@index + 1));
	}
	.icol(@index) when (@index > @grid-columns) {
	}
	.icol(1); // kickstart it
}

tpo-audit-schedule, tpo-schedule-selection-pool, tpo-audit-new-nmls, tpo-audit-auto-close {
    &.curtain {
        display: block;
    }
}

.sm-help-link {
	display: inline-table;
	vertical-align: bottom;
}

.app-store {
	background: url('/static/images/promo/icons/store-icons.png') 0 -66px no-repeat;
	display: inline-block;
	padding-left: 22px;
	line-height: 22px;
	color: #8A9BAE;
}

.play-market {
	background: url('/static/images/promo/icons/store-icons.png') 0 2px no-repeat;
	display: inline-block;
	padding-left: 22px;
	line-height: 22px;
	color: #8A9BAE;
}

.social-settings {
	.icon-holder {
		position: relative;
		padding-left: 32px;

		.medium-icon {
			position: absolute;
			top: -3px;
			left: 0;
			font-size: 22px;
			cursor: default;
		}
	}

	.setting-link {
		cursor: pointer;

		&:hover {
			background: @highlight-edit-item-bkg;
		}
	}
}

.social-reports {
	.stats-holder {
		padding: 25px 10px;
		border-bottom: 1px solid #e5e5e5;

		.text-h2 {
			font-size: @font-size-h2;
			color: @text-muted;

			b {
				font-weight: normal;
				color: @text-color
			}

		}

		.row-fluid {
			& > div:not(:last-child) {
				border-right: 1px solid #e5e5e5;
			}
		}
	}

	.stats-bar {
		.progress-bar {
			.magic-colors(background-color);
		}
	}

	.general-indicators {
		display: flex;
		flex-wrap: wrap;
		margin: 0px;

		list-style-type: none;
		padding: 0px;

		li {
			padding: 0 30px 10px 0;
			width: 130px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			.indicator-value {
				font-size: 23px;
				line-height: 25px;
			}
		}
	}

	.social-media {
		.network-title {
			font-size: 19px;
		}
	}
}

.billing-info-modal {
	.expiration {
		display: inline-block;
		width: 110px;
		min-width: 110px;
		margin-right: 5px;
	}
}

.modal-billing {
	width: 480px;
}

.schedule-selected-contacts {
    border: 2px solid #E4E4E4;
    padding: 15px;
}
