word-editor {
	@size: 18px;

	.add-word-area {
		margin-bottom: 10px;
	}

	.words{
		.word {
			display: inline-flex;
			overflow: hidden;
			user-select: none;
			margin: 0 5px 5px 0;
			padding: 0;
			font-size: 12px;
			line-height: @size;
			border-radius: 2px;
			background: @ng-select-disabled-text;
			color: @ng-select-primary-text;

			.word-remove {
				display: block;
				width: 19px;
				font-size: 18px;
				text-align: center;
				border-right: 1px solid fade(#888, 20%);
				color: fade(@text-muted, 90%);
				cursor: pointer;

				&:hover {
					color: @text-muted;
					background-color: darken(@ng-select-selected, 5%);
				}
			}

			.word-value {
				padding: 0 5px;
			}
		}
	}
}
