//@Notice: copy from src/main/webapp/styles/social-media.less line 573, remove after final integration
.social-media-modal-new {
	width: 550px;
	.modal-body {
		p {
			margin-bottom: 25px;
		}
		.btn-section {
			margin-bottom: 15px;
			.btn {
				width: 240px;
				i {
					float: left;
					// c-icon vertical position fix
					&.c-icon {
						padding-top: 4px;
					}
				}
			}
		}
	}
}
