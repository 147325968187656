@font-face {
	font-family: 'comergence-icons';
	src: data-uri('application/font-woff;charset=utf-8;base64', '/static/fonts/comergence-icons.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Glyphicons Halflings';
	src: data-uri('application/font-woff;charset=utf-8;base64', '~bootstrap/fonts/glyphicons-halflings-regular.woff') format('woff');
}
