.row-fluid {

	// Common styles for all sizes of double precision(coldp) grid columns, widths 1-24
	.coldp-width-loop (@index) when (@index > 0) {
		.coldp-xs-@{index}, .coldp-sm-@{index}, .coldp-md-@{index}, .coldp-lg-@{index} {
			width: (100% * @index/(@grid-columns * 2));
			float: left;
			position: relative;
			// Prevent columns from collapsing when empty
			min-height: 1px;
			// Inner gutter via padding
			padding-left:  (@grid-gutter-width / 2);
			padding-right: (@grid-gutter-width / 2);
		}
		.coldp-width-loop(@index - 1);
	}
	.coldp-width-loop(@grid-columns * 2);
}
