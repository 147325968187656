.contact-us {
	position: relative;

	&:after {
		content: "Contact Us";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;
		color: fade(@text-muted, 75%);
		font-size: 3.5em;
		font-weight: bold;
		background-color: #fff;
		opacity: .5;
	}
}
