@import "mixins.less";

@ng-select-highlight: @brand-primary;
@ng-select-primary-text: @text-color;
@ng-select-disabled-text: #F4F5F7;
@ng-select-border: @input-border;
@ng-select-border-radius: 4px;
@ng-select-bg: #ffffff;
@ng-select-marked-selected: #E6E7EC;
@ng-select-marked:  #F4F5F7;
@ng-select-selected: mix(@ng-select-marked-selected, @ng-select-marked);
@ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
@ng-select-placeholder: lighten(@ng-select-primary-text, 40%);
@ng-select-height: @input-height-base;
@ng-select-value-padding-left: 10px;
@ng-select-value-font-size: 12px;
@ng-select-value-line-height: 18px;

.ng-select {
	&.ng-select-opened {
		> .ng-select-container {
			background: @ng-select-bg;
			border-color: darken(@ng-select-border, 10%) @ng-select-border lighten(@ng-select-border, 5%);

			&:hover {
				box-shadow: none;
			}

			.ng-arrow {
				top: -2px;
				border-color: transparent transparent darken(@ng-select-border, 20%);
				border-width: 0 5px 5px;

				&:hover {
					border-color: transparent transparent darken(@ng-select-border, 60%);
				}
			}
		}

		&.ng-select-bottom {
			> .ng-select-container {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		&.ng-select-top {
			> .ng-select-container {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
		}
	}

	&.ng-select-focused {
		&:not(.ng-select-opened) > .ng-select-container {
			border-color: @ng-select-highlight;
			box-shadow: @ng-select-box-shadow;
		}
	}

	&.ng-select-disabled {
		> .ng-select-container {
			background-color: @ng-select-disabled-text;
		}
	}

	.ng-has-value .ng-placeholder {
		display: none;
	}

	.ng-select-container {
		color: @ng-select-primary-text;
		background-color: @ng-select-bg;
		border-radius: @ng-select-border-radius;
		border: 1px solid @ng-select-border;
		min-height: @ng-select-height;
		align-items: center;

		&:hover {
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
		}

		.ng-value-container {
			align-items: center;
			padding-left: @ng-select-value-padding-left;

			.rtl {
				padding-right: @ng-select-value-padding-left;
				padding-left: 0;
			}

			.ng-placeholder {
				color: @ng-select-placeholder;
			}
		}
	}

	&.ng-select-single {
		.ng-select-container {
			height: @ng-select-height;

			.ng-value-container {
				.ng-input {
					top: 5px;
					left: 0;
					padding-left: @ng-select-value-padding-left;
					padding-right: 50px;

					.rtl {
						padding-right: @ng-select-value-padding-left;
						padding-left: 50px;
					}
				}
			}
		}
	}

	&.ng-select-multiple {
		&.ng-select-disabled {
			> .ng-select-container .ng-value-container .ng-value {
				background-color: @ng-select-disabled-text;
				border: 1px solid lighten(@ng-select-border, 10%);

				.ng-value-label {
					padding: 0 5px;
				}
			}
		}

		.ng-select-container {
			.ng-value-container {
				padding-top: 5px;
				padding-left: 7px;

				.rtl {
					padding-right: 7px;
					padding-left: 0
				}

				.ng-value {
					margin-bottom: 5px;
					background-color: @ng-select-marked;
					line-height: @ng-select-value-line-height;
					border-radius: 2px;
					margin-right: 5px;

					.rtl {
						margin-right: 0;
						margin-left: 5px;
					}

					&.ng-value-disabled {
						background-color: @ng-select-disabled-text;

						.ng-value-label {
							padding-left: 5px;

							.rtl {
								padding-left: 0;
								padding-right: 5px;
							}
						}
					}

					.ng-value-label {
						display: inline-block;
						vertical-align: middle;
						padding: 0 3px;
						font-size: @ng-select-value-font-size;
						line-height: @ng-select-value-line-height;
					}

					.ng-value-icon {
						@ng-select-border-color: fade(@text-muted, 20%);

						display: inline-block;
						vertical-align: middle;
						text-align: center;
						flex: 0 0 @ng-select-value-line-height + 1;
						width: @ng-select-value-line-height + 1;
						color: fade(@text-muted, 90%);
						font-size: 18px;
						line-height: @ng-select-value-line-height;

						&:hover {
							color: @text-muted;
							background-color: darken(@ng-select-selected, 5%);
						}


						&.left {
							border-right: 1px solid @ng-select-border-color;

							.rtl {
								border-left: 1px solid @ng-select-border-color;
								border-right: none;
							}
						}

						&.right {
							border-left: 1px solid @ng-select-border-color;

							.rtl {
								border-left: 0;
								border-right: 1px solid @ng-select-border-color;
							}
						}
					}
				}

				.ng-input {
					padding: 0 0 3px 3px;

					.rtl {
						padding: 0 3px 3px 0;
					}
				}

				.ng-placeholder {
					top: 5px;
					padding-bottom: 5px;
					padding-left: 3px;

					.rtl {
						padding-right: 3px;
						padding-left: 0;
					}
				}
			}
		}
	}

	.ng-clear-wrapper {
		color: darken(@ng-select-border, 20%);
		line-height: @ng-select-value-line-height;
		text-align: center;

		&:hover .ng-clear {
			color: darken(@ng-select-border, 40%);
		}
	}

	.ng-spinner-zone {
		padding: 5px 5px 0 0;

		.rtl {
			padding: 5px 0 0 5px;
		}
	}

	.ng-arrow-wrapper {
		width: 20px;
		padding-right: 3px;

		.rtl {
			padding-left: 3px;
			padding-right: 0;
		}

		&:hover {
			.ng-arrow {
				border-top-color: darken(@ng-select-border, 40%);
			}
		}

		.ng-arrow {
			border-color: darken(@ng-select-border, 20%) transparent transparent;
			border-style: solid;
			border-width: 5px 5px 2.5px;
		}
	}
}

.ng-dropdown-panel {
	background-color: @ng-select-bg;
	border: 1px solid @ng-select-border;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	left: 0;

	&.ng-select-bottom {
		top: 100%;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		border-top-color: @ng-select-border;
		margin-top: -1px;

		.ng-dropdown-panel-items {
			.ng-option {
				&:last-child {
					border-bottom-right-radius: 4px;
					border-bottom-left-radius: 4px;
				}
			}
		}
	}

	&.ng-select-top {
		bottom: 100%;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-color: lighten(@ng-select-border, 10%);
		margin-bottom: -1px;

		.ng-dropdown-panel-items {
			.ng-option {
				&:first-child {
					border-top-right-radius: 4px;
					border-top-left-radius: 4px;
				}
			}
		}
	}

	.ng-dropdown-header {
		border-bottom: 1px solid @ng-select-border;
		padding: 5px 7px;
	}

	.ng-dropdown-footer {
		border-top: 1px solid @ng-select-border;
		padding: 2px 5px;
	}

	.ng-dropdown-panel-items {
		.ng-optgroup {
			user-select: none;
			padding: 8px 10px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.54);
			cursor: pointer;

			&.ng-option-disabled {
				cursor: default;
			}

			&.ng-option-selected {
				background-color: @ng-select-selected;
				font-weight: 600;
			}

			&.ng-option-marked {
				background-color: @ng-select-marked;
			}

			&.ng-option-marked.ng-option-selected {
				background-color: @ng-select-marked-selected;
			}
		}

		.ng-option {
			background-color: @ng-select-bg;
			color: rgba(0, 0, 0, .87);
			padding: 8px 10px;

			&.ng-option-selected {
				color: @ng-select-primary-text;
				background-color: @ng-select-selected;

				.ng-option-label {
					font-weight: 600;
				}
			}

			&.ng-option-marked {
				background-color: @ng-select-marked;
				color: @ng-select-primary-text;
			}

			&.ng-option-marked.ng-option-selected {
				background-color: @ng-select-marked-selected;

			}

			&.ng-option-disabled {
				color: lighten(@ng-select-primary-text, 60%);
			}

			&.ng-option-child {
				padding-left: 22px;

				.rtl {
					padding-right: 22px;
					padding-left: 0;
				}
			}

			.ng-tag-label {
				font-size: 80%;
				font-weight: 400;
				padding-right: 5px;

				.rtl {
					padding-left: 5px;
					padding-right: 0;
				}
			}
		}
	}

	.rtl {
		direction: rtl;
		text-align: right;
	}
}
