.social-accounts-modal {
	.modal-body {
		display: flex;
		align-items: center;
		flex-direction: column;

		p {
			margin-bottom: 25px;
			max-width: 100%;
		}

		.btn-section {
			width: 100%;

			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			margin: 0 -8px;

			.connect-btn {
				margin: 0 16px 16px 0;
				flex: 0 0 auto;
				width: 166px;
				height: 75px;
				border: 1px solid #E0E2E8;
				border-radius: 5px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 128px 50px;
				cursor: pointer;

				&:nth-child(3n) {
					margin-right: 0;
				}

				&:hover {
					background-color: fade(@brand-primary, 10%);
				}
			}
		}
	}

	.connect-btn-yt {
		background-image: url("/static/images/social-media/buttons/youtube-min.png");
	}

	.connect-btn-fb {
		background-image: url("/static/images/social-media/buttons/facebook-min.png");
	}

	.connect-btn-ig {
		background-image: url("/static/images/social-media/buttons/instagram-min.png");
	}

	.connect-btn-tw {
		background-image: url("/static/images/social-media/buttons/twitter-min.png");
	}

	.connect-btn-gmb {
		background-image: url("/static/images/social-media/buttons/google-min.png");
	}

	.connect-btn-li {
		background-image: url("/static/images/social-media/buttons/linkedin-min.png");
	}
}
