investors-list {
    display: block;
}

investor-item {
    .child {
        padding-top: 3px;
        padding-left: -8px;
    }
}
