.dynamic-content-selector {
	position: relative;
	padding-bottom: 5px;

	.text-link:hover {
		text-decoration: none;
	}

	.c-icon {
		font-size: 10px;
		position: relative;
		top: -1px;
	}
}
