.modal-new .campaigns-enroll {
	.modal-body {
		padding: 10px 0;
	}

	.sm-account {
		padding: 6px 20px;
		margin: 0;
		font-weight: normal;

		&.danger {
			background: @btn-outline-danger-hover-bg;
		}

		&.has-action:hover {
			cursor: pointer;
			background: @btn-secondary-hover-bg;
		}

		sm-icon {
			margin-right: 15px;
		}

		input {
			margin: 0;
		}
	}
}
