.neighborhood-watch {
	h3 {
		margin-bottom: 0;
	}

	.lender-details {
		.form-horizontal {
			.form-group {
				.control-label {
					&:after {
						content: ":"
					}
				}
				uber-editable {
					white-space: nowrap;
				}
			}
			[form-errors] {
				.form-error-inner {
					margin: 0;
					padding: 4px 0;
					background: none;
					border: none;
					border-radius: 0;
				}
			}
		}
	}
	.stats {
		.item {
			.text-center;
			&.item-header {
				padding-top: 2px;
				padding-right: 20px;
				padding-left: 20px;
			}
			&.item-content {
				padding-bottom: 2px;
				.text-big {
					display: block;
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}
			&:not(:last-child) {
				border-right: 1px rgba(0, 0, 0, 0.15) solid;
			}
		}
	}
	.manage-columns {
		.columns-list {
			column-count: 2;
			column-gap: 30px;
			.checkbox {
				margin-top: 0;
				margin-bottom: 5px;
			}
		}
		.notification {
			margin-left: 15px;
			.text-muted;
		}
	}
	section {
		margin-top: 0;
	}
}

.neighborhood-watch-upload {
	.input-details {
		// TODO: make generic
		.droppable {
			position: relative;
			.frontface {
				padding: 10px 20px;
				min-height: 110px;
				display: flex;
				align-items: center;
				border: 1px dashed #ccc;
				border-radius: 6px;
				.file-handler() {
					.file-handler {
						margin-bottom: 0;
						input[type=file] {
							.ng-hide();
						}
						.btn {
							width: 150px;
						}
					}
				}
				.upload {
					margin: 0 auto;
					text-align: center;
					.file-handler();
					.uploading {
						position: absolute;
						top: 1px;
						right: 1px;
						left: 1px;
						bottom: 1px;
						background: #fff url(/static/images/loader-circular-primary.gif) no-repeat center;
						border-radius: 6px;
					}
				}
				.uploaded {
					width: 100%;
					.file-handler();
					.file {
						margin: 0;
						float: left;
					}
					.actions {}
				}
			}
			// TODO: verify implementation
			.backface {
				color: fade(@brand-primary, 100%);
				background: mix(@brand-primary, #fff, 20%);
				border: 1px dashed @brand-primary;
				border-radius: 6px;
				text-align: center;
				.error {
					padding: 20px 0 0;
					color: @brand-danger;
					border: 1px solid @brand-danger;
					background: mix(@brand-danger, #fff, 20%);
					border-radius: inherit;
				}
			}
		}
		.form-controls {
			background-color: inherit;
			button {
				&[type=submit] {
					width: 100px;
				}
			}
		}
	}
}
