.channel-relationship {
	position: relative;
	z-index: 0;

	.currentApplication {
		background: @current-section;
	}
	.btn-sm {
		padding: 3px 5px;
	}

	.tabbed-header {
		h2 {
			margin: 0;
		}
	}

	.questionnaire {
		margin: 20px 0 0;
		padding: 30px 30px 10px;
		border-radius: 6px;
		background: #efefef;

		@paddingLeft: 35px;

		h3 {
			margin-left: @paddingLeft;
		}

		.countee {
			margin-top: 20px;
			margin-left: @paddingLeft;
			padding-bottom: 20px;
			border-bottom: 1px solid #ddd;
			position: relative;

			&:last-child {
				border-bottom: none;
			}

			&:before {
				position: absolute;
				margin-right: 20px;
				right: 100%;
				font-weight: 600;
			}
		}

		.group {
			font-size: @font-size-h3;
			padding: 30px 0 0 @paddingLeft;

			&:first-child {
				padding-top: 0;
			}
		}

		.description {
			margin-top: 15px;
			font-size: 13px;
			font-style: oblique;
			color: #666;
		}


		.answer {
			min-width: 60px;
			padding: 10px 0 5px;
			font-weight: 600;

			.answer-number {
				position: absolute;
				margin-right: 20px;
				right: 100%
			}

			&:empty:after {
				content: "-";
				color: @brand-danger;
			}
		}
	}
}

