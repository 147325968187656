@keyframes animate-loader-fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.remote-resource {
	@duration: 0.3s;
	position: relative;
    animation-name: aninate-loader-fadein;
    animation-duration: 1s;
    animation-iteration-count: initial;

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: 999;
		top: 0;
		right: 0;
		left: 0;
		height: 100%;
		min-height: 30px;
		background: fade(#fff, 75%) url("/static/images/linear-loader.svg") 50% 50% no-repeat;
		opacity: 1;
		visibility: visible;
		transition: opacity @duration, visibility @duration, background-position @duration;
	}

	&.patient:after {
		content: 'Please be patient as this process may take a few minutes to complete. To avoid processing disruption, please stay on the page.';
		text-shadow: 0 0 5px #FFF;
		padding: 30px 30px 0;
		background-color: fade(#fff, 95%);
	}

	&.resolved:after {
		opacity: 0;
		visibility: hidden;
		transition-delay: 0s, @duration, 0s;
		transition-duration: @duration, 0s, @duration;
	}

	&.no-data:after {
		content: "No Data";
		opacity: 1;
		visibility: visible;
		background-image: none;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ccc;
		font-size: 1.5em;
	}

	&.rows-striped:after , &.rows-bordered:after  {
		left: -15px;
		right: -15px;
	}
}
