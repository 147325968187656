//@Notice: Looks like for this case we should use collapsible-card global component instead of channel-card
.channel-card {
    border: 1px solid lightgray;
    border-top: none;
    border-radius: 6px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    margin-right: 5px;
    .card-heading {
        h3 {
            margin: 0;
            font-size: 18px;
            line-height: @input-height-base;
        }

        .glyphicon {
            top: 1px;
            font-size: 18px;
            transition: transform 0.3s;
        }

        border-radius: 6px 6px 0 0;
        background-color: @light-blue;
        color: #FFFFFF;
        padding: 10px;
        position: relative;
        cursor: pointer;
        &:hover {
            background-color: lighten(@light-blue, 5%);
        }
        &.collapsed {
            .glyphicon {
                transform: rotate(90deg);
            }
        }
    }
    .card-body {
        padding: 10px;
        .description-text {
            text-align: justify;
        }
        .right-colum-alignment {
            margin-left: 20px;
        }

        hr {
            margin: 5px 0;
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    // TODO: Relay these components out. These styles are all hacks

    .toggle-container-left-alignment {
        padding-left: 0px !important;
    }
    .bottom-row-absolute-hack {
        padding-bottom: 20px;
    }

    label {
        margin-bottom: 0px !important;
        padding-top: 4px !important;
    }

    channel-card-ownership-and-description {
        a {
            padding-top: 5px !important;
        }
    }

    .questionnaire-dropdown {
        padding-right: 0px !important;
    }

    .questionnaire-edit-controls {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.notification-header {
    border-bottom: 2px solid dimgrey;
}
