contacts-import-container .container {
    display: block;
    margin: 30px auto;
    width: @modal-md;
}


contacts-import-steps {
    display: flex;
    @num-pad: 15px;
    margin-bottom: 30px;

    .step {
        color: #CCC;

        h4 {
            margin-block: 8px 0;
        }

        &.active {
            .number {
                background-color: @brand-primary;
            }

            h4 {
                color: @text-color;
                font-weight: 600;
            }
        }

        .number {
            font-weight: 600;
            font-size: 18px;
            flex: 0 0 (@num-pad*2 + 1);
            line-height: (@num-pad*2 + 1);
            height: (@num-pad*2 + 1);
            background-color: #CCC;
            color: #FFF;
            border-radius: 50%;
            text-align: center;
        }
    }

    .line {
        flex: 1 1 auto;
        height: 1px;
        margin-block: @num-pad;
        background-color: #CCC;
    }
}

contacts-import-upload {
    p, uploader {
        display: block;
        margin: 30px 0;
    }
}

contacts-import-mapping .rows-bordered > .row-fluid .control-label {
    padding-top: 5px;
    padding-inline: 15px;
}

contact-imports-list .rows-bordered > .row-fluid .button-holder {
    padding-inline: 5px;
}
