@import "../../../commons/components/layouts/full-page/full-page-layout.common.less";

.social-compliance-publisher-post,
.social-compliance-publisher-post-template {
	.full-page-header {
		.page-title {
			margin: 0 auto;
			.full-page-layout-header-font();
		}
	}
	.content {
		overflow-y: hidden;

		.panel {
			@panel-width: 400px;
			@footer-height: 60px;

			display: flex;
			flex-direction: column;
			margin: 0;
			padding-bottom: @footer-height;
			width: @panel-width;
			border: none;
			box-shadow: 1px 0 0 0 #e6e7ec;

			.panel-body {
				padding: 20px 20px 30px;
				overflow-y: auto;
				background-color: #fff;
				min-height: 100%;

				section {
					min-width: 360px;
				}

				.ng-select .ng-dropdown-panel {
					max-width: 360px;
				}

				.panel-title {
					margin-bottom: 15px;
					font-size: 19px;
					font-weight: 400;
					line-height: 1.5;
				}
			}

			.panel-footer {
				position: fixed;
				bottom: 0;
				display: flex;
				align-items: center;
				width: @panel-width;
				height: @footer-height;
				padding: 0 20px;
				background-color: #fff;
				border: none;
				box-shadow: 0 -1px 0 0 #e6e7ec;

				.pull-right {
					margin-left: auto;
				}
			}
		}

		.alert {
			margin: 0 0 15px;
			padding: 15px;
			&.alert-danger {
				font-size: 14px;
			}
		}
		section {
			margin: 0 0 15px;
		}
		.form-group {
			margin-bottom: 10px;
		}

		.font() {
			line-height: 1.5;
			font-size: 12px;
			font-weight: 400;
		}
		@muted: #808080;

		.control-label {
			margin-bottom: 3px;
			.font();
			color: @muted;
		}
		.control-description {
			margin-top: 3px;
			.font();
			color: @muted;
		}
		.btn-link {
			&.btn-small {
				min-width: auto;
				padding: 0;
				.font();
			}
		}

		.invalid() {
			border-color: #f09996;
		}

		.form-control {
			&.ng-invalid.ng-touched {
				.invalid();
			}
		}
		.ng-select {
			&.ng-invalid.ng-touched {
				.ng-select-container {
					.invalid();
				}
			}
		}
		textarea {
			padding: 5px 10px;
		}
	}
}

.ng-dropdown-panel {
	&.social-compliance-publisher-post-accounts,
	&.social-compliance-publisher-post-template,
	&.social-compliance-publisher-post-campaigns,
	&.social-compliance-publisher-post-template-campaigns {
		.ng-dropdown-panel-items {
			.ng-option:not(.ng-option-disabled) {
				color: #808080;
				&.ng-option-selected {
					color: #333;
				}
			}
		}
	}
}

@import './posts/$postType/$postId/edit/post-edit.component.less';
@import './posts/$postType/$postId/edit/limits.component.less';
@import './posts/$postType/$postId/view/post-view.component.less';
@import './library/templates/list.component.less';
@import './library/templates/$templateId/edit/template-edit.component.less';
@import './library/templates/$templateId/view/template-view.component.less';
@import './library/templates/$templateId/template-network-select/template-network-select.less';
@import './library/templates/$templateId/template-text-edit/template-text-edit.less';
@import './library/templates/folder-modal/folder-modal.component.less';
@import './library/templates/groups-modal/groups-modal.component.less';
@import './library/templates/move-template-modal/move-template-modal.component.less';
@import './library/templates/remove-folder-modal/remove-folder-modal.component.less';
@import './library/content/content.less';
@import './posts/$postType/$postId/posts-preview/posts-preview.component.less';
@import './posts/$postType/$postId/posts-preview/post.component.less';
@import './posts/$postType/$postId/posts-preview/facebook-post/facebook-post.component.less';
@import './posts/$postType/$postId/posts-preview/instagram-post/instagram-post.component.less';
@import './posts/$postType/$postId/posts-preview/linkedin-post/linkedin-post.component.less';
@import './posts/$postType/$postId/posts-preview/twitter-post/twitter-post.component.less';
@import './posts/$postType/$postId/posts-preview/publisher-attachments-preview/publisher-attachments-previews.component.less';
@import './campaigns/$campaignId/participants/campaign-participants.component.less';
@import './campaigns/$campaignId/folder/campaign-folder.component.less';
@import './posts/$postType/post-type.component.less';
@import './groups/groups.less';
