post {
	linkedin-post {
		&.post-content {
			.link-preview {
				.text-holder {
					.link {
						margin-top: 8px;
					}
				}
			}
		}
	}
}
