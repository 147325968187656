.payments{
	h4{
		margin:0;
	}
	.print{
		.glyphicon {
			font-size: 22px;
			margin-left: 5px;
			color: @brand-grey-icon;
			vertical-align: text-bottom;
		}
		border-left: 1px solid @brand-grey-icon;
	}
	.header {
		span.text-center {
			padding-left: 2px;
			padding-right: 2px;
		}
	}
	
}