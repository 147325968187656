.configure-financial-documents-modal {
  .toggle-request {
    padding-bottom: 10px;
    border-bottom: 2px solid #5e5e5e;
    margin-bottom: 15px;

    &.text-muted {
      border-color: @btn-disabled-border;
    }

    .toggle-container.active {
      background-color: @brand-success;
      border-color: @brand-success;
    }
  }

  .financial-settings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}