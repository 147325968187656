.social-media-campaigns {

	.section-header {
		margin-bottom: 30px;
	}

	.row-fluid {
		&.highlighted {
			background: fade(@brand-primary, 10%);
		}

		.ng-select {
			margin-left: 0;
		}
	}
}
