finding-checklist {
	.rows-bordered {
		& > .row-fluid:last-child {
			border-bottom: none;
		}

		.header {
			.col-sm-12 {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.glyphicon {
				padding-right: 5px;

				&.glyphicon-ok {
					color: @brand-success;
				}

				&.glyphicon-remove {
					color: @red-danger;
				}
			}
		}

		.observation {
			margin-bottom: 7px;
		}
	}
}
