.scorecard-finding-header-label {
    position: relative;
    bottom: 1px;
}

.scorecard-finding-header-content {
    position: relative;
    bottom: 4px;

    &.mark-reviewed-button {
        bottom: 1px;
    }
}
