.promo {
	@col-width: 880px;

	.underlined {
		text-decoration: underline;
	}

	.top-holder {
		margin-top: -20px;
		background: @brand-primary-dark;
		padding: 40px 0;
		box-shadow: -@col-width 0 0 @brand-primary-dark,
		@col-width 0 0 @brand-primary-dark;

		.description-holder {
			position: relative;
			min-height: 300px;
			.description {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				color: #8999aa;
				h2 {
					color: #fff;
					font-size: 34px;
					line-height: 39px;
					margin-bottom: 15px;
				}
				p {
					line-height: 22px;
					font-size: 14px;
					padding: 0 60px 10px 0;
				}
			}
		}

		.carousel-holder {
			text-align: center;
			img {
				max-width: 420px;
				max-height: 300px;
			}
		}
	}

	.blocks {
		div {
			color: #8A9BAE;
			line-height: 22px;
			ul {
				padding-left: 5px;
			}
			a {
				color: @brand-info;
			}
			img {
				height: 90px;
				margin-left: -15px;
			}
			h3 {
				color: @brand-info;
				margin-bottom: 20px;
			}
		}
	}
}
