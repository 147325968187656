.social-compliance-publisher-post {
	&.edit {
		.ng-select {
			&.social-compliance-publisher-post-accounts {
				.ng-select-container {
					.ng-value-container {
						padding-top: 0;
					}
				}
			}
		}
		.schedule-date-time {
			// compensate time picker controls
			margin-top: -@timepicker-chevron-height;
			timepicker {
				margin-left: 10px;
			}
		}
		.panel {
			.panel-body {
				padding-bottom: 35px;
			}
		}
	}
}

.social-compliance-publisher-post-template {
	.panel {
		.panel-body {
			flex-grow: 1;
		}
	}

	.ng-dropdown-panel {
		max-width: 100%;
	}

	.horizontal-flex-group {
		.full-width {
			overflow: hidden;
			text-overflow: ellipsis;
			margin-right: 0;
		}
	}
}
