.review-findings {
	.account-holder {
		display: flex;
		align-items: center;

		.image-size {
			width: 80px;
			height: 80px;
		}

		.image-holder {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			overflow: hidden;
			border: 1px solid #dbdbdb;
			margin: 0 10px;
			background-color: white;
			.image-size;

			.screenshot-preview {
				.image-size;
				object-fit: contain;
			}

			i {
				color: #e6e7ec;
				font-size: 28px;
			}
		}
	}

	.observation-icon {
		color: #9C9C9C;
		margin-left: 20px;

		&:before {
			margin-left: -5px;
		}
	}

	popover-container {
		max-width: 400px;
		width: 400px;

		ul {
			margin-bottom: 0;
			padding-left: 15px;
			font-size: 11px;
			color: #828282;

			.observation-text {
				font-size: 13px;
				color: black;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.link-holder {
		overflow: hidden;
	}
}
