.c-icon {
	position: relative;
	top: 0;
	display: inline-block;
	font-family: "comergence-icons";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	width: 1em;
	height: 1em;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@import (inline) "../fonts/comergence-icons-codes.css";

.svg-c-icon(@url) {
	&:before {
		content: "\2007";
		display: inline-block;
		width: 1em;
		height: 1em;
		background-image: url(@url);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}

//Replace before
.c-icon-google {
	.svg-c-icon('/static/images/icons/google.svg');
}

.c-icon-google-my-business {
	.svg-c-icon('/static/images/icons/gmb.svg');
}

.c-icon-google-my-business-circle {
	.svg-c-icon('/static/images/icons/gmb-circle.svg');

	&.icon-light-gray,
	&.icon-lighter-gray {
		&:before {
			background-image: url('/static/images/icons/gmb-circle-inactive.svg');
		}
	}
}

.c-icon-bing {
	.svg-c-icon('/static/images/icons/bing.svg');
}

.c-icon-eye-crossed {
	&:before {
		content: '\e801';
	}

	&:after {
		content: "\2007";
		display: block;
		position: absolute;
		height: 1.3em;
		border-left: 2px solid;
		top: 0.25em;
		left: .7em;
		box-shadow: 0 0 0 2px #fff;
		transform: rotate(15deg);
	}
}

.c-icon-alert {
	&:before {
		content: '\e800';
		color: transparent;
		text-shadow:
			0 0 @brand-danger,
			1px 0 #fff,
			0 1px #fff,
			-1px 0 #fff,
			0px -1px #fff;
		border-radius: 50%;
		position: relative;
		z-index: 1;
	}

	&:after {
		content: "\2007";
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 1em;
		height: 1em;
		background: #fff;
		border-radius: 50%;
	}
}

.c-icon-reverse {
	transform: rotate(180deg);
}
