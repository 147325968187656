.pagination {
	.btn {
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			border-color: transparent;
		}

		&.page {
			min-width: @input-height-base;
			&.active {
				color: @text-color;
				font-weight: bold;
				background: @btn-tertiary-hover-bg;
			}
		}
	}

	.size {
		line-height: @input-height-base;
		.size-control {
			float: right;
			margin-left: 5px;
			line-height: initial;
		}
		.ng-select-container {
			min-width: 53px;
		}
	}
}
