.social-media-posts {

	.w11k-select {
		.dropdown-menu {
			max-width: 100%;

			.items {
				& > .item {
					text-overflow: ellipsis;
				}
			}
		}
	}

	&.post-privacy-settings-modal,
	&.remove-post-modal,
	&.remove-template-modal {
		.modal-dialog {
			width: 550px;
		}
	}

	.btn {
		&.btn-link {
			&.no-padding {
				padding: 0;
			}
			&.no-side-padding {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.radio-holder {
		padding-left: 22px;
	}

	.pseudo-row {
		.pseudo-cell {
			&:first-child {
				padding-left: 0;
			}
		}
	}

	.counter {
		display: inline-block;
		margin-top: 5px;
		color: @text-muted;
	}

	.row {
		&.bordered {
			margin: 0;
			padding: 15px 0;

			&:not(:last-child) {
				border-bottom: 1px solid @brand-nav-panel-search-border;
			}

			> div {
				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	.account-option {
		.checkbox-holder {
			width: 25px;
		}

		.icon-holder {
			position: relative;
			width: 56px;
		}
	}

	.control-value {
		&.label-holder {
			padding-top: 3px;
		}
	}

	.margin-bottom-md {
		margin-bottom: @line-height-computed * 1.5;
	}

	.margin-bottom-lg {
		margin-bottom: ceil(@line-height-computed * 10);
	}

	.files {
		.attachments {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;

			.attachment {
				display: block;
				width: 110px;
				height: 110px;
				margin: 0 5px 10px 5px;
				border: 1px solid #ccc;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				img {
					display: block;
					width: 110px;
					height: 110px;
					margin: -1px;
					border-radius: 3px;
					z-index: -1;
				}

				.remove {
					position: absolute;
					top: 6px;
					right: 6px;
					color: white;
					text-align: center;
					font-size: 16px;
					line-height: 16px;
					background: #aaa;
					padding: 4px;
					box-sizing: content-box;
					border-radius: 50%;
					opacity: 0;
					transition: opacity 0.3s;
					cursor: pointer;
					z-index: 1000;
				}

				&:hover .remove {
					opacity: 1;
				}

				.error {
					position: absolute;
					bottom: 6px;
					left: 6px;
					top: auto;
					text-align: center;
					font-weight: 600;
					width: 24px;
					height: 24px;
					line-height: 24px;
					background: @brand-danger;
					color: white;
					border-radius: 50%;
					cursor: default;
					display: block;
				}
			}
		}

		.attachment-video {
			background: url("/static/images/video_icon.png") center no-repeat;
		}

		.attachment-image {
			background: url("/static/images/image_icon.png") center no-repeat;
		}

		&:extend(.documents .files all);
	}

	.browse-templates {
		.w11k-select {
			& > .form-control {
				padding-right: 40px;
				padding-left: 0;
				border: none;
				box-shadow: none;
				cursor: pointer;

				.header-placeholder {
					color: #66a1dd;
				}

				.header-text {
					display: inline-block;
					margin-right: -20px;
					padding-right: 20px;
					padding-left: 6px;
					color: #66A1DD;
					background-color: #e0ecf8;
					border-radius: 3px;
				}

				.caret {
					position: initial;
					color: #66a1dd;
				}
			}
		}
		.clear {
			position: absolute;
			top: 4px;
			right: 15px;
		}
	}

	.section-wrapper {
		@col-width: 880px;
		margin-top: -29px;
		padding: 10px 0;
		background: #fff;
		border-bottom: 1px solid #DDE4E9;

		box-shadow:
				-@col-width -1px  0 #fff,
				-@col-width 0     0 #DDE4E9,
				@col-width -1px  0 #fff,
				@col-width 0     0 #DDE4E9,
				2*-@col-width -1px  0 #fff,
				2*-@col-width 0     0 #DDE4E9,
				2* @col-width -1px  0 #fff,
				2* @col-width 0     0 #DDE4E9;
	}

	.post-preview {
		&:extend(.social-compliance .archive .posts-holder all);

		.post {
			.content-holder {
				.content {
					padding: 0;
				}
			}
		}
	}
}
