// extend holder class in @import "documents";
.manage {
  &:extend(.documents all);
  .row-space {
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .manage-page {
    & > h3 {
      margin:35px 0;
    }

    .checkbox-holder, .no-label-padding {
      .control-value {
        padding-top: 0;
      }
    }
  }

  &.manage-password-policy {
	h3 {
		margin-bottom: 20px;
	}

	&.editing {
		.control-label, .checkbox-holder, .inner-space .weight-normal {
			padding-top: 6px;
		}
	}

	.settings-holder {
		max-width: none;
		margin-left: -15px;
		margin-right: -15px;
	}

	.checkbox-holder {
		// checkbox holder
		.col-sm-2 {
			width: 42px;
		}
	}
  }

  .checkbox {
    margin-top: 0;
  }

  .settings-holder {
    max-width: 1000px;
    margin: 0px auto;
  }

  .rows-holder {
    .inner-space {
      padding-bottom: 5px;
    }
    .checkbox {
      margin-bottom: 0;
    }
  }

  .hide-value {
    text-indent: -9999px;
    font-size: 0;
  }

  .deactivate-channel {
    span {
      opacity: 0.5;
    }
    span:first-child {
      span {
        opacity: 1;
      }
    }
    .deactivate-name {
      opacity: 1;
    }
  }

  .label-deactivate {
    border: 1px solid gray;
    font-weight: bold;
  }

  .active-name {
    color: @brand-primary;
    &:hover{
      text-decoration: underline;
    }
  }
  .questionnaire {
    .applications, .renewals{
      display: none;
    }
    .applicationAssigned .applications{
      display: inline;
    }
    .renewalsAssigned .renewals{
      display: inline;
    }
    .glyphicon-ok-sign {
      color: @brand-green;
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }
}
