uploader {
	.uploader-container {
		height: 70px;
		border: 1px dashed @brand-grey-light;
		border-radius: 5px;
		transition: border 300ms;

		&.hover {
			border: 1px solid @brand-primary;
		}

        .selected-file {
            display: flex;
            padding-inline: 15px;
            width: 100%;

            .c-icon-file {
                width: 30px
            }
        }
	}

	input[type=file] {
		.hidden;
	}
}

.view-file-list {
	.view-file-list-container {
		height: 70px;
		border: 1px dashed @brand-grey-light;
		border-radius: 5px;
		transition: border 300ms;

		&.hover {
			border: 1px solid @brand-primary;
		}
	}
}
