.market-search {
	display: block;
	width: 340px;

	.ng-select.ng-select-single {
		.ng-select-container {
			.ng-value-container {
				padding-right: 8px;

				&:before {
					.glyphicon;
					content: "\e003";
					top: -1px;
					padding-right: 8px;
					margin-left: -4px;
					color: black;
				}

				.ng-input {
					left: 17px;
				}

				.ng-value {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}

		&.ng-select-focused .ng-select-container {
			border: 1px solid #75b8fc;
			outline: 0;
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(117, 184, 252, 0.6);
		}

		&.ng-select-opened.ng-select-focused:not(.ng-select-filtered) {
			.ng-value {
				display: none;
			}

			.ng-has-value .ng-placeholder {
				display: block;
			}
		}

		.ng-arrow-wrapper {
			display: none;
		}

		.ng-clear-wrapper {
			width: 30px;

			.ng-clear {
				font-weight: bold;
				color: #000;
				font-size: 22px;
				padding-top: 2px;
			}
		}
	}

	.ng-dropdown-panel {
		.ng-dropdown-panel-items {
			max-height: 280px;

			.ng-option {
				&.message {
					.text-muted;
					padding: 6px 10px;
				}
			}
		}
	}
}