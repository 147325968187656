.origination {
	.form-group{
		margin-bottom: 0;
	}

	input.form-control[type='radio'], input.form-control[type='checkbox']{
		display: inline;
		margin-left: 0px;
	}
	.methods span + span:before{
		content:"| ";
		margin: 0 5px;
		color: @text-muted;
	}

}
